import { useQuery } from 'react-query'
import { apiJSON } from '@shared/utils/api/api.util'
import { Scope } from '@shared/types'

export function useScopesQuery(noCache?: boolean) {
  const { isLoading: areScopesLoading, data: scopes } = useQuery<Scope[]>(
    'scopes',
    () => apiJSON('scopes'),
    noCache ? { cacheTime: 0 } : {}
  )

  return { areScopesLoading, scopes }
}
