import React, { FC } from 'react'
import { Dialog, DialogTitle, IconButton } from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'
import { makeStyles } from '@material-ui/core/styles'
import { AuditRequirementImage } from '@shared/types'

interface Props {
    currentImagePreview: AuditRequirementImage | null
    isImagePreviewOpen: boolean
    onImagePreviewClose: () => void
}

export const RequirementPhotoDialog: FC<Props> = ({
    currentImagePreview,
    isImagePreviewOpen,
    onImagePreviewClose,
}) => {
    const classes = useStyles()

    return (
        <Dialog
            open={isImagePreviewOpen}
            onClose={onImagePreviewClose}
            maxWidth={false}
        >
            <DialogTitle
                disableTypography
                className={classes.imagePreviewTitle}
            >
                <IconButton onClick={onImagePreviewClose}>
                    <originalIcons.Close />
                </IconButton>
            </DialogTitle>
            {currentImagePreview && (
                <img src={currentImagePreview.imageURL} alt="preview" />
            )}
        </Dialog>
    )
}

export const useStyles = makeStyles({
    imagePreviewTitle: {
        padding: 0,
        display: 'flex',
        flexFlow: 'row-reverse',
    },
})
