/**
 * @todo This should be part oc Components as well as empty component
 */
import React, {ReactNode} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'

export type Props = ButtonProps & { isLoading?: boolean, endComponent?: ReactNode }

export const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  }
})

export function ProgressButton({ isLoading, disabled, ...buttonProps }: Props) {
  const classes = useStyles()

  return (
    <Button {...buttonProps} disabled={disabled || isLoading}>
      {buttonProps.children}
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      {buttonProps?.endComponent}
    </Button>
  )
}
