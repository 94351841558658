import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import { Audit, AuditRequirement, AuditRequirementImage, Template } from '@shared/types'
import { useCallback, useEffect } from 'react'

export enum OfflineEntityType {
  Templates = 'templates',
  Template = 'template',
  Requirement = 'requirement',
  Audit = 'audit',
  Images = 'images'
}

export const OFFLINE_PREFIX = 'offline'

const getOfflineKey = (entityType: OfflineEntityType) => `${OFFLINE_PREFIX}::${entityType}`

export const getOfflineContent = (entityType: OfflineEntityType) => {
  const content = localStorage.getItem(getOfflineKey(entityType))

  if (content) {
    return JSON.parse(content)
  }
}

export function useOfflineContent() {
  const { isOffline } = useNavigatorOnline()

  const get = useCallback(
    <ReturnType>(entityType: OfflineEntityType, entityID?: number) => {
      if (!isOffline) {
        return
      }

      if (entityType === OfflineEntityType.Templates || entityType === OfflineEntityType.Audit) {
        return getOfflineContent(entityType) as unknown as ReturnType
      }
    },
    [isOffline]
  )

  const set = useCallback(
    (entityType: OfflineEntityType, value: any) => {
      if (entityType === OfflineEntityType.Template) {
        const templates = get<Template[]>(OfflineEntityType.Templates)

        localStorage.setItem(getOfflineKey(entityType), JSON.stringify([...(templates ?? []), value]))
      } else if (entityType === OfflineEntityType.Images) {
        const images = get<AuditRequirementImage[]>(OfflineEntityType.Images)

        localStorage.setItem(getOfflineKey(entityType), JSON.stringify([...(images ?? []), value]))
      } else if (entityType === OfflineEntityType.Audit) {
        const audit = get<Audit>(OfflineEntityType.Audit)

        localStorage.setItem(getOfflineKey(entityType), JSON.stringify({ ...(audit ?? {}), ...value }))
      } else if (entityType === OfflineEntityType.Requirement) {
        const requirement = value as unknown as AuditRequirement
        const audit = get<Audit>(OfflineEntityType.Audit)

        console.log("TAK WCHODZI REQ::::")

        set(OfflineEntityType.Audit, {
          auditRequirements: [
            ...(audit?.auditRequirements ?? []).filter(
              previousRequirement => previousRequirement.auditRequirementID !== requirement.auditRequirementID
            ),
            requirement
          ]
        })
      }
    },
    [get]
  )

  useEffect( () => {

  }, []);

  return { get, set }
}
