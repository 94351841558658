import React from 'react'

import { authContext } from './auth.context'
import { useAuthProvider } from './use-auth-provider.hook'

import { useQuery } from 'react-query'
import { NO_REFETCH_OPTIONS } from '@shared/utils/api/api.consts'

type Props = {
  children: React.ReactNode
}

export function AuthProvider(props: Props) {
  const auth = useAuthProvider()

  useQuery<void>('session', () => auth.checkIsLogged(), NO_REFETCH_OPTIONS)

  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>
}
