import React from 'react'

import {
    makeStyles,
    Typography,
    useTheme,
    Box,
    IconButton,
} from '@material-ui/core'
import { useQuery } from 'react-query'

import { originalIcons } from '@shared/components/icons/icons'
import { ClientAuditStatsChart } from './client-audit-stats-chart.component'
import { apiJSON } from '@shared/utils/api/api.util'
import { statusColors } from '@shared/utils/status-colors'
import { StatusTypes } from '@shared/types'
import { getChartData } from '@modules/requirements/client-audit-stats.consts'

export type Stats = {
    accepted: number
    all: number
    infringement: number
    outdated: number
    rejected: number
    new: number
    notAssessed: number
    sumWithoutOutdated: number
    incorrectSumWithoutOutdated: number
    warnings: number
    scopeID: 'all' | number
}

export type Props = {
    companyID: number
    scopeID: number
    isPreviewMode: boolean
    currentTabSelected: string
    handleClose: () => void
}

export function ClientAuditStats(props: Props) {
    const classes = useStyles()
    const theme = useTheme()

    const statsEndpoint = [
        'statistics',
        props.companyID,
        !props.isPreviewMode ? 'last' : false,
    ]
        .filter(Boolean)
        .join('/')

    const { isLoading, data: dataAll } = useQuery<Stats[]>(statsEndpoint, () =>
        apiJSON(statsEndpoint)
    )
    const data = dataAll?.find(
        (statsSet) => statsSet.scopeID === props.scopeID?.toString()
    )

    const chartData = getChartData(data, theme)

    return !isLoading && data ? (
        <div className={classes.statsContainer}>
            <Box className={classes.header}>
                <Typography variant="h6">Wyniki audytu</Typography>
                <IconButton onClick={() => props.handleClose()}>
                    <originalIcons.Close />
                </IconButton>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <ClientAuditStatsChart data={chartData?.overall} />
                <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.contentWrapper}
                >
                    <Typography variant="caption">Kategoria</Typography>
                    <Typography variant="body1">
                        <strong>{props.currentTabSelected}</strong>
                    </Typography>
                    <ul className={classes.ul}>
                        <li>
                            <Typography variant="caption">
                                <span
                                    className={classes.overallStatsNumbers}
                                    style={{
                                        color: statusColors[
                                            StatusTypes.NotAssessed
                                        ],
                                    }}
                                >
                                    {data?.all}
                                </span>{' '}
                                &#45; ilość ocenionych wymagań prawnych
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption">
                                <span
                                    className={classes.overallStatsNumbers}
                                    style={{
                                        color: statusColors[
                                            StatusTypes.Rejected
                                        ],
                                    }}
                                >
                                    {data?.rejected}
                                </span>{' '}
                                &#45; ilość wymagań oznaczonych statusem
                                NIEZGODNE
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption">
                                <span
                                    className={classes.overallStatsNumbers}
                                    style={{
                                        color: statusColors[
                                            StatusTypes.Infringement
                                        ],
                                    }}
                                >
                                    {data?.infringement}
                                </span>{' '}
                                &#45; ilość wymagań oznaczonych statusem
                                NARUSZENIE
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption">
                                <span
                                    className={classes.overallStatsNumbers}
                                    style={{
                                        color: statusColors[
                                            StatusTypes.Warnings
                                        ],
                                    }}
                                >
                                    {data?.warnings}
                                </span>{' '}
                                &#45; ilość wymagań oznaczonych statusem UWAGA
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption">
                                <span
                                    className={classes.overallStatsNumbers}
                                    style={{
                                        color: statusColors[
                                            StatusTypes.Accepted
                                        ],
                                    }}
                                >
                                    {data?.accepted}
                                </span>{' '}
                                &#45; ilość wymagań oznaczonych statusem ZGODNE
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption">
                                <span
                                    className={classes.overallStatsNumbers}
                                    style={{
                                        color: statusColors[
                                            StatusTypes.NotApplicable
                                        ],
                                    }}
                                >
                                    {data?.notAssessed}
                                </span>{' '}
                                &#45; ilość wymagań nie poddanych ocenie
                            </Typography>
                        </li>
                    </ul>
                </Box>
                {/*{data?.outdated ? (*/}
                {/*    <Typography variant="body2">*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                padding: '10px',*/}
                {/*                color: statusColors[*/}
                {/*                    StatusTypes.NotAssessedNegative*/}
                {/*                ],*/}
                {/*                backgroundColor:*/}
                {/*                    statusColors[StatusTypes.Rejected],*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Od dnia zakończenia audytu zaszły zmiany w*/}
                {/*            obowiązujących przepisach. W wyniku tych*/}
                {/*            zmian:*/}
                {/*            <br />*/}
                {/*            <strong>{data?.outdated}</strong>*/}
                {/*            &nbsp;&#45; wymagań prawnych dotyczących*/}
                {/*            Twojej firmy uległo zmianie i nie jest znany*/}
                {/*            status ich realizacji.*/}
                {/*        </div>*/}
                {/*    </Typography>*/}
                {/*) : null}*/}
            </Box>
        </div>
    ) : null
}

const useStyles = makeStyles((theme) => ({
    statsContainer: {
        padding: theme.spacing(2),
        '& .MuiPaper-root': {
            flex: 1,
            marginRight: theme.spacing(2),
            marginLeft: 1,
        },
        '& .MuiPaper-root:last-child': {
            marginRight: 1,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    contentWrapper: {
        marginLeft: theme.spacing(2),
    },
    ul: {
        padding: 0,
        listStyleType: 'none',
    },
    overallStatsNumbers: {
        fontSize: '18px',
        fontWeight: 700,
    },
    strongStatsNumber: {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.grey[700],
    },
}))
