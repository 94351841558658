import React, {
    forwardRef,
    ForwardRefExoticComponent,
    RefAttributes,
} from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Create from '@material-ui/icons/Create'
import CheckCircle from '@material-ui/icons/CheckCircle'
import AddCircle from '@material-ui/icons/AddCircle'
import Assessment from '@material-ui/icons/Assessment'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import NewReleases from '@material-ui/icons/NewReleases'
import Description from '@material-ui/icons/Description'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Palette from '@material-ui/icons/Palette'
import Close from '@material-ui/icons/Close'
import ImageIcon from '@material-ui/icons/Image'
import CachedIcon from '@material-ui/icons/Cached'
import InfoIcon from '@material-ui/icons/Info'
import DateRangeIcon from '@material-ui/icons/DateRange'
import RoomIcon from '@material-ui/icons/Room'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GroupIcon from '@material-ui/icons/Group'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import SendIcon from '@material-ui/icons/Send'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import CommentIcon from '@material-ui/icons/Comment'
import { makeStyles } from '@material-ui/core/styles'

import { SvgIconComponent } from 'material-table'
import { useStore } from '@shared/hooks/use-store.hook'
import { Actions } from '@shared/lib/reducer'

export function forwardedIconFactory(IconComponent: SvgIconComponent) {
    // @ts-ignore
    return forwardRef<SVGSVGElement>((props, ref) => (
        <IconComponent {...props} ref={ref} />
    ))
}

export type ForwardedIconComponent = ForwardRefExoticComponent<
    RefAttributes<SVGSVGElement>
>

const DetailPanel = () => {
    const { state, dispatch } = useStore()
    const classes = useStyles()

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        const parent = e.currentTarget.parentNode?.parentNode?.parentNode

        if (parent?.hasChildNodes()) {
            const rowGroup = parent.childNodes[2]
            if (rowGroup.hasChildNodes()) {
                const textContainer = rowGroup.childNodes[1]
                if (textContainer) {
                    const rowText = textContainer.textContent
                    if (!state.expandedValues.includes(rowText)) {
                        return dispatch({
                            type: Actions.ADD_EXPANDED_VALUE,
                            payload: rowText,
                        })
                    }

                    return dispatch({
                        type: Actions.REMOVE_EXPANDED_VALUE,
                        payload: rowText,
                    })
                }
            }
        }
    }

    return (
        <div onClick={handleClick} className={classes.container}>
            <ChevronRight />
        </div>
    )
}

export const useStyles = makeStyles({
    container: {
        width: '24px',
        height: '24px',
    },
})

export const originalIcons = {
    Add: AddBox,
    AddCircle,
    Assessment,
    Check,
    CheckCircle: CheckCircle,
    Clear,
    Close,
    Create,
    Delete: DeleteOutline,
    Description,
    DetailPanel,
    Edit,
    Error: ErrorOutline,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage,
    ImageIcon,
    InfoIcon,
    LastPage,
    NewReleases,
    NextPage: ChevronRight,
    Palette,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search,
    CachedIcon,
    SortArrow: ArrowDownward,
    ThirdStateCheck: Remove,
    ViewColumn,
    Visibility,
    VisibilityOff,
    Calendar: DateRangeIcon,
    Pin: RoomIcon,
    Expand: ExpandMoreIcon,
    Group: GroupIcon,
    Tune: TuneIcon,
    Paper: InsertDriveFileIcon,
    PlayArrow: PlayArrowIcon,
    Watch: WatchLaterIcon,
    Send: SendIcon,
    Chart: DonutLargeIcon,
    Comment: CommentIcon,
}

export const icons = Object.entries(originalIcons).reduce(
    (memo: { [key: string]: ForwardedIconComponent }, [key, Component]) => {
        memo[key] = forwardedIconFactory(Component)

        return memo
    },
    {}
)
