import React, { FC, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { originalIcons } from '@shared/components/icons/icons'
import { CircularProgress, Tooltip } from '@material-ui/core'
import { api } from '@shared/utils/api/api.util'
import { makeStyles } from '@material-ui/core/styles'

interface LinkWithStatusProps {
    link: string
    isColumnView?: boolean
}

const fetchStatus = async (link: string): Promise<boolean> => {
    try {
        const response = await api(`legalbasis/check-url?url=${link}`)
        if (response.status === 200) {
            return true
        }
    } catch {
        return false
    }
    return false
}

const getLinkContent = (isColumnView: boolean, url: string): string => {
    if (isColumnView) {
        const parts = url.split('/')
        const lastSegment = parts[parts.length - 1]

        if (lastSegment.startsWith('par-')) {
            const number = lastSegment.substring(4)
            return `§ ${number}`
        } else if (lastSegment.startsWith('art-')) {
            const number = lastSegment.substring(4)
            return `Art. ${number}`
        }

        return ''
    }

    return url
}

enum Status {
    Success = 'Success',
    Failed = 'Failed',
    Loading = 'Loading',
}

const LinkStatus: FC<LinkWithStatusProps> = ({ link, isColumnView }) => {
    const classes = useStyles()
    const [status, setStatus] = useState<Status>(Status.Loading)

    useEffect(() => {
        const getStatus = async () => {
            const isSuccess = await fetchStatus(link)
            setStatus(isSuccess ? Status.Success : Status.Failed)
        }

        getStatus()
    }, [link])

    if (isColumnView && [Status.Failed, Status.Loading].includes(status)) {
        return null
    }

    if (isColumnView) {
        return (
            <Link href={link} target="_blank" rel="noopener">
                {getLinkContent(isColumnView, link)}
            </Link>
        )
    }

    return (
        <Typography key={link} className={classes.wrapper}>
            {status === Status.Loading && !isColumnView && (
                <CircularProgress size={12} className={classes.icon} />
            )}
            {status === Status.Success && !isColumnView && (
                <originalIcons.CheckCircle
                    color="primary"
                    fontSize="small"
                    className={classes.icon}
                />
            )}
            {status === Status.Failed && !isColumnView && (
                <Tooltip title="Link nie istnieje, sprawdź czy prawidłowo uzupełniłeś pole art./§.">
                    <originalIcons.Error
                        color="error"
                        fontSize="small"
                        className={classes.icon}
                    />
                </Tooltip>
            )}
            <Link href={link} target="_blank" rel="noopener">
                {getLinkContent(!!isColumnView, link)}
            </Link>
        </Typography>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: 6,
        },
    }
})

export default LinkStatus
