import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { useRouter } from '@shared/hooks/use-router'
import { apiJSON } from '@shared/utils/api/api.util'
import { useACL } from '@shared/hooks/use-acl.hook'
import { RequirementAddPhotoDialog } from './requirement-add-photo.dialog.component'
import {
    AuditRequirementEnhanced,
    AuditRequirementImage,
    Roles,
} from '@shared/types'
import { AddFileDialog } from '@shared/components/add-file.dialog.component'
import { originalIcons } from '@shared/components/icons/icons'
import { RequirementPhotoDialog } from '@modules/requirement/requirement-photo-dialog.component'
import { RequirementForm } from '@modules/requirement/requirement-form/requirement-form.component'
import { FormProvider, useForm } from 'react-hook-form'

type RouteQuery = { auditID: string }
type RouteLocation = { requirement: AuditRequirementEnhanced }

export function RequirementPage() {
    const queryClient = useQueryClient()

    const { is } = useACL()
    const isClient = is([Roles.Client])
    const isClientEditor = is([Roles.ClientEditor])
    const isClientOrClientEditor = isClient || isClientEditor

    const {
        query: { auditID },
        location: {
            state: { requirement },
        },
    } = useRouter<RouteQuery, RouteLocation>()

    const [isTakePhotoDialogOpen, setTakePhotoDialogOpen] = useState(false)
    const [isUploadPhotoDialogOpen, setUploadDialogOpen] = useState(false)
    const [currentPhoto, setCurrentPhoto] = useState<string | null>(null)
    const [photos, setPhotos] = useState<AuditRequirementImage[]>([])
    const [currentImagePreview, setCurrentImagePreview] =
        useState<AuditRequirementImage | null>(null)
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState<boolean>(false)
    const [isRemoveCamera, setIsRemoveCamera] = useState<boolean>(false)
    const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false)

    useEffect(() => {
        document.querySelector('main')?.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setPhotos(requirement.images ?? [])
    }, [requirement.images])

    const onImagePreviewClose = useCallback(() => {
        setIsImagePreviewOpen(false)
        setCurrentImagePreview(null)
    }, [setCurrentImagePreview, setIsImagePreviewOpen])

    const onImagePreviewOpenFactory = useCallback(
        (image: AuditRequirementImage) =>
            (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                setCurrentImagePreview(image)
                setIsImagePreviewOpen(true)
            },
        [setCurrentImagePreview, setIsImagePreviewOpen]
    )

    const onUploadPhotoDialogClose = useCallback(() => {
        setUploadDialogOpen(false)
    }, [setUploadDialogOpen])

    const removeImageMutation = useMutation(
        (imageID: AuditRequirementImage['imageID']) =>
            apiJSON(
                `auditrequirements/${imageID}/file`,
                {},
                { method: 'DELETE' }
            ),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(`audits/${requirement.auditID}`),
        }
    )

    const onPhotoRemoveFactory = useCallback(
        (photoToRemove: AuditRequirementImage) => async () => {
            setPhotos(photos.filter((photo) => photo !== photoToRemove))

            await removeImageMutation.mutateAsync(photoToRemove.imageID)
        },
        [photos, removeImageMutation]
    )

    const onTakePhotoDialogOpen = useCallback(() => {
        setTakePhotoDialogOpen(true)
        setIsRemoveCamera(false)
    }, [])

    const onUploadPhotoDialogOpen = useCallback(() => {
        setUploadDialogOpen(true)
    }, [])

    const savePhotos = useCallback(
        (photos: AuditRequirementImage[]) => {
            setPhotos((prevPhotos) => [...prevPhotos, ...photos])
        },
        [setPhotos]
    )

    const onPhotoDialogReset = useCallback(() => {
        setCurrentPhoto(null)
        setIsRemoveCamera(false)
    }, [setCurrentPhoto])

    const uploadPhotosFunction = (photos: string[]) =>
        Promise.all(
            photos.map((photo) =>
                apiJSON(
                    `auditrequirements/${requirement.auditID}/${requirement.auditRequirementID}/image`,
                    {
                        imageString: photo.replace(
                            /data:image\/.*;base64./,
                            ''
                        ),
                        imageName: uuidv4(),
                    },
                    {
                        method: 'POST',
                    }
                )
            )
        )

    const { mutateAsync: uploadRequirementPhotos } = useMutation(
        uploadPhotosFunction,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`audits/${requirement.auditID}`)
            },
        }
    )

    const onPhotoDialogClose = useCallback(
        async (result: boolean) => {
            if (result && currentPhoto) {
                setIsUploadingPhoto(true)
                const fakeImageID = uuidv4()

                savePhotos([
                    {
                        imageID: fakeImageID,
                        fileName: fakeImageID,
                        auditID: +auditID,
                        auditRequirementID: requirement.requirementBaseID,
                        imageURL: currentPhoto,
                    },
                ])
                await uploadRequirementPhotos([currentPhoto])
                setIsUploadingPhoto(false)
            }

            setCurrentPhoto(null)
            setTakePhotoDialogOpen(false)
        },
        [
            currentPhoto,
            savePhotos,
            uploadRequirementPhotos,
            auditID,
            requirement.requirementBaseID,
        ]
    )

    const handleOnTakePhotoAnimationDone = (dataUri: string) => {
        setCurrentPhoto(dataUri)
        setIsRemoveCamera(true)
    }
    
    const formInitConfig = {
        defaultValues: {
            fixDate: requirement?.fixDate,
            summary: requirement?.summary,
            clientComment: requirement?.clientStatus?.comment,
            auditorAssessment: requirement?.auditorAssessment,
            connectedAuditRequirements:
                requirement?.connectedAuditRequirements ?? [],
        },
    }

    const formMethods = useForm(formInitConfig)

    if (!requirement) return null

    return (
        <FormProvider {...formMethods}>
            <RequirementForm
                onImagePreviewOpenFactory={onImagePreviewOpenFactory}
                isClient={isClient}
                isClientEditor={isClientEditor}
                isClientOrClientEditor={isClientOrClientEditor}
                onTakePhotoDialogOpen={onTakePhotoDialogOpen}
                onUploadPhotoDialogOpen={onUploadPhotoDialogOpen}
                photos={photos}
                removeImageMutation={removeImageMutation}
                onPhotoRemoveFactory={onPhotoRemoveFactory}
            />
            {isImagePreviewOpen && (
                <RequirementPhotoDialog
                    isImagePreviewOpen={isImagePreviewOpen}
                    onImagePreviewClose={onImagePreviewClose}
                    currentImagePreview={currentImagePreview}
                />
            )}
            {isTakePhotoDialogOpen && (
                <RequirementAddPhotoDialog
                    isSubmitDisabled={isUploadingPhoto}
                    open={isTakePhotoDialogOpen}
                    fullWidth
                    maxWidth={false}
                    style={{ width: '100%', maxWidth: 'none' }}
                    onReset={onPhotoDialogReset}
                    onClose={onPhotoDialogClose}
                >
                    {currentPhoto ? (
                        <img
                            alt=""
                            style={{ display: 'block', margin: '0 auto' }}
                            src={currentPhoto}
                        />
                    ) : !isRemoveCamera ? (
                        <Camera
                            idealFacingMode="environment"
                            imageCompression={0.5}
                            onTakePhotoAnimationDone={(dataUri) =>
                                handleOnTakePhotoAnimationDone(dataUri)
                            }
                        />
                    ) : null}
                </RequirementAddPhotoDialog>
            )}
            {isUploadPhotoDialogOpen && (
                <AddFileDialog
                    open={isUploadPhotoDialogOpen}
                    acceptedFiles={['image/jpeg', 'image/png']}
                    getPreviewIcon={() => <originalIcons.ImageIcon />}
                    getEndpoint={() =>
                        `auditrequirements/${auditID}/${requirement.auditRequirementID}/file`
                    }
                    onDialogClose={onUploadPhotoDialogClose}
                    onCleanup={onUploadPhotoDialogClose}
                    onAddFiles={savePhotos}
                />
            )}
        </FormProvider>
    )
}
