import React, { useCallback, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'

import {
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from '@material-ui/core'

import { ProgressButton } from '@shared/components/progress-button.component'
import { ErrorsAlert } from '@shared/components/errors-alert.component'

import { useAuth } from '@modules/auth/use-auth.hook'
import { NormalizedErrors } from '@shared/utils/api/api.util'

type LoginForm = {
    email: string
    password: string
}

export function LoginPage() {
    const classes = useStyles()
    const authService = useAuth()

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<LoginForm>({
        defaultValues: { email: '', password: '' },
    })

    const {
        mutate: login,
        isLoading: isSessionCreating,
        isError: sessionCreationFailed,
        error: sessionCreationError,
        reset: resetSessionCreationMutation,
    } = useMutation<void, NormalizedErrors, LoginForm>(
        ({ email, password }: LoginForm) => authService.login(email, password),
        {
            retry: false,
        }
    )

    const onSubmit = useCallback(
        (data: LoginForm) => {
            login(data)
        },
        [login]
    )

    const emailFormValue = watch('email')
    const passwordFormValue = watch('password')

    useEffect(() => {
        resetSessionCreationMutation()
    }, [resetSessionCreationMutation, emailFormValue, passwordFormValue])

    return authService.isLogged ? (
        <Redirect to="/" />
    ) : (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logo}>
                    <img
                        src="/logo/logo-e.jpg"
                        className={classes.logoImage}
                        alt="logo"
                    />
                </div>
                <Typography component="h1" variant="h5">
                    Logowanie
                </Typography>
                <form noValidate className={classes.form}>
                    <ErrorsAlert errors={sessionCreationError} />
                    <TextField
                        {...register('email', {
                            required: 'Musisz podać email',
                        })}
                        error={sessionCreationFailed || Boolean(errors?.email)}
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        disabled={isSessionCreating}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        autoComplete="email"
                        autoFocus
                        helperText={errors?.email?.message}
                    />
                    <TextField
                        {...register('password', {
                            required: 'Musisz podać hasło',
                        })}
                        error={
                            sessionCreationFailed || Boolean(errors?.password)
                        }
                        disabled={isSessionCreating}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Hasło"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        helperText={
                            Boolean(errors?.password)
                                ? errors?.password?.message
                                : sessionCreationFailed &&
                                  typeof sessionCreationError === 'string' &&
                                  sessionCreationError
                        }
                    />
                    <FormControlLabel
                        disabled={isSessionCreating}
                        control={<Checkbox value="remember" color="primary" />}
                        label="Zapamiętaj"
                    />
                    <ProgressButton
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isSessionCreating}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Zaloguj
                    </ProgressButton>
                    <Grid container>
                        <Grid item xs>
                            <Link href="forgotpassword" variant="body2">
                                Zapomniałeś hasło?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}

export const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFF',
        padding: '20px',
    },
    logo: {
        margin: theme.spacing(1),
        backgroundColor: '#FFF',
    },
    logoImage: {
        width: '100px',
        height: '100px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))
