import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { getDefaultStringInsteadOfNull } from '@shared/utils/get-default-string-instead-of-null'

interface Props {
    content: string
}

export const FormSectionContent = ({ content }: Props) => {
    return (
        <Typography variant="body1">
            <Box component="span" paddingTop={0.75} marginTop={0.75}>
                {getDefaultStringInsteadOfNull(content)}
            </Box>
        </Typography>
    )
}
