import React, { useCallback, useRef, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { FormTemplates } from '@modules/requirement/requirement-form/form-templates.component'
import { useFormContext } from 'react-hook-form'
import { Audit, AuditRequirementEnhanced, Roles, Template } from '@shared/types'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { ACL } from '@shared/components/acl.component'
import { useRouter } from '@shared/hooks/use-router'
import { registerInputRefFactory } from '@modules/admin-generic/admin-generic.utils'

type RouteQuery = {
    companyID: string
    auditID: string
    auditRequirementID: string
}
type RouteLocation = {
    requirement: AuditRequirementEnhanced
    audit: Audit
    isPreviewMode: boolean
}

interface Props {
    isAuditorAssessmentEmpty: boolean
    requirementBaseID: number
    statusID: number
    isSummaryEmpty: boolean
    selectedTemplate?: Template
    setSelectedTemplate: (value: Template) => void
}

export const FormStatusSummary = ({
    isAuditorAssessmentEmpty,
    requirementBaseID,
    statusID,
    isSummaryEmpty,
    selectedTemplate,
    setSelectedTemplate,
}: Props) => {
    const [showSummaryTemplates, setShowSummaryTemplates] = useState(false)
    const { location } = useRouter<RouteQuery, RouteLocation>()

    const { setValue, register } = useFormContext()
    const registerInputRef = registerInputRefFactory(register)

    const onShowTemplatesClick = useCallback(() => {
        setShowSummaryTemplates(!showSummaryTemplates)
    }, [showSummaryTemplates])

    const onSetTemplate = useCallback(
        (templateText) => {
            setValue('summary', templateText)
            setShowSummaryTemplates(false)
        },
        [setShowSummaryTemplates, setValue]
    )

    return (
        <ShowConditionally when={!location.state.isPreviewMode}>
            <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            {...registerInputRef('summary')}
                            label="Podsumowanie"
                            fullWidth
                            multiline
                            rowsMax={20}
                            rows={4}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <FormTemplates
                        saveDisabled={
                            isAuditorAssessmentEmpty || isSummaryEmpty
                        }
                        showTemplates={showSummaryTemplates}
                        requirementBaseID={requirementBaseID}
                        onSetTemplate={onSetTemplate}
                        statusID={statusID}
                        onShowTemplatesClick={onShowTemplatesClick}
                        controlName="saveAsTemplateEnhanced"
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        isSummary
                    />
                </Grid>
            </ACL>
        </ShowConditionally>
    )
}
