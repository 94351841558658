import React from 'react'
import { groupBy } from 'lodash'
import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'

import { LocationDecorated } from '@shared/types'
import { usePersistedTabChange } from '@shared/hooks/use-persisted-tab-change'

import { SelectLocationTable } from './select-location-table.component'
import { AuditModes } from './select-location.types'
import { useCompaniesAuditsQuery } from '@shared/hooks/use-companies-audits-query.hook'

export function SelectLocationPage() {
  const classes = useStyles()
  const { currentTab, onTabChange } = usePersistedTabChange('selectLocation.currentTab')
  const { companiesWithAudits, areCompaniesLoading } = useCompaniesAuditsQuery()

  const locationsDecorated: { [key: string]: LocationDecorated[] } = groupBy(
    (companiesWithAudits ?? []).map(company => {
      return {
        ...company,
        action: company.lastActiveAuditID > 0 ? 'Kontynuuj audyt' : 'Rozpocznij audyt'
      }
    }),
    (company: LocationDecorated) => (company.lastActiveAuditID ? AuditModes.InProgress : AuditModes.Other)
  )

  return (
    <TabContext value={currentTab}>
      <Tabs
        className={classes.tab}
        value={currentTab}
        onChange={onTabChange}
        variant='fullWidth'
        textColor='primary'
        indicatorColor='primary'>
        <Tab label={AuditModes.InProgress} value='0' />
        <Tab label={AuditModes.Other} value='1' />
      </Tabs>
      <TabPanel value='0'>
        <SelectLocationTable
          mode={AuditModes.InProgress}
          isLoading={areCompaniesLoading}
          companies={locationsDecorated[AuditModes.InProgress]}
        />
      </TabPanel>
      <TabPanel value='1'>
        <SelectLocationTable mode={AuditModes.Other} isLoading={areCompaniesLoading} companies={locationsDecorated[AuditModes.Other]} />
      </TabPanel>
    </TabContext>
  )
}

export const useStyles = makeStyles(theme => {
  return {
    tab: { width: '95%', margin: '0 auto' }
  }
})
