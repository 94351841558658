export type ViewRowTableField = {
    property: string
    label: string
    selected: boolean
}

export enum ViewRowTableFieldPropertyEnum {
    LEGAL_REQUIREMENT = 'legalRequirement',
    CONFORMITY_ASSESSMENT = 'conformityAssessment',
    ACTION_TAKEN = 'actionTaken',
}

export enum ViewRowTableFieldLabelEnum {
    LEGAL_REQUIREMENT = 'Wymaganie prawne',
    CONFORMITY_ASSESSMENT = 'Ocena zgodności',
    ACTION_TAKEN = 'Podjęte działania',
}

const viewRowTableFields: ViewRowTableField[] = [
    {
        property: ViewRowTableFieldPropertyEnum.LEGAL_REQUIREMENT,
        label: ViewRowTableFieldLabelEnum.LEGAL_REQUIREMENT,
        selected: true,
    },
    {
        property: ViewRowTableFieldPropertyEnum.CONFORMITY_ASSESSMENT,
        label: ViewRowTableFieldLabelEnum.CONFORMITY_ASSESSMENT,
        selected: true,
    },
    {
        property: ViewRowTableFieldPropertyEnum.ACTION_TAKEN,
        label: ViewRowTableFieldLabelEnum.ACTION_TAKEN,
        selected: true,
    },
]

export type TableData = {
    auditID: number
    scopeID: number | undefined
    filters: number[]
}

export type State = {
    currentPathBase: string
    prevPathBase: string
    expandedValues: string[]
    requirementTableData: TableData[] | undefined
    viewRowTableFields: ViewRowTableField[]
    isDraftFilterActive: boolean
}

export const initialState: State = {
    currentPathBase: '',
    prevPathBase: '',
    expandedValues: [],
    requirementTableData: [],
    viewRowTableFields,
    isDraftFilterActive: false,
}

export enum Actions {
    UPDATE_PATHS = 'UPDATE_PATHS',
    ADD_EXPANDED_VALUE = 'ADD_EXPANDED_VALUE',
    REMOVE_EXPANDED_VALUE = 'REMOVE_EXPANDED_VALUE',
    REMOVE_EXPANDED_LIST = 'REMOVE_EXPANDED_LIST',
    ADD_EXPANDED_LIST = 'ADD_EXPAND_LIST',
    CLEAR_EXPANDED_VALUES = 'CLEAR_EXPANDED_VALUES',
    SET_REQUIREMENT_TABLE_DATA = 'SET_REQUIREMENT_TABLE_DATA',
    TOGGLE_VIEW_ROW_TABLE_FIELD = 'TOGGLE_VIEW_ROW_TABLE_FIELD',
    TOGGLE_FILTER_DRAFTS = 'TOGGLE_FILTER_DRAFTS',
}

export type Action =
    | {
          type: Actions.UPDATE_PATHS
          payload: { currentPathBase: string; prevPathBase: string }
      }
    | {
          type: Actions.ADD_EXPANDED_VALUE
          payload: string
      }
    | {
          type: Actions.REMOVE_EXPANDED_VALUE
          payload: string
      }
    | {
          type: Actions.REMOVE_EXPANDED_LIST
          payload: string[]
      }
    | {
          type: Actions.ADD_EXPANDED_LIST
          payload: string[]
      }
    | {
          type: Actions.CLEAR_EXPANDED_VALUES
      }
    | { type: Actions.SET_REQUIREMENT_TABLE_DATA; payload: TableData }
    | {
          type: Actions.TOGGLE_VIEW_ROW_TABLE_FIELD
          payload: { property: ViewRowTableFieldPropertyEnum }
      }
    | {
          type: Actions.TOGGLE_FILTER_DRAFTS
      }

export const reducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case Actions.UPDATE_PATHS:
            return {
                ...state,
                currentPathBase: action.payload.currentPathBase,
                prevPathBase: action.payload.prevPathBase,
            }
        case Actions.ADD_EXPANDED_VALUE:
            return {
                ...state,
                expandedValues: [...state.expandedValues, action.payload],
            }
        case Actions.REMOVE_EXPANDED_VALUE:
            return {
                ...state,
                expandedValues: state.expandedValues.filter(
                    (item) => !action.payload.includes(item)
                ),
            }

        case Actions.ADD_EXPANDED_LIST:
            return {
                ...state,
                expandedValues: [
                    ...state.expandedValues,
                    ...action.payload.filter(
                        (item) => !state.expandedValues.includes(item)
                    ),
                ],
            }

        case Actions.REMOVE_EXPANDED_LIST:
            return {
                ...state,
                expandedValues: state.expandedValues.filter(
                    (item) => !action.payload.includes(item)
                ),
            }

        case Actions.CLEAR_EXPANDED_VALUES:
            return {
                ...state,
                expandedValues: initialState.expandedValues,
            }
        case Actions.SET_REQUIREMENT_TABLE_DATA:
            if (!state.requirementTableData) return state

            const scopeDataExist = state.requirementTableData?.find(
                (tableData) => tableData.scopeID === action.payload.scopeID
            )

            let updatedTableData
            if (scopeDataExist) {
                const updatedScopeDataIndex =
                    state.requirementTableData?.findIndex(
                        (data) => data.scopeID === action.payload.scopeID
                    )

                state.requirementTableData[updatedScopeDataIndex].filters =
                    action.payload.filters

                updatedTableData = state.requirementTableData
            } else {
                updatedTableData = [
                    ...(state.requirementTableData as TableData[]),
                    action.payload,
                ]
            }

            return {
                ...state,
                requirementTableData: updatedTableData,
            }
        case Actions.TOGGLE_VIEW_ROW_TABLE_FIELD:
            return {
                ...state,
                viewRowTableFields: state.viewRowTableFields.map((field) => {
                    if (field.property === action.payload.property) {
                        return {
                            ...field,
                            selected: !field.selected,
                        }
                    }
                    return field
                }),
            }
        case Actions.TOGGLE_FILTER_DRAFTS:
            return {
                ...state,
                isDraftFilterActive: !state.isDraftFilterActive,
            }

        default:
            return state
    }
}
