import React, { useEffect, useMemo, useRef } from 'react'
import { defaultsDeep } from 'lodash'
import { Theme, useTheme } from '@material-ui/core'
import MaterialTable, { MaterialTableProps } from 'material-table'

import { TableToolbar } from './table-toolbar.component'

import { icons } from '@shared/components/icons/icons'
import { usePersistedScroll } from '@shared/hooks/use-persisted-scroll'
import { getPersistKey } from '@shared/utils/get-persist-key'

export const PAGE_NUMBER_KEY_PREFIX = 'page'
export const PAGE_SIZE_KEY = 'pageSize'

export type Props<RowData extends object> = {
    trackPanelsBy?: string
    trackScroll?: boolean
    initialScrollPosition?: 'top'
    additionalComponent?: React.ReactElement
    pageSize?: number
} & MaterialTableProps<RowData>

function defaultPropsFactory<RowData extends object>(
    theme: Theme,
    props: Props<RowData>
): Partial<MaterialTableProps<RowData>> {
    const lastUsedPage = props.trackPanelsBy
        ? localStorage.getItem(
              getPersistKey(props.trackPanelsBy, PAGE_NUMBER_KEY_PREFIX)
          )
        : null
    const initialPage =
        props.trackPanelsBy && lastUsedPage ? parseInt(lastUsedPage) : undefined
    const lastSelectedPageSize = localStorage.getItem(PAGE_SIZE_KEY)
    const initialPageSize = lastSelectedPageSize
        ? parseInt(lastSelectedPageSize)
        : undefined

    return {
        icons,
        onChangePage: (pageNumber, pageSize) => {
            props?.onChangePage?.(pageNumber, pageSize)

            if (props.trackPanelsBy) {
                localStorage.setItem(
                    getPersistKey(props.trackPanelsBy, PAGE_NUMBER_KEY_PREFIX),
                    pageNumber.toString()
                )
                localStorage.setItem(PAGE_SIZE_KEY, pageSize.toString())
            }
        },
        options: {
            initialPage,
            paginationPosition: 'both',
            defaultExpanded:
                props.options?.defaultExpanded ?? Boolean(props.trackPanelsBy),
            pageSize: initialPageSize ?? 100,
            pageSizeOptions: [20, 50, 100],
            emptyRowsWhenPaging: false,
            search: true,
            draggable: false,
            actionsColumnIndex: -1,
            searchFieldStyle: {
                width: 320,
                height: 40,
                paddingRight: 0,
                color: 'black',
            },
            searchFieldVariant: 'outlined',
            searchFieldAlignment: 'right',
            headerStyle: {
                fontWeight: 'bold',
            },
        },
        localization: {
            pagination: {
                labelDisplayedRows: '{from}-{to} z {count}',
                labelRowsSelect: 'wierszy',
                nextTooltip: 'Następna strona',
                previousTooltip: 'Poprzednia strona',
                firstTooltip: 'Pierwsza strona',
                lastTooltip: 'Ostatnia strona',
            },
            toolbar: {
                searchPlaceholder: 'Szukaj',
                nRowsSelected: 'wybrano {0} wiersz(y)',
            },
            header: {
                actions: '',
            },
            body: {
                emptyDataSourceMessage: 'Brak rekordów do wyświetlenia',
                filterRow: {
                    filterTooltip: 'Filtruj',
                },
            },
        },
        components: {
            Toolbar: (toolbarProps) => (
                <TableToolbar
                    {...toolbarProps}
                    additionalComponent={props.additionalComponent}
                />
            ),
        },
    }
}

export function Table<RowData extends object>(props: Props<RowData>) {
    const theme = useTheme()
    const { trackPanelsBy, data, ...finalProps } = defaultsDeep(
        {},
        props,
        defaultPropsFactory<RowData>(theme, props)
    ) as Props<RowData>

    const tableRef = useRef<any>()
    const openedPanels = useRef<{ [key: string]: boolean }>({})

    const resetScroll = usePersistedScroll({
        key: props?.trackPanelsBy,
        enabled: Boolean(props?.trackPanelsBy && !props?.isLoading),
    })

    const onChangePageFinal = (pageNumber: number, pageSize: number) => {
        resetScroll()

        finalProps?.onChangePage?.(pageNumber, pageSize)
    }

    useEffect(() => {
        if (props.initialScrollPosition === 'top') {
            resetScroll()
        }
    }, [props.initialScrollPosition, resetScroll])

    const pageSize = useMemo(
        () =>
            finalProps.options?.pageSize &&
            data?.length < finalProps.options?.pageSize
                ? data.length
                : finalProps.options?.pageSize,
        [data?.length, finalProps.options?.pageSize]
    )

    return Array.isArray(data) ? (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html: `.MuiPaper-elevation2 { box-shadow: none }`,
                }}
            />
            <MaterialTable<RowData>
                {...finalProps}
                key={data.length}
                onChangePage={onChangePageFinal}
                tableRef={tableRef}
                options={{
                    ...finalProps.options,
                    rowStyle: props.options?.rowStyle,
                    pageSizeOptions: finalProps.options?.pageSizeOptions,
                    pageSize: props.pageSize || pageSize,
                    searchText: props.options?.searchText,
                }}
                onSearchChange={props.onSearchChange}
                data={
                    data?.map((dataItem: RowData, index: number) => {
                        return {
                            ...dataItem,
                            tableData: {
                                showDetailPanel: openedPanels.current[index]
                                    ? props.detailPanel
                                    : null,
                            },
                        }
                    }) ?? []
                }
            />
        </>
    ) : null
}
