import React, { ReactNode } from 'react'
import { Box, Tooltip, Typography } from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
    text: string
    tooltipText: string
    icon: ReactNode
}

export const TooltipText = ({ tooltipText, text, icon }: Props) => {
    const classes = useStyles()
    return (
        <Tooltip title={tooltipText}>
            <Box display="flex" alignItems="center" className={classes.wrapper}>
                {icon}
                <Typography variant="body2" className={classes.text}>
                    {text}
                </Typography>
                <originalIcons.Expand className={classes.icon} />
            </Box>
        </Tooltip>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            marginLeft: theme.spacing(2),
        },
        text: {
            fontSize: theme.spacing(1.5),
        },
        icon: {
            color: theme.palette.text.secondary,
            fontSize: theme.spacing(2),
        },
    }
})
