/**
 * @todo rename to scopes
 * @todo use memo for computation
 */
import React, { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonGroup } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

import { chunk, uniq } from 'lodash'
import SVG from 'react-inlinesvg'

import { apiJSON } from '@shared/utils/api/api.util'
import { useRouter } from '@shared/hooks/use-router'
import { ProgressButton } from '@shared/components/progress-button.component'
import { useScopesQuery } from '@shared/hooks/use-scopes-query.hook'
import { originalIcons } from '@shared/components/icons/icons'
import { Audit, Scope } from '@shared/types'
import { useAuditQuery } from '@shared/hooks/use-audit-query.hook'
import { usePersistedTabChange } from '@shared/hooks/use-persisted-tab-change'
import { BackdropProgress } from '@shared/components/backdrop-progress.component'

const { Check } = originalIcons

export function SelectScopesPage() {
  const queryClient = useQueryClient()
  const { query, history } = useRouter<{ companyID: string; auditID?: string }>()
  const { companyID, auditID } = query
  const isEditMode = Boolean(auditID)
  const { onTabChange } = usePersistedTabChange(`currentTab.${companyID}.${auditID}`)

  const { audit } = useAuditQuery(auditID!, { enabled: isEditMode })
  const selectedScopes = isEditMode ? uniq(audit?.auditRequirements.map(({ scopeID }) => scopeID) ?? []) : []

  const classes = useStyles()
  const [scopeIDs, setScopes] = useState<(number | string)[]>(selectedScopes)

  const { areScopesLoading, scopes } = useScopesQuery()
  const scopeParts = chunk<Scope>(scopes ?? [], 4)

  const onScopesSelect = useCallback((e, selectedScopes: string[]) => {
    setScopes(selectedScopes)
  }, [])

  const { mutateAsync: createAudit, isLoading: isAuditCreating } = useMutation<Audit>(() =>
    apiJSON(
      'audits',
      {
        companyID,
        scopeIDs
      },
      { method: 'POST' }
    )
  )

  const { mutateAsync: editScopes, isLoading: isScopeEditing } = useMutation<{}>(
    () => apiJSON(`audits/${auditID}/scopes`, scopeIDs, { method: 'PUT' }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(`audits/${auditID}`)
        onTabChange(null, '0')
      }
    }
  )

  const onCancel = useCallback(() => {
    isEditMode ? history.replace(`/audit/${companyID}/${auditID}`) : history.goBack()
  }, [history, isEditMode, companyID, auditID])

  const onScopesSubmit = useCallback(async () => {
    if (isEditMode) {
      await editScopes()

      history.replace(`/audit/${companyID}/${auditID}`)
    } else {
      const audit = await createAudit()

      if (audit) {
        history.replace(`/audit/${companyID}/${audit.auditID}`)
      }
    }
  }, [auditID, editScopes, isEditMode, companyID, createAudit, history])

  return !areScopesLoading && !isScopeEditing ? (
    <>
      <h2 className={classes.header}>Wybierz obszary które będą weryfikowane podczas audytu</h2>
      {scopeParts.map(scopesChunk => (
        <ToggleButtonGroup
          key={scopesChunk.map(({ scopeID }) => scopeID).join('-')}
          size='large'
          orientation='horizontal'
          value={scopeIDs}
          onChange={onScopesSelect}
          className={classes.scopesContainer}>
          {scopesChunk!.map(scope => {
            return (
              <ToggleButton key={scope.scopeID} className={classes.scopeTile} value={scope.scopeID}>
                <p className={classes.scopeLabel}>{scope.name}</p>
                {scope.imageURL ? <SVG src={scope.imageURL} className={classes.scopeIcon} /> : null}
                {scopeIDs.includes(scope.scopeID) ? <Check className={classes.scopeSelectedIcon} /> : null}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      ))}
      <ButtonGroup className={classes.actionButtonsContainer}>
        <Button onClick={onCancel} variant='outlined'>
          Anuluj
        </Button>
        <ProgressButton
          isLoading={isAuditCreating}
          onClick={onScopesSubmit}
          disabled={isAuditCreating || scopeIDs.length === 0}
          variant='contained'
          color='primary'
          className={classes.actionButtonSubmit}>
          Dalej
        </ProgressButton>
      </ButtonGroup>
    </>
  ) : (
    <BackdropProgress />
  )
}

export const useStyles = makeStyles(theme => {
  return {
    scopesContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    scopeTile: {
      display: 'flex',
      flexDirection: 'column',
      width: '200px',
      height: '200px',
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[100],
      margin: '5px 10px',
      border: `1px solid ${theme.palette.grey[300]}`,
      /**
       * @todo find better way, probably overriding .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)
       */
      borderLeft: `1px solid ${theme.palette.grey[300]} !important`,
      '& span:first-child': {
        flexDirection: 'column'
      }
    },
    scopeIcon: {
      display: 'block',
      width: 80,
      height: 80,
      fill: theme.palette.primary.main
    },
    scopeLabel: {
      marginTop: 'auto'
    },
    scopeSelectedIcon: {
      position: 'absolute',
      bottom: 10,
      right: 10
    },
    header: {
      color: theme.palette.grey[600],
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight
    },
    actionButtonsContainer: {
      marginTop: '30px',
      marginLeft: 'auto',
      marginRight: '10px'
    },
    actionButtonSubmit: {
      marginLeft: 20
    }
  }
})
