import React from 'react'

import CacheBuster from 'react-cache-buster'

import { version } from '../package.json'

import { QueryClient, QueryClientProvider } from 'react-query'

import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { ApplicationRoutes } from './ApplicationRoutes'

import { ThemeProvider } from '@shared/theme/theme.provider.component'

import { AuthProvider } from '@modules/auth/auth.provider.component'
import { LoginPage } from '@modules/login/login.page.component'
import { ResetPasswordPage } from '@modules/reset-password/reset-password.page.component'
import { ForgotPasswordPage } from '@modules/forgot-password/forgot-password.page.component'
import { UpdateHandler } from '@shared/components/update-handler.component'
import { StoreProvider } from '@shared/hooks/use-store.hook'
import { initialState, reducer } from '@shared/lib/reducer'
import { BackdropProgress } from '@shared/components/backdrop-progress.component'
import useMaintenanceMode from '@shared/hooks/use-is-maintenance-mode'
import MaintenanceScreen from './MaintenanceScreen'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

function App() {
    const { isMaintenanceMode, isTester } = useMaintenanceMode()

    if (isMaintenanceMode && !isTester) {
        return <MaintenanceScreen />
    }

    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={true}
            isVerboseMode={false}
            loadingComponent={<BackdropProgress />}
        >
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <ThemeProvider>
                    <StoreProvider
                        initialState={initialState}
                        reducer={reducer}
                    >
                        <AuthProvider>
                            <Router>
                                <div className="App">
                                    <UpdateHandler>
                                        <Switch>
                                            <Route path="/resetpassword">
                                                <ResetPasswordPage />
                                            </Route>
                                            <Route path="/forgotpassword">
                                                <ForgotPasswordPage />
                                            </Route>
                                            <Route path="/login" exact>
                                                <LoginPage />
                                            </Route>
                                            <ApplicationRoutes />
                                        </Switch>
                                    </UpdateHandler>
                                </div>
                            </Router>
                        </AuthProvider>
                    </StoreProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </CacheBuster>
    )
}

export default App

/**
 * @todo replace potential reduce/map/find with _.[method] versions
 * @todo use https://react-query.tanstack.com/docs/guides/initial-query-data#initial-data instead of manual fallback
 * @todo dont destructure props
 * @todo put queries in one place or in separate files in given module scope with separate cache keys factories to invalidate query somewher eelse
 * @todo consider react-query cache warm-up when starting audit so it's available when offline (templates for example)
 * @todo useIsFetching for checking is something is fetched and indicate globally
 * @todo use custom hook to handle errors global https://codesandbox.io/s/github/tannerlinsley/react-query/tree/master/examples/custom-hooks
 * @todo rename components and variables to alignw with entities
 * @todo dont use `isLoading` for rendering query data as it can be not there yet because of error
 * @todo create generic button with loading/disabled status when async operation is done on click
 * @todo remove mobx if not needed now
 * @todo unify material-ui imports and add sorting
 * @todo passing requirement by state will fail on redirect, should fetch it separately if not present?
 * @todo investigate workbox from CRA 4.0
 * @todo consider using react-hooks-form
 * @todo investigate `shared` module to be split to `utils`, `shared-components`
 * @todo re-usable page Header component
 * @todo re-usable form buttons component
 * @todo re-usable 2 columns labe: value component
 * @todo use Material-UI Link for non styled Link
 * @todo unify API error handlin in API utils
 * @todo move use-styles to component files
 * @todo if user is online and 401 received, redirect to logn
 * @todo dont cache login response in service-worker
 * @todo instead of passing data to state when going to requirement, connect Auditrequirements with Requirement query
 * @todo on admin, reset search when changing endpoint
 * @todo consider mobx if app will use a lot of state
 * @todo fix react-query parametrized cache keys (use tuple or second parameter)
 * @todo hoist DTO errors handling from CRUD and login to one component
 * @todo investigate better showing of requirements - instead of details pane, use groupping and hide grouping bar https://material-table.com/#/docs/features/grouping
 * @todo login error alert can be re-used on multiple pages perhaps?
 */
