import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { useStore } from '@shared/hooks/use-store.hook'
import { Actions } from '@shared/lib/reducer'

interface UpdaterProps extends RouteComponentProps {
    customHook: any
}
class Updater extends Component<UpdaterProps> {
    getPathBase = (path: string) => {
        const splittedPath = path.split('/')
        const filteredPaths = splittedPath.filter((el) => el)
        return filteredPaths[0]
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        const {
            location: { pathname: currentPathname },
        } = this.props
        const {
            location: { pathname: prevPathname },
        } = prevProps

        const { dispatch } = this.props.customHook

        const prevPathBase = this.getPathBase(prevPathname)
        const currentPathBase = this.getPathBase(currentPathname)
        const isPrevPathBaseAudit = prevPathBase === 'audit'

        if (prevPathname !== currentPathname) {
            dispatch({
                type: Actions.UPDATE_PATHS,
                payload: { currentPathBase, prevPathBase },
            })
        }
        if (isPrevPathBaseAudit && prevPathBase !== currentPathBase) {
            dispatch({ type: Actions.CLEAR_EXPANDED_VALUES })
        }
    }

    render() {
        return this.props.children
    }
}

function withUseStore(Component: any) {
    return function WrappedComponent(props: any) {
        const customHookValue = useStore()
        return <Component {...props} customHook={customHookValue} />
    }
}

export const UpdateHandler = withUseStore(withRouter(Updater))
