import {
    Audit,
    AuditRequirement,
    BaseRequirement,
    RequirementType,
    Roles,
    StatusKeyTypes,
    StatusTypes,
} from '@shared/types'
import {
    MaterialTableProps,
    MTableAction,
    MTableGroupRow,
} from 'material-table'
import { GroupWithScopeAndRequirements } from '@shared/hooks/use-requirements-data.hook'
import { useMutation, useQueryClient } from 'react-query'
import { useThemeStyles } from '@shared/theme/use-theme-styles'
import { useACL } from '@shared/hooks/use-acl.hook'
import { useStatusesQuery } from '@shared/hooks/use-statuses-query.hook'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import {
    OfflineEntityType,
    useOfflineContent,
} from '@shared/hooks/use-offline-content'
import { useStore } from '@shared/hooks/use-store.hook'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRequirementMutation } from '@shared/hooks/use-requirement-mutation.hook'
import { apiJSON } from '@shared/utils/api/api.util'
import { flatten } from 'lodash'
import {
    isAuditRequirement,
    isBaseRequirement,
} from '@shared/utils/table-type-assessment'
import { getStatusColor } from '@modules/requirements/requirements.utils'
import { originalIcons } from '@shared/components/icons/icons'
import {
    TABLE_PADDING_COMMON,
    TABLE_PADDING_LEFT,
} from '@shared/components/table/table.consts'
import { Checkbox, colors, FormControlLabel, Tooltip } from '@material-ui/core'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { Actions, TableData } from '@shared/lib/reducer'
import React from 'react'
import { Table } from '@shared/components/table/table.component'
import { FilterStatusesComponent } from '@modules/requirement/filter-statuses.component'
import { Empty } from '@shared/components/empty.component'
import { ACL } from '@shared/components/acl.component'
import { ProgressButton } from '@shared/components/progress-button.component'
import { makeStyles } from '@material-ui/core/styles'
import { TableStatusIndicatorComponent } from '@modules/requirement/requirement-table/table-status-indicator.component'
import { TableRequirementDescriptionComponent } from '@modules/requirement/requirement-table/table-requirement-description.component'
import { PREVIEW_AUDIT_ID } from '@shared/consts'

export type RequirementData =
    | (AuditRequirement & { no: string })
    | (BaseRequirement & { no: string })

export type RegisteredStatus = {
    statusBaseID: number
    statusColor: string
    name: string
}

type Props = {
    auditID?: number
    isLoading: boolean
    isPreviewMode?: boolean
    trackBy?: string
    actions: MaterialTableProps<RequirementType>['actions']
    requirementsGroups: GroupWithScopeAndRequirements<RequirementType>[]
    initialScrollPosition?: 'top'
    initiallyCollapsedWithButtons?: boolean
    enableColumnOffset?: boolean
    enableColumnNo?: boolean
    enableColumnOrder?: boolean
    enableColumnStatus?: boolean
    enableColumnDontShow?: boolean
    enableDontShow?: boolean
    enableGroupRowNo?: boolean
    enableGroupRowOrder?: boolean
    enableGroupRowVerifiedCount?: boolean
    enableShowNotActive?: boolean
    currentScopeID?: number
}

export const NOT_VERIFIED_STATUS = 5
const SHOW_NOT_ACTIVE_LABEL = 'SHOW_NOT_ACTIVE_LABEL'
const DONT_SHOW_LABEL = 'Nie pokazuj'

export const RequirementsTable = (props: Props) => {
    const classes = useStyles()
    const queryClient = useQueryClient()
    const themeClasses = useThemeStyles()
    const { is } = useACL()
    const { statuses } = useStatusesQuery()
    const { isOffline } = useNavigatorOnline()
    const offlineContent = useOfflineContent()
    const { state, dispatch } = useStore()
    const [filteredData, setFilteredData] = useState<any[] | null>(null)
    const [activeFilters, setActiveFilters] = useState<number[]>([])
    const [registeredStatuses, setRegisteredStatuses] =
        useState<RegisteredStatus[]>()
    const [isNotActiveMode, setIsNoActiveMode] = useState(false)
    const { updateAuditRequirement } = useRequirementMutation(props.auditID)

    const dontShowMutation = useMutation(
        async ({
            auditID,
            groupID,
            newAuditRequirements,
        }: {
            auditID: number
            groupID: number
            newAuditRequirements: AuditRequirement[]
        }) => {
            await queryClient.cancelQueries(`audits/${auditID}`)

            const previousAudit = queryClient.getQueryData<Audit>(
                `audits/${auditID}`
            )
            if (previousAudit) {
                previousAudit.auditRequirements =
                    previousAudit.auditRequirements.map((req) => {
                        return (
                            newAuditRequirements.find(
                                (i) =>
                                    i.auditRequirementID ===
                                    req.auditRequirementID
                            ) ?? req
                        )
                    })
                await queryClient.setQueryData(
                    `audits/${auditID}`,
                    previousAudit
                )
            }

            return await apiJSON(
                'audits/batch',
                { auditID, groupID },
                { method: 'PUT' }
            )
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(
                    `audits/${props.auditID}/auditrequirements?synchronize=true`
                )
            },
        }
    )

    const DONT_SHOW_STATUS_OBJECT = statuses?.find(
        (status) => status.label === StatusTypes.NoShow
    )

    const onNotActiveModeChange = useCallback((event) => {
        setIsNoActiveMode(event.target.checked)
    }, [])

    const hasClientComments = (
        groups: GroupWithScopeAndRequirements<RequirementType>[]
    ) =>
        groups.some((group) =>
            group.requirements.some(
                (requirement) =>
                    (requirement as AuditRequirement)?.clientStatus?.comment
            )
        )

    const displayClientsComments =
        (Boolean(props.isPreviewMode) ||
            is([Roles.Client, Roles.ClientEditor])) &&
        hasClientComments(props.requirementsGroups)

    const data: RequirementData[] = useMemo(
        () =>
            flatten(
                props.requirementsGroups.map((group) =>
                    group.requirements
                        .filter(
                            (requirement) =>
                                isAuditRequirement(requirement) ||
                                (isBaseRequirement(requirement) &&
                                    ((isNotActiveMode &&
                                        !(requirement as BaseRequirement)
                                            .isPublished) ||
                                        (!isNotActiveMode &&
                                            (requirement as BaseRequirement)
                                                .isPublished)))
                        )
                        .map((requirement) => ({
                            ...requirement,
                            ...(isAuditRequirement(requirement)
                                ? {
                                      colors: {
                                          client: getStatusColor(
                                              statuses ?? [],
                                              requirement.clientStatus
                                                  .statusBaseID
                                          ),
                                          auditor: getStatusColor(
                                              statuses ?? [],
                                              requirement.auditorStatus
                                                  .statusBaseID
                                          ),
                                      },
                                  }
                                : {}),
                        }))
                )
            ),
        [props.requirementsGroups, isNotActiveMode, statuses]
    )

    const auditScopeData = useMemo(
        () =>
            state.requirementTableData.find(
                (data: TableData) =>
                    data.auditID === props.auditID &&
                    data.scopeID === props.currentScopeID
            ),
        [props.auditID, props.currentScopeID, state.requirementTableData]
    )

    useEffect(() => {
        if (auditScopeData !== undefined) {
            setActiveFilters(auditScopeData.filters)
        }
    }, [auditScopeData, data])

    useEffect(() => {
        let updatedData = data
        if (auditScopeData?.filters.length > 0) {
            updatedData = updatedData.filter((requirement) =>
                auditScopeData?.filters.includes(
                    (requirement as AuditRequirement).auditorStatus.statusBaseID
                )
            )
        }

        if (state.isDraftFilterActive) {
            updatedData = updatedData.filter(
                (requirement) => (requirement as AuditRequirement).isDraft
            )
        }

        setFilteredData(updatedData)
    }, [auditScopeData?.filters, data, state.isDraftFilterActive])

    useEffect(() => {
        dispatch({
            type: Actions.SET_REQUIREMENT_TABLE_DATA,
            payload: {
                auditID: props.auditID,
                filters: activeFilters,
                scopeID: props.currentScopeID || undefined,
            },
        })
    }, [activeFilters, dispatch, props.auditID, props.currentScopeID])

    useEffect(() => {
        const existingStatuses: RegisteredStatus[] = (
            data as AuditRequirement[]
        ).reduce<RegisteredStatus[]>((current, el: AuditRequirement) => {
            if (
                !el.auditorStatus ||
                !el.colors ||
                current.some(
                    (item) =>
                        item.statusBaseID === el.auditorStatus.statusBaseID
                )
            ) {
                return current
            }

            return [
                ...current,
                {
                    statusBaseID: el.auditorStatus.statusBaseID,
                    statusColor: el.colors.auditor,
                    name:
                        (statuses || []).find(
                            ({ statusID }) =>
                                el.auditorStatus.statusBaseID === statusID
                        )?.name || '',
                },
            ]
        }, [])

        setRegisteredStatuses(existingStatuses)
    }, [data])

    const groupList = useMemo<string[]>(
        () => props.requirementsGroups.map((item) => item.name),
        [props.requirementsGroups]
    )

    const onDontShowElement = async (
        e: Event,
        requirement: AuditRequirement
    ) => {
        if (DONT_SHOW_STATUS_OBJECT) {
            const updatedRequirement = {
                ...requirement,
                isDraft: false,
                [StatusKeyTypes.AUDITOR_STATUS]: {
                    ...requirement[StatusKeyTypes.AUDITOR_STATUS],
                    statusBaseID: DONT_SHOW_STATUS_OBJECT.statusID,
                },
            }
            offlineContent.set(
                OfflineEntityType.Requirement,
                updatedRequirement
            )
            await updateAuditRequirement(updatedRequirement)
        }
    }

    const onDontShowGroup = async (groupID: number) => {
        if (props.auditID) {
            dontShowMutation.mutate({
                auditID: props.auditID,
                groupID,
                newAuditRequirements: props.requirementsGroups
                    .filter((item) => item.groupID === groupID)[0]
                    .requirements.map((requirement) => {
                        if (
                            DONT_SHOW_STATUS_OBJECT &&
                            'auditorStatus' in requirement
                        ) {
                            const updatedRequirement: RequirementType = {
                                ...requirement,
                                isDraft: false,
                                [StatusKeyTypes.AUDITOR_STATUS]: {
                                    ...requirement?.auditorStatus,
                                    statusBaseID:
                                        DONT_SHOW_STATUS_OBJECT.statusID,
                                },
                            }
                            offlineContent.set(
                                OfflineEntityType.Requirement,
                                updatedRequirement
                            )
                            // updateAuditRequirement(updatedRequirement)
                            return updatedRequirement
                        }
                        return requirement as AuditRequirement
                    }),
            })
        }
    }

    const actions = [
        props.enableColumnDontShow
            ? {
                  icon: originalIcons.VisibilityOff,
                  tooltip: DONT_SHOW_LABEL,
                  onClick: onDontShowElement,
              }
            : false,
        ...(props.actions ?? []),
        props.enableShowNotActive
            ? { isFreeAction: true, tooltip: SHOW_NOT_ACTIVE_LABEL }
            : false,
    ].filter(Boolean) as MaterialTableProps<RequirementType>['actions']

    const expandAll = useCallback(() => {
        dispatch({ type: Actions.ADD_EXPANDED_LIST, payload: groupList })
    }, [dispatch, groupList])

    const collapseAll = useCallback(() => {
        dispatch({ type: Actions.REMOVE_EXPANDED_LIST, payload: groupList })
    }, [dispatch, groupList])

    const columns = [
        props.enableColumnOffset
            ? {
                  field: 'offset',
                  width: '1%',
                  cellStyle: {
                      ...TABLE_PADDING_COMMON,
                  },
              }
            : false,
        props.enableColumnOrder
            ? {
                  field: 'order',
                  width: '4%',
                  cellStyle: {
                      ...TABLE_PADDING_COMMON,
                      ...TABLE_PADDING_LEFT,
                  },
              }
            : false,
        props.enableColumnNo
            ? {
                  field: 'no',
                  width: '5%',
                  cellStyle: {
                      ...TABLE_PADDING_COMMON,
                      ...TABLE_PADDING_LEFT,
                      textAlign: 'center',
                  },
              }
            : false,
        {
            title: '',
            field: 'group.name',
            defaultGroupOrder: 0,
            customSort: () => {
                /**
                 * Here we should sort by order instead of name, but we have only `group.name` data.
                 * We can point to `group` as object and customize rendering of `GroupRow` to avoid crashing.
                 * Instead, ...simply do nothing so default sorting of data delivered to table is left intact
                 */
            },
            cellStyle: {
                ...TABLE_PADDING_COMMON,
            },
        },
        props.enableColumnStatus
            ? {
                  field: 'color',
                  render: (requirement: RequirementType) => {
                      if (!isAuditRequirement(requirement)) return null

                      return (
                          <TableStatusIndicatorComponent
                              requirement={requirement}
                              isPreviewMode={props.isPreviewMode}
                          />
                      )
                  },
                  width: '10%',
                  cellStyle: {
                      ...TABLE_PADDING_COMMON,
                      ...TABLE_PADDING_LEFT,
                  },
              }
            : false,
        {
            field: 'name',
            width: '15%',
            cellStyle: {
                ...TABLE_PADDING_COMMON,
            },
        },
        {
            field: 'description',
            width: '75%',
            render: (requirement: RequirementType) =>
                isAuditRequirement(requirement) ? (
                    <TableRequirementDescriptionComponent
                        requirement={requirement}
                        isPreviewMode={props.isPreviewMode}
                        displayClientsComments={displayClientsComments}
                    />
                ) : (
                    <>{requirement.description}</>
                ),
            cellStyle: {
                ...TABLE_PADDING_COMMON,
                ...TABLE_PADDING_LEFT,
            },
        },
    ].filter(Boolean) as MaterialTableProps<RequirementType>['columns']

    const pageSize =
        props.requirementsGroups.length < 100
            ? props.requirementsGroups.length
            : 100

    return (
        <div className={classes.tableContainer}>
            <div className={themeClasses.tableContainer}>
                <Table<RequirementType>
                    pageSize={pageSize}
                    title="Wymagania"
                    trackScroll
                    initialScrollPosition={props.initialScrollPosition}
                    trackPanelsBy={props.trackBy}
                    isLoading={props.isLoading}
                    options={{
                        grouping: true,
                        rowStyle: (rowData: AuditRequirement) => {
                            if (rowData.isDraft) {
                                return {
                                    backgroundColor: colors.grey['200'],
                                }
                            }

                            if (
                                rowData.isOutdated &&
                                is([Roles.Client, Roles.ClientEditor])
                            ) {
                                return {
                                    backgroundColor: colors.red['200'],
                                }
                            }

                            return {}
                        },
                    }}
                    data={filteredData || data}
                    columns={columns}
                    actions={actions}
                    additionalComponent={
                        registeredStatuses && (
                            <FilterStatusesComponent
                                registeredStatuses={registeredStatuses}
                                setFilteredData={setFilteredData}
                                data={data}
                                activeFilters={activeFilters}
                                setActiveFilters={setActiveFilters}
                                initiallyCollapsedWithButtons={
                                    props.initiallyCollapsedWithButtons
                                }
                                expandAll={expandAll}
                                collapseAll={collapseAll}
                                isExpanded={state.expandedValues.length}
                                isPreviewMode={props.isPreviewMode}
                            />
                        )
                    }
                    components={{
                        Groupbar: Empty,
                        Header: Empty,
                        Action: (actionProps) => {
                            return actionProps.action.tooltip ===
                                SHOW_NOT_ACTIVE_LABEL ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="filter-finished"
                                            color="primary"
                                            checked={isNotActiveMode}
                                            onChange={onNotActiveModeChange}
                                        />
                                    }
                                    label="Pokaż nieaktywne"
                                    className={classes.filterCheckboxForm}
                                />
                            ) : (
                                <MTableAction {...actionProps} />
                            )
                        },
                        GroupRow: (groupRowProps) => {
                            const currentValue = groupRowProps.groupData.value
                            const currentGroupIndex =
                                props.requirementsGroups.findIndex(
                                    (group) => group.name === currentValue
                                )

                            const currentGroup =
                                props.requirementsGroups[currentGroupIndex]
                            const verifiedTotal =
                                currentGroup.requirements.length
                            const verified = currentGroup.requirements.reduce(
                                (count: number, requirement: RequirementType) =>
                                    count +
                                    (isAuditRequirement(requirement) &&
                                    requirement.auditorStatus.statusBaseID !==
                                        NOT_VERIFIED_STATUS &&
                                    requirement.auditorStatus.statusBaseID !==
                                        8 &&
                                    !requirement.isDraft
                                        ? 1
                                        : 0),
                                0
                            )

                            const newProps = {
                                ...groupRowProps,

                                groupData: {
                                    ...groupRowProps.groupData,
                                    isExpanded:
                                        props.initiallyCollapsedWithButtons
                                            ? state.expandedValues?.includes(
                                                  currentValue
                                              )
                                            : !state.expandedValues?.includes(
                                                  currentValue
                                              ),
                                    value: (
                                        <>
                                            <div className={classes.groupRow}>
                                                <ShowConditionally
                                                    when={
                                                        props.enableGroupRowOrder
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            paddingRight: 20,
                                                        }}
                                                    >
                                                        {currentGroup.order}.
                                                    </span>
                                                </ShowConditionally>
                                                <ShowConditionally
                                                    when={
                                                        props.enableGroupRowNo
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            paddingRight: 20,
                                                        }}
                                                    >
                                                        {currentGroup.no}.
                                                    </span>
                                                </ShowConditionally>
                                                <span style={{ flex: 1 }}>
                                                    {
                                                        groupRowProps.groupData
                                                            .value
                                                    }
                                                </span>
                                                <ShowConditionally
                                                    when={
                                                        props.enableGroupRowVerifiedCount
                                                    }
                                                >
                                                    <span>
                                                        {verified}/
                                                        {verifiedTotal}{' '}
                                                        zweryfikowano
                                                    </span>
                                                </ShowConditionally>
                                                <ShowConditionally
                                                    when={props.enableDontShow}
                                                >
                                                    <ACL
                                                        allowedRoles={[
                                                            Roles.Admin,
                                                            Roles.Auditor,
                                                        ]}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                DONT_SHOW_LABEL
                                                            }
                                                        >
                                                            <ProgressButton
                                                                variant="outlined"
                                                                className={
                                                                    classes.dontShowButton
                                                                }
                                                                onClick={() =>
                                                                    onDontShowGroup(
                                                                        currentGroup.groupID
                                                                    )
                                                                }
                                                                disabled={
                                                                    dontShowMutation.isLoading &&
                                                                    isOffline
                                                                }
                                                                isLoading={
                                                                    dontShowMutation.isLoading &&
                                                                    dontShowMutation
                                                                        ?.variables
                                                                        ?.groupID ===
                                                                        currentGroup.groupID
                                                                }
                                                            >
                                                                <originalIcons.VisibilityOff />
                                                            </ProgressButton>
                                                        </Tooltip>
                                                    </ACL>
                                                </ShowConditionally>
                                            </div>
                                        </>
                                    ),
                                },
                            }

                            return (
                                <MTableGroupRow
                                    {...newProps}
                                    className={classes.groupRow}
                                />
                            )
                        },
                    }}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    deprecatedWarning: {
        marginLeft: 10,
        color: theme.palette.error.main,
    },
    colorCircle: {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
    },
    groupRow: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: 'calc(100% - 42px)',
        position: 'relative',
        left: -10,
        backgroundColor: theme.palette.common.white,
    },
    filterCheckboxForm: {
        flex: 1,
        marginLeft: '10px',
    },
    dontShowButton: {
        height: 20,
        marginLeft: theme.spacing(2),
        border: 'none',
    },
    tableContainer: {
        position: 'relative',
        '& .MuiTableCell-paddingNone:empty': {
            width: '0 !important',
        },
    },
    progressOverlay: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
    },
}))
