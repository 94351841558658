import React from 'react'
import { Breadcrumbs, Grid, Typography } from '@material-ui/core'
import { useThemeStyles } from '@shared/theme/use-theme-styles'
import { AuditRequirementEnhanced, Status } from '@shared/types'
import { StatusText } from '@shared/utils/get-status-text'
import { statusContainerContent } from '@modules/requirement/requirement-form/consts'
import { StatusContainer } from '@modules/requirement/requirement-form/status-container.component'

interface Props {
    requirement: AuditRequirementEnhanced
    statuses: Status[]
}

export const FormDetails = ({ requirement, statuses }: Props) => {
    const themeClasses = useThemeStyles()

    return (
        <Grid
            container
            className={themeClasses.detailsContainer}
            justify="space-between"
        >
            <Grid>
                <h1 className={themeClasses.detailsHeader}>
                    {requirement.name}
                </h1>
                <Breadcrumbs
                    className={themeClasses.detailsBreadcrumbsContainer}
                >
                    {requirement.scope && (
                        <Typography
                            className={themeClasses.detailsBreadcrumbsItem}
                        >
                            {requirement.scope.name}
                        </Typography>
                    )}
                    {requirement.group && (
                        <Typography
                            className={themeClasses.detailsBreadcrumbsItem}
                        >
                            {requirement.group.name}
                        </Typography>
                    )}
                </Breadcrumbs>
            </Grid>
            <Grid>
                {statusContainerContent.map((content) => (
                    <StatusContainer
                        key={content.role}
                        requirement={requirement}
                        role={content.role as keyof StatusText}
                        title={content.title}
                        statuses={statuses}
                    />
                ))}
            </Grid>
        </Grid>
    )
}
