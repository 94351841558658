import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import { getStatusColorStyles } from '@modules/requirements/requirements.utils'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { AuditRequirement, Roles } from '@shared/types'
import { originalIcons } from '@shared/components/icons/icons'
import { useACL } from '@shared/hooks/use-acl.hook'

interface Props {
    requirement: AuditRequirement & { colors: Record<string, string> }
    isPreviewMode: boolean | undefined
}

export const TableStatusIndicatorComponent = ({
    requirement,
    isPreviewMode,
}: Props) => {
    const classes = useStyles()

    const { is } = useACL()

    return (
        <div style={{ display: 'flex' }}>
            <Tooltip title="Status audytora">
                <div
                    className={classes.colorCircle}
                    style={{
                        ...getStatusColorStyles(requirement.colors.auditor),
                        marginRight: 10,
                    }}
                />
            </Tooltip>
            <ShowConditionally
                when={
                    is([Roles.Client, Roles.ClientEditor]) ||
                    Boolean(isPreviewMode)
                }
            >
                <>
                    <Tooltip title="Status klienta">
                        <div
                            className={classes.colorCircle}
                            style={getStatusColorStyles(
                                requirement.colors.client
                            )}
                        />
                    </Tooltip>
                    {requirement.isOutdated ? (
                        <Tooltip
                            title="Treść tego wymagania zmieniła się od czasu wykonania audytu. Potwierdzenie aktualności statusu wymaga ponownej oceny."
                            aria-label="deprecation-warning"
                        >
                            <originalIcons.Error
                                className={classes.deprecatedWarning}
                            />
                        </Tooltip>
                    ) : null}
                </>
            </ShowConditionally>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    deprecatedWarning: {
        marginLeft: 10,
        color: theme.palette.error.main,
    },
    colorCircle: {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
    },
}))
