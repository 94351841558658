import { useQuery } from 'react-query'

import { apiJSON } from '@shared/utils/api/api.util'

import { getResourceParametrizedEndpoint } from '@shared/utils/get-resource-parametrized-endpoint'
import { NO_REFETCH_OPTIONS } from '@shared/utils/api/api.consts'

export function useResourceQuery(resourceEndpoint: string, resourceID?: string) {
  const finalEndpoint = resourceID ? getResourceParametrizedEndpoint(resourceEndpoint, resourceID) : resourceEndpoint

  const {
    data: resourceData,
    refetch: readResourceData,
    isLoading: isResourceDataFetching
  } = useQuery(finalEndpoint, () => apiJSON(finalEndpoint), {
    /**
     * @todo move to shared consts
     */
    enabled: false,
    ...NO_REFETCH_OPTIONS
  })

  return { resourceData, readResourceData, isResourceDataFetching }
}
