import React from 'react'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { AuditRequirement, Roles } from '@shared/types'
import { useACL } from '@shared/hooks/use-acl.hook'
import { TableRequirementCommentComponent } from '@modules/requirement/requirement-table/table-requirement-comment.component'
import {
    ViewRowTableField,
    ViewRowTableFieldPropertyEnum,
} from '@shared/lib/reducer'
import { useStore } from '@shared/hooks/use-store.hook'
import { getStatusColorStyles } from '@modules/requirements/requirements.utils'

interface Props {
    requirement: AuditRequirement & { colors: Record<string, string> }
    isPreviewMode: boolean | undefined
    displayClientsComments?: boolean
}

export const TableRequirementDescriptionComponent = ({
    requirement,
    isPreviewMode,
    displayClientsComments,
}: Props) => {
    const { is } = useACL()
    const { state } = useStore()
    const viewRowTableFields = state.viewRowTableFields as ViewRowTableField[]
    const isLegalRequirementVisible = !!viewRowTableFields.find(
        ({ property }) =>
            property === ViewRowTableFieldPropertyEnum.LEGAL_REQUIREMENT
    )?.selected
    const isConformityAssessmentVisible = !!viewRowTableFields.find(
        ({ property }) =>
            property === ViewRowTableFieldPropertyEnum.CONFORMITY_ASSESSMENT
    )?.selected
    const isActionTakenVisible = !!viewRowTableFields.find(
        ({ property }) =>
            property === ViewRowTableFieldPropertyEnum.ACTION_TAKEN
    )?.selected

    return (
        <>
            <ShowConditionally when={isLegalRequirementVisible}>
                <>{requirement.description}</>
            </ShowConditionally>
            <ShowConditionally
                when={
                    isConformityAssessmentVisible &&
                    requirement.auditorAssessment
                }
            >
                <TableRequirementCommentComponent
                    content={requirement.auditorAssessment}
                    variant={
                        ViewRowTableFieldPropertyEnum.CONFORMITY_ASSESSMENT
                    }
                    mainColor={
                        getStatusColorStyles(requirement.colors.auditor)
                            ?.backgroundColor
                    }
                />
            </ShowConditionally>
            <ShowConditionally
                when={
                    isActionTakenVisible &&
                    requirement.clientStatus?.comment?.length
                }
            >
                <TableRequirementCommentComponent
                    content={requirement.clientStatus.comment || ''}
                    variant={ViewRowTableFieldPropertyEnum.ACTION_TAKEN}
                />
            </ShowConditionally>
        </>
    )
}
