import React from 'react'
import {
    CircularProgress,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'
import { makeStyles } from '@material-ui/core/styles'
import { SummaryTemplate } from '@shared/types'

interface Props {
    selectedTemplate: SummaryTemplate | null
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
    displayedName: () => string
    templates: SummaryTemplate[]
    onEditTemplate: () => Promise<void>
    isTemplateEditing: boolean
    isTemplateDeleting: boolean
    onDeleteTemplate: () => Promise<void>
    handleChangeSelectedTemplate: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void
}

export const SummaryTemplateSelect = ({
    selectedTemplate,
    handleChange,
    displayedName,
    templates,
    onEditTemplate,
    isTemplateEditing,
    isTemplateDeleting,
    onDeleteTemplate,
    handleChangeSelectedTemplate,
}: Props) => {
    const classes = useStyles()

    return (
        <>
            <Select
                displayEmpty={!selectedTemplate}
                className={classes.input}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={selectedTemplate?.description}
                renderValue={() =>
                    !selectedTemplate
                        ? 'Wybierz szablon podsumowania'
                        : displayedName()
                }
            >
                {templates?.map((template) => (
                    <MenuItem
                        key={template.summaryTemplateID}
                        value={template.summaryTemplateID}
                    >
                        {template.description.length > 50
                            ? `${template.description.slice(0, 50)}...`
                            : template.description}
                    </MenuItem>
                ))}
            </Select>
            {selectedTemplate && (
                <Grid container alignItems="center" justify="space-between">
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justify="flex-end"
                        className={classes.input}
                    >
                        <IconButton
                            className={classes.icon}
                            onClick={onEditTemplate}
                        >
                            {isTemplateEditing ? (
                                <CircularProgress />
                            ) : (
                                <originalIcons.Check />
                            )}
                        </IconButton>
                        <IconButton
                            className={classes.icon}
                            onClick={onDeleteTemplate}
                        >
                            {isTemplateDeleting ? (
                                <CircularProgress />
                            ) : (
                                <originalIcons.Delete />
                            )}
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={handleChangeSelectedTemplate}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            rowsMax="Infinity"
                            value={selectedTemplate?.description}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(1),
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        padding: 0,
        marginLeft: theme.spacing(2),
    },
}))
