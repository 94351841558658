import React, { useCallback, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { isEmpty, isEqual, omitBy, uniq } from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonGroup, Grid, Typography, Divider, Breadcrumbs } from '@material-ui/core'

import { apiJSON } from '@shared/utils/api/api.util'
import { useRouter } from '@shared/hooks/use-router'
import { useAuditQuery } from '@shared/hooks/use-audit-query.hook'
import { ProgressButton } from '@shared/components/progress-button.component'
import { TextField } from '@shared/components/text-field.component'
import { useScopesQuery } from '@shared/hooks/use-scopes-query.hook'
import { useThemeStyles } from '@shared/theme/use-theme-styles'
import { Audit, Location } from '@shared/types'

import { NO_REFETCH_OPTIONS } from '@shared/utils/api/api.consts'
import { useCompanyQuery } from '@shared/hooks/use-company-query.hook'
import { OfflineEntityType, useOfflineContent } from '@shared/hooks/use-offline-content'
import { usePrevious } from '@shared/hooks/use-previous.hook'
import { registerInputRefFactory } from '@modules/admin-generic/admin-generic.utils'

type Fields<T, K = {}> = ({ key: keyof T; label: string } & K)[]

export const COMPANY_FIELDS: Fields<Location> = [
  { key: 'name', label: 'Klient' },
  { key: 'companyHeadquaters', label: 'Adres siedziby' }
]

export function MetricsPage() {
  const { query, history } = useRouter<{ companyID: number; auditID: number }>()
  const { companyID, auditID } = query
  const returnURL = `/audit/${companyID}/${auditID}`

  const offlineContent = useOfflineContent()
  const classes = useStyles()
  const themeClasses = useThemeStyles()
  const queryClient = useQueryClient()

  const { scopes } = useScopesQuery()
  const { company } = useCompanyQuery(companyID)

  const { audit: localAudit } = useAuditQuery(auditID, NO_REFETCH_OPTIONS)
  const offlineAudit = offlineContent.get<Audit>(OfflineEntityType.Audit)
  const audit = offlineAudit ?? localAudit
  const previousAudit = usePrevious(audit)

  const auditScopeNames = (audit && scopes
    ? uniq(audit.auditRequirements.map(({ scopeID }) => scopeID) ?? [])
        .map(scopeID => scopes.find(scope => scope.scopeID === scopeID)?.name ?? false)
        .filter(Boolean)
    : []) as unknown as string[]

  const { register, handleSubmit, reset } = useForm<Audit>()
  const registerInputRef = registerInputRefFactory(register)

  useEffect(() => {
    if (!isEqual(previousAudit, audit)) {
      reset({
        ...omitBy(audit, isEmpty)
      })
    }
  }, [audit, previousAudit, reset])

  const { mutateAsync: updateAudit, isLoading: isAuditUpdating } = useMutation(
    (audit: Audit) => apiJSON(`audits/${auditID}`, audit, { method: 'PUT' }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(`audits/${auditID}`)
      }
    }
  )

  const onSubmit = useCallback(
    async (data: Audit) => {
      try {
        const newAuditData = { ...audit, ...data }

        offlineContent.set(OfflineEntityType.Audit, newAuditData)

        await updateAudit(newAuditData)
      } catch (e) {
      } finally {
        history.push(returnURL)
      }
    },
    [updateAudit, audit, history, returnURL, offlineContent]
  )

  return audit && company ? (
    <>
      <div className={themeClasses.detailsContainer}>
        <h1 className={themeClasses.detailsHeader}>Metryka Raportu</h1>
        <Breadcrumbs className={themeClasses.detailsBreadcrumbsContainer}>
          {auditScopeNames.map((scopeName: string) => (
            <Typography key={scopeName} className={themeClasses.detailsBreadcrumbsItem}>
              {scopeName}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
      <Grid container>
        {COMPANY_FIELDS.map(({ label, key }) => (
          <>
            <Grid key={`${key}-label`} item xs={4}>
              <Typography variant='h6'>{label}</Typography>
            </Grid>
            <Grid key={`${key}-value`} item xs={8}>
              <Typography variant='body2'>{company[key]}</Typography>
            </Grid>
          </>
        ))}
        <Divider className={classes.divider} />
        <form noValidate id='metrics'>
          <TextField {...registerInputRef('auditNumber')} rows={4} label='Numer raportu' />
          <TextField {...registerInputRef('legalStatusVersion')} rows={4} label='Wersja/Data stanu prawnego' />
          <TextField {...registerInputRef('preparationDate')} rows={4} label='Data oceny' />
          <TextField {...registerInputRef('reportDate')} rows={4} label='Data raportu' />
          <TextField {...registerInputRef('auditors')} rows={4} label='Audytorzy' multiline />
          <TextField {...registerInputRef('localization')} rows={4} label='Lokalizacja' multiline />
          <TextField {...registerInputRef('activities')} rows={4}  label='Zakres działalności' multiline />
          <TextField {...registerInputRef('auditParticipants')} rows={4} label='Osoby uczestniczące w ocenie' multiline />
          <TextField {...registerInputRef('additionalInformation')} rows={4} label='Dodatkowe informacje' multiline />
          <Grid container justify='space-between'>
            <Grid item xs={6}>
              <TextField
                {...registerInputRef('introductionLeft')}
                rows={14}
                label='Wstęp kolumna lewa'
                multiline
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...registerInputRef('introductionRight')}
                rows={14}
                label='Wstęp kolumna prawa'
                multiline
              />
            </Grid>
          </Grid>
          <TextField {...registerInputRef('summary')} rows={4} label='Podsumowanie audytu' multiline />
        </form>
      </Grid>
      <ButtonGroup className={classes.actionButtonsContainer}>
        <Link to={returnURL} style={{ textDecoration: 'none' }}>
          <Button variant='outlined' color='primary'>
            Anuluj
          </Button>
        </Link>
        <ProgressButton
          onClick={handleSubmit(onSubmit)}
          form='metrics'
          isLoading={isAuditUpdating}
          variant='contained'
          color='primary'
          className={classes.actionButtonSubmit}>
          Zapisz
        </ProgressButton>
      </ButtonGroup>
    </>
  ) : null
}

export const useStyles = makeStyles(theme => {
  /**
   * @todo possible styles duplication
   */
  return {
    divider: {
      width: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    actionButtonsContainer: {
      marginTop: '30px',
      marginLeft: 'auto',
      marginRight: '10px'
    },
    actionButtonSubmit: {
      marginLeft: 20
    }
  }
})
