import React from 'react'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#25A956',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '8px',
                textTransform: 'none',
                boxShadow: 'none !important',
            },
            containedSecondary: {
                minWidth: 0,
                padding: '5px 15px',
                border: '1px solid #FEEBEB',
                backgroundColor: '#FEEBEB',
                color: '#EF4545',
                '&:hover': {
                    backgroundColor: '#ffd1d1',
                },
            },
        },
    },
})

type Props = {
    children: React.ReactNode
}

export function ThemeProvider(props: Props) {
    return <MUIThemeProvider theme={theme}>{props.children}</MUIThemeProvider>
}
