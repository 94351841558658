import { AuditRequirementEnhanced, Status } from '@shared/types'

export type StatusText = { auditor: string; client: string }

export const getStatusText = (
    statuses: Status[],
    requirement: AuditRequirementEnhanced
): StatusText => {
    const auditor =
        statuses.find(
            (status) =>
                status.statusID === requirement?.auditorStatus.statusBaseID
        )?.name ?? '-'

    const client =
        statuses.find(
            (status) =>
                status.statusID === requirement?.clientStatus.statusBaseID
        )?.name ?? '-'

    return {
        auditor,
        client,
    }
}
