import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, CircularProgress } from '@material-ui/core'

export function BackdropProgress() {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export const useStyles = makeStyles(theme => {
  return {
    backdrop: {
      zIndex: 1,
      backgroundColor: theme.palette.grey[300]
    }
  }
})
