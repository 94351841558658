import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { api } from '@shared/utils/api/api.util'

import { getResourceParametrizedEndpoint } from '@shared/utils/get-resource-parametrized-endpoint'

export function useResourceRemove(resourceEndpoint: string, options?: { isFinalEndpoint?: boolean; invalidateEndpoint?: string }) {
  const queryClient = useQueryClient()

  const [isInvalidatingAfterRemove, setIsInvalidating] = useState<boolean>(false)
  const [isRemovalDialogOpened, setRemovalDialogOpened] = useState<boolean>(false)
  const [removedResourceID, setRemovedResourceID] = useState<string | null>(null)

  const { mutateAsync: removeResource, isLoading: isResourceRemoving } = useMutation<any, unknown, string>(
    resourceID => {
      const finalEndpoint = options?.isFinalEndpoint
        ? `${resourceEndpoint}/${resourceID}`
        : getResourceParametrizedEndpoint(resourceEndpoint, resourceID)

      return api(finalEndpoint, {}, { method: 'DELETE' })
    },
    {
      onSuccess: async () => {
        setIsInvalidating(true)
        await queryClient.invalidateQueries(options?.invalidateEndpoint ?? resourceEndpoint)
        setIsInvalidating(false)
      }
    }
  )

  const onResourceRemove = useCallback(
    async (result: boolean) => {
      if (result && removedResourceID) {
        await removeResource(removedResourceID)
      }

      setRemovalDialogOpened(false)
    },
    [setRemovalDialogOpened, removeResource, removedResourceID]
  )

  return useMemo(
    () => ({
      isRemovalDialogOpened,
      isResourceRemoving,
      setRemovalDialogOpened,
      setRemovedResourceID,
      onResourceRemove,
      isInvalidatingAfterRemove
    }),
    [
      isRemovalDialogOpened,
      isResourceRemoving, 
      setRemovalDialogOpened, 
      setRemovedResourceID,
      onResourceRemove,
      isInvalidatingAfterRemove
    ]
  )
}
