import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { isEqual } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { apiJSON } from '@shared/utils/api/api.util'
import { Resource } from './admin-generic.types'
import { AdminGenericCrudSummaryTemplateComponent } from '@modules/admin-generic/admin-generic-crud-summary-template.component'
import { Template } from '@shared/types'
import { useRouter } from '@shared/hooks/use-router'

type Props = {
    multiple?: boolean
    defaultValue?: any
    label: string
    name: string
    resourceConfig: Resource
}

type RouteQuery = {
    resourceID: string
}

export const AdminGenericCrudSelect = ({
    multiple,
    defaultValue,
    label,
    name,
    resourceConfig,
}: Props) => {
    const { control } = useFormContext()
    const classes = useStyles()
    const { query } = useRouter<RouteQuery>()
    const { data } = useQuery(resourceConfig.endpoint, () =>
        apiJSON(`${resourceConfig.endpoint}`)
    )
    const includesSummaryTemplates = Boolean(
        data?.find((element: any) => element.summaryTemplates)
    )

    if (includesSummaryTemplates) {
        const templateData = data.find(
            (element: Template) =>
                element.descriptionTemplateID === +query.resourceID
        )

        return (
            <AdminGenericCrudSummaryTemplateComponent
                templateData={templateData}
                endpoint={resourceConfig.endpoint}
            />
        )
    }

    if (!data) return null

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ?? multiple ? [] : {}}
            render={(formControllerProps) => (
                <Autocomplete
                    value={formControllerProps.field.value}
                    autoHighlight
                    multiple={!includesSummaryTemplates && multiple}
                    className={classes.autocomplete}
                    options={data}
                    getOptionLabel={(option: { [key: string]: string }) =>
                        option?.name ??
                        option?.[`${resourceConfig.singular}Name`] ??
                        ''
                    }
                    getOptionSelected={isEqual}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                        />
                    )}
                    onChange={(e, data) =>
                        formControllerProps.field.onChange(data)
                    }
                    filterSelectedOptions
                    {...formControllerProps}
                />
            )}
        />
    )
}

/**
 * @deprecated use theme styles
 */
export const useStyles = makeStyles((theme) => ({
    autocomplete: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))
