/**
 * @todo implement persistent uncollapsed section in table, otherwise they are kept closed after each fetch
 */

import { Audit, AuditRequirement, RequirementType, Roles } from '@shared/types'
import { Tab, Tabs } from '@material-ui/core'
import {
    OfflineEntityType,
    useOfflineContent,
} from '@shared/hooks/use-offline-content'
import React, { useCallback, useEffect } from 'react'
import { TabContext, TabPanel } from '@material-ui/lab'

import { AuditCacheWarmup } from './audit-cache-warmpup.component'
import { BackdropProgress } from '@shared/components/backdrop-progress.component'
import { GroupWithScopeAndRequirements } from '@shared/hooks/use-requirements-data.hook'
import { PREVIEW_AUDIT_ID } from '@shared/consts'
import { RequirementsTable } from '@modules/requirement/requirement-table/requirements-table.component'
import { makeStyles } from '@material-ui/core/styles'
import { originalIcons } from '@shared/components/icons/icons'
import { useACL } from '@shared/hooks/use-acl.hook'
import { useAuditQuery } from '@shared/hooks/use-audit-query.hook'
import { useCompaniesAuditsQuery } from '@shared/hooks/use-companies-audits-query.hook'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import { usePersistedTabChange } from '@shared/hooks/use-persisted-tab-change'
import { usePrevious } from '@shared/hooks/use-previous.hook'
import { useRequirementsData } from '@shared/hooks/use-requirements-data.hook'
import { useRouter } from '@shared/hooks/use-router'
import { useSynchronizedAuditRequirements } from './use-synchronized-audit-requirements.hook'
import { AuditHeader } from '@modules/requirements/audit-header.component'
import { Actions } from '@shared/lib/reducer'
import { useStore } from '@shared/hooks/use-store.hook'

export function RequirementsPage() {
    const offlineContent = useOfflineContent()
    const classes = useStyles()
    const { is } = useACL()
    const isClientOrClientEditor = is([Roles.Client, Roles.ClientEditor])

    const { dispatch } = useStore()
    const { isOffline } = useNavigatorOnline()
    const previousIsOffline = usePrevious(isOffline)

    const { query, history } = useRouter<{
        auditID: string
        companyID: number
    }>()
    const { companyID, auditID } = query

    const isPreviewMode =
        auditID === PREVIEW_AUDIT_ID && !isClientOrClientEditor
    const { currentTab, onTabChange } = usePersistedTabChange(
        `currentTab.${companyID}.${auditID}`
    )

    /**
     * If it's an admin/auditor in preview mode ("just watching") or client, we want to just show
     * last closed audit (as that kind of audits we are showing in preview mode only), otherwise we can just ask for particular audit.
     */
    const auditEndpoint =
        isPreviewMode || isClientOrClientEditor
            ? `${companyID}/${PREVIEW_AUDIT_ID}`
            : auditID
    const {
        audit: localAudit,
        isAuditLoading,
        refetchAudit,
    } = useAuditQuery(auditEndpoint)
    const offlineAudit = offlineContent.get<Audit>(OfflineEntityType.Audit)
    const audit = offlineAudit ?? localAudit

    const { companiesWithAudits, areCompaniesLoading } =
        useCompaniesAuditsQuery()
    const company = (companiesWithAudits ?? []).find(
        (company) => company.companyID === +companyID
    )

    /**
     * Audit requirements synchronization
     */
    const {
        synchronizedAuditRequirements,
        refetchRequirements,
        areSynchronizedRequirementsRefetching,
    } = useSynchronizedAuditRequirements(auditID)

    const onSyncClick = useCallback(() => {
        refetchRequirements()
    }, [refetchRequirements])

    const { isRequirementsDataFetching, groupsPerScopeEntries } =
        useRequirementsData<AuditRequirement>(
            synchronizedAuditRequirements ?? audit?.auditRequirements ?? []
        )

    const currentScopeID =
        groupsPerScopeEntries[+currentTab]?.[1]?.[0]?.scope.scopeID

    /**
     * Refetch audit and it's requirements when application went from offline to online state,
     * give 3 secs for establishing network connection
     */
    useEffect(() => {
        let refetchTimeout: any

        if (previousIsOffline && !isOffline) {
            refetchTimeout = setTimeout(() => refetchAudit, 3000)
        }

        return () => clearTimeout(refetchTimeout)
    }, [isOffline, previousIsOffline, refetchAudit])

    /**
     * Store last active audit to redirect to it in offline
     */
    useEffect(() => {
        localStorage.setItem(
            'last-active-audit',
            JSON.stringify({ companyID, auditID })
        )
    }, [auditID, companyID])

    /**
     * Return home if trying to open completed audit in non-preview mode
     */
    useEffect(() => {
        if (audit?.auditCompleted && auditID !== PREVIEW_AUDIT_ID) {
            history.push('/')
        }
    }, [history, audit, auditID])

    const isDataLoading =
        areSynchronizedRequirementsRefetching ||
        isRequirementsDataFetching ||
        areCompaniesLoading ||
        isAuditLoading

    const setGroupsAndRequirementsNumbers = (
        groups: GroupWithScopeAndRequirements<RequirementType>[]
    ) => {
        groups.map((group, groupIndex) => {
            group.no = groupIndex + 1
            group.requirements.map(
                (requirement, requirementIndex) =>
                    (requirement.no = group.no + '.' + (requirementIndex + 1))
            )
            return group
        })
        return groups
    }

    const draftsExists = groupsPerScopeEntries
        .map(([name, elements]) => elements.map((item) => item.requirements))
        .flat(2)
        .find((requirement) => requirement.isDraft)

    return !isDataLoading ? (
        <>
            <AuditCacheWarmup companyID={companyID} />
            <AuditHeader
                audit={audit}
                company={company}
                isPreviewMode={isPreviewMode}
                handleSync={onSyncClick}
                isSyncLoading={areSynchronizedRequirementsRefetching}
                draftsExists={!!draftsExists}
                currentScopeID={currentScopeID}
                currentTabSelected={groupsPerScopeEntries[+currentTab]?.[0]}
                isLastAudit={auditID === PREVIEW_AUDIT_ID}
            />
            <TabContext value={currentTab}>
                <Tabs
                    value={currentTab}
                    onChange={(...arg) => {
                        onTabChange(...arg)
                        dispatch({ type: Actions.CLEAR_EXPANDED_VALUES })
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {groupsPerScopeEntries.map(([scopeName], index) => (
                        <Tab
                            key={scopeName}
                            label={scopeName}
                            value={index.toString()}
                            className={classes.tab}
                        />
                    ))}
                </Tabs>
                {groupsPerScopeEntries.map(
                    ([scopeName, scopeRequirementsGroups], index) => (
                        <TabPanel
                            key={scopeName}
                            value={index.toString()}
                            style={{ padding: 0 }}
                        >
                            <RequirementsTable
                                auditID={parseInt(auditID)}
                                enableColumnStatus
                                enableColumnDontShow={
                                    auditID !== PREVIEW_AUDIT_ID
                                }
                                enableGroupRowNo
                                enableColumnNo
                                enableGroupRowVerifiedCount
                                enableDontShow={!isPreviewMode}
                                isLoading={isDataLoading}
                                isPreviewMode={isPreviewMode}
                                initiallyCollapsedWithButtons={true}
                                requirementsGroups={setGroupsAndRequirementsNumbers(
                                    scopeRequirementsGroups
                                )}
                                currentScopeID={currentScopeID}
                                trackBy={`requirements:audit.${companyID}.${auditID}.${scopeName}`}
                                actions={[
                                    {
                                        icon: originalIcons[
                                            isPreviewMode || is([Roles.Client])
                                                ? 'Visibility'
                                                : 'Create'
                                        ],
                                        tooltip:
                                            isPreviewMode || is([Roles.Client])
                                                ? 'Podgląd'
                                                : 'Edytuj',
                                        onClick: (
                                            event: React.MouseEvent<HTMLElement>,
                                            requirement
                                        ) => {
                                            history.push(
                                                `/audit/${companyID}/${auditID}/${
                                                    (
                                                        requirement as AuditRequirement
                                                    ).auditRequirementID
                                                }`,
                                                {
                                                    requirement,
                                                    audit,
                                                    isPreviewMode:
                                                        isPreviewMode ||
                                                        is([Roles.Client]),
                                                }
                                            )
                                        },
                                    },
                                ]}
                            />
                        </TabPanel>
                    )
                )}
            </TabContext>
        </>
    ) : (
        <BackdropProgress />
    )
}
export const useStyles = makeStyles((theme) => {
    return {
        tab: {
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }
})
