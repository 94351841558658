import React from 'react'
import { map } from 'lodash'
import { MTableActions } from 'material-table'
import { User, AdminResourceType } from '@shared/types'

import { Resource } from './admin-generic.types'
import { AdminGenericResetPassword } from './admin-generic-reset-password.component'
/**
 * @todo make `Crud` a const and use it also in admin-generic.utils
 */

export const RESOURCES: Resource[] = [
    {
        id: AdminResourceType.USERS,
        singular: 'user',
        dto: 'User',
        endpoint: 'users',
        table: {
            company: {
                field: 'company.name',
                title: 'Firma',
            },
            userRoles: {
                field: 'userRole',
                title: 'Rola',
                defaultGroupOrder: 0,
            },
        },
        components: {
            Actions: (props: { data: User }) => (
                <>
                    {props.data ? (
                        <AdminGenericResetPassword email={props?.data?.email} />
                    ) : null}
                    <MTableActions {...props} />
                </>
            ),
        },
        parseData: (data: User[]) =>
            data.map((user) => ({
                ...user,
                userRole: user.userRoles
                    ?.map(({ roleName }) => roleName)
                    .join(', '),
            })),
    },
    /**
     * @todo CRUD is missing
     */
    {
        id: AdminResourceType.COMPANIES,
        singular: 'company',
        dto: 'Company',
        endpoint: 'companies',
    },
    {
        id: AdminResourceType.ROLES,
        singular: 'role',
        dto: 'Role',
        endpoint: 'roles',
    },
    {
        id: AdminResourceType.SCOPES,
        singular: 'scope',
        dto: 'ScopeCrud',
        endpoint: 'scopes/crud',
        table: {
            order: {
                field: 'order',
                title: 'Pozycja',
                width: 150,
            },
            name: {
                field: 'name',
                title: 'Nazwa Zakresu',
                width: 400,
            },
        },
    },
    {
        id: AdminResourceType.GROUPS,
        singular: 'group',
        dto: 'GroupCrud',
        endpoint: 'groups/crud',
        table: {
            order: {
                field: 'order',
                title: 'Pozycja',
                width: 150,
            },
            scope: {
                field: 'scope.name',
                title: 'Zakresy',
                width: 400,
            },
        },
    },
    {
        id: AdminResourceType.STATUSES,
        singular: 'status',
        dto: 'StatusBase',
        endpoint: 'statuses',
    },
    {
        id: AdminResourceType.TEMPLATES,
        singular: 'template',
        dto: 'DescriptionTemplateCrud',
        endpoint: 'templates/crud',
    },
    {
        id: AdminResourceType.REQUIREMENTS,
        singular: 'requirement',
        dto: 'RequirementBaseCrud',
        endpoint: 'requirementbase/crud',
    },
    {
        id: AdminResourceType.LEGAL_BASIS,
        singular: 'legalbasis',
        dto: 'LegalBasisCrud',
        endpoint: 'legalbasis/crud',
    },
    {
        id: AdminResourceType.LEGAL_BASIS_TYPE,
        singular: 'legalbasistype',
        dto: 'LegalBasisType',
        endpoint: 'legalbasis/types',
        lookup: true,
    },
    {
        id: AdminResourceType.SUMMARY_TEMPLATES,
        singular: 'summaryTemplate',
        dto: 'SummaryTemplateCrud',
        endpoint: 'templates/crud',
        lookup: true,
    },
]

export const ALLOWED_RESOURCES = map(RESOURCES, 'id')
