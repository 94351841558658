import { useQuery } from 'react-query'

import { Location, LocationAudits, Roles } from '@shared/types'
import { apiJSON } from '@shared/utils/api/api.util'

import { useACL } from './use-acl.hook'

/**
 * This is only exception to put 2 hooks in one file, it's used only for proper cache cleanup
 * in requirements.page and we need endpoint for that
 */
export function useCompaniesAuditsQueryEndpoint() {
  const { is } = useACL()

  return is([Roles.Client, Roles.ClientEditor]) ? 'companies/own' : 'companies/audits'
}

export function useCompaniesAuditsQuery() {
  const endpoint = useCompaniesAuditsQueryEndpoint()

  const { data: companiesWithAudits, isLoading: areCompaniesLoading } = useQuery<(Location & LocationAudits)[]>(endpoint, () =>
    apiJSON(endpoint)
  )

  return { companiesWithAudits, areCompaniesLoading }
}
