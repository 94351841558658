import { filter, groupBy, has, sortBy } from 'lodash'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { apiJSON } from '@shared/utils/api/api.util'
import { RequirementDefaultGroups, Scope } from '@shared/types'
import { useScopesQuery } from './use-scopes-query.hook'
import { NO_REFETCH_OPTIONS } from '@shared/utils/api/api.consts'

export type GroupWithScopeAndRequirements<Requirement> = {
    scope: Scope
    groupID: number
    order: number
    no: number
    name: string
    requirements: (Requirement & { no: string })[]
}

export function useRequirementsData<Requirement>(
    requirements: Requirement[] = [],
    noCache?: boolean
) {
    type RequirementsByGroup = {
        [key: number]: Requirement[]
    }

    const { scopes } = useScopesQuery(noCache)

    const {
        data: requirementDefaultGroups,
        isLoading: areRequirementsDefaultGroupsLoading,
    } = useQuery<RequirementDefaultGroups>(`groups`, () => apiJSON(`groups`), {
        ...NO_REFETCH_OPTIONS,
        ...(noCache ? { cacheTime: 0 } : {}),
    })

    const isRequirementsDataFetching = areRequirementsDefaultGroupsLoading

    const requirementsPerGroups = useMemo(() => {
        if (requirements && requirementDefaultGroups && scopes) {
            const requirementsByGroupID = groupBy<Requirement>(
                requirements ?? [],
                'groupID'
            ) as RequirementsByGroup
            const requirementsByGroupIDEntries = Object.entries(
                requirementsByGroupID
            )

            const decoratedRequirementsByGroup =
                requirementsByGroupIDEntries.map(
                    ([groupID, groupRequirements]) => {
                        const parsedGroupID = parseInt(groupID, 10)
                        const group = requirementDefaultGroups.find(
                            (requirementDefaultGroups) =>
                                requirementDefaultGroups.groupID ===
                                parsedGroupID
                        )

                        const scope = scopes.find(
                            (scope) => scope.scopeID === group?.scope.scopeID
                        )!

                        return {
                            scope,
                            groupID: parsedGroupID,
                            /**
                             * To avoid dancing with types, just set `0` here and adjust later after sorting by order
                             */
                            no: 0,
                            order: group?.order,
                            name:
                                group?.name ||
                                `Nieznane z nieznanej grupy o ID ${groupID}`,
                            requirements: sortBy(
                                groupRequirements.map((requirement) => ({
                                    ...requirement,
                                    group,
                                    scope,
                                    /**
                                     * To avoid dancing with types, just set `0` here and adjust later after sorting by order
                                     */
                                    no: '0.0',
                                })),
                                'order'
                            ),
                        } as GroupWithScopeAndRequirements<Requirement>
                    }
                )

            return sortBy(decoratedRequirementsByGroup, 'order').map(
                (groupWithScope, groupIndex) => ({
                    ...groupWithScope,
                    no: groupIndex + 1,
                    requirements: groupWithScope.requirements.map(
                        (requirement, requirementIndex) => ({
                            ...requirement,
                            no: `${groupIndex + 1}.${requirementIndex + 1}`,
                        })
                    ),
                })
            )
        }

        return []
    }, [requirements, requirementDefaultGroups, scopes])

    return useMemo(() => {
        const path = 'scope.name'
        const groupsPerScope = groupBy(
            filter(requirementsPerGroups, (object) => has(object, path)),
            path
        )

        const scopesList = Object.keys(groupsPerScope)
            .map((scope) => ({
                scopeName: scope,
                // @ts-ignore
                order: scopes?.find((el) => el.name === scope)?.order,
            }))
            .sort((a, b) => a.order - b.order)
            .map((scope) => scope.scopeName)
        const groupsPerScopeEntries = Object.entries(groupsPerScope)
        groupsPerScopeEntries.sort(
            (a, b) => scopesList.indexOf(a[0]) - scopesList.indexOf(b[0])
        )

        return {
            isRequirementsDataFetching,
            groupsPerScopeEntries,
            scopesList,
        }
    }, [scopes, isRequirementsDataFetching, requirementsPerGroups])
}
