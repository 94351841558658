import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

type Props = {
  to: LinkProps['to']
  children: React.ReactChild | React.ReactChildren
}

export function TableActionLink(props: Props) {
  const classes = useStyles()

  return (
    <Typography variant='body2' className={classes.actionText}>
      <Link className={classes.actionLink} to={props.to}>
        {props.children}
      </Link>
    </Typography>
  )
}

export const useStyles = makeStyles(theme => {
  return {
    actionText: {
      display: 'inline-block'
    },
    actionLink: {
      textDecoration: 'underline',
      textAlign: 'right',
      cursor: 'pointer',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(2)
    }
  }
})
