import React from 'react'

type Props = {
  when?: any
  children: React.ReactElement
}

export function ShowConditionally(props: Props) {
  return Boolean(props.when) ? props.children : null
}
