import React, { useMemo } from 'react'
import { Route } from 'react-router-dom'
import { useQuery } from 'react-query'

import { isArray } from 'lodash'

import { Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'

import { usePersistedTabChange } from '@shared/hooks/use-persisted-tab-change'
import { useRequirementsData } from '@shared/hooks/use-requirements-data.hook'
import { apiJSON } from '@shared/utils/api/api.util'
import { originalIcons } from '@shared/components/icons/icons'
import { ConfirmationDialog } from '@shared/components/confirmation.dialog.component'
import { useRouter } from '@shared/hooks/use-router'
import { BackdropProgress } from '@shared/components/backdrop-progress.component'
import { BaseRequirement } from '@shared/types'
import { useResourceRemove } from '@shared/hooks/use-resource-remove.hook'

import { AdminRequirementCrudDialog } from './admin-requirement-crud.dialog.component'
import { RequirementsTable } from '@modules/requirement/requirement-table/requirements-table.component'

export function AdminRequirementsCrudPage() {
    const { push } = useRouter()

    const {
        isRemovalDialogOpened,
        isResourceRemoving,
        setRemovalDialogOpened,
        setRemovedResourceID,
        onResourceRemove,
        /**
         * @todo retrieve from config
         */
    } = useResourceRemove('requirementbase/crud')

    /**
     * @todo retrieve from admin-generic.consts
     */
    const { data: baseRequirements, isLoading: areRequirementsFetching } =
        useQuery<BaseRequirement[]>('requirementbase/crud', () =>
            apiJSON('requirementbase/crud')
        )

    const requirements = useMemo(
        () =>
            (baseRequirements ?? []).map((requirement) => ({
                ...requirement,
                groupID: requirement.group.groupID,
            })),
        [baseRequirements]
    )

    const { isRequirementsDataFetching, groupsPerScopeEntries, scopesList } =
        useRequirementsData<BaseRequirement>(requirements, true)

    const { currentTab, onTabChange } = usePersistedTabChange(
        'admin.requirements.currentTab'
    )
    const isDataLoading = isRequirementsDataFetching || areRequirementsFetching

    if (isDataLoading) return <BackdropProgress />

    return (
        <>
            <TabContext value={currentTab}>
                <Tabs
                    value={currentTab}
                    onChange={onTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {scopesList.map((scopeName, index) => (
                        <Tab
                            key={scopeName}
                            label={scopeName}
                            value={index.toString()}
                        />
                    ))}
                </Tabs>
                {groupsPerScopeEntries.map(
                    ([scopeName, scopeRequirementsGroups], index) => (
                        <TabPanel
                            key={scopeName}
                            value={index.toString()}
                            style={{ padding: 0, paddingTop: 24 }}
                        >
                            <RequirementsTable
                                enableGroupRowOrder
                                enableColumnOrder
                                enableColumnOffset
                                enableShowNotActive
                                isLoading={isDataLoading}
                                requirementsGroups={scopeRequirementsGroups}
                                initialScrollPosition="top"
                                initiallyCollapsedWithButtons={false}
                                actions={[
                                    {
                                        icon: originalIcons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            push('/admin/requirements/add')
                                        },
                                    },
                                    {
                                        icon: originalIcons.Palette,
                                        tooltip: 'Szablony',
                                        onClick: (
                                            event: React.MouseEvent<HTMLElement>,
                                            resourceEntity
                                        ) => {
                                            if (!isArray(resourceEntity)) {
                                                push(
                                                    `/admin/templates?filterBy=requirementBase--requirementBaseID&filterValue=${resourceEntity.requirementBaseID}`
                                                )
                                            }
                                        },
                                    },
                                    {
                                        icon: originalIcons.Create,
                                        tooltip: 'Edytuj',
                                        onClick: (
                                            event: React.MouseEvent<HTMLElement>,
                                            resourceEntity
                                        ) => {
                                            if (!isArray(resourceEntity)) {
                                                push(
                                                    `/admin/requirements/edit/${resourceEntity.requirementBaseID}`,
                                                    {
                                                        resourceEntity,
                                                    }
                                                )
                                            }
                                        },
                                    },
                                    {
                                        icon: originalIcons.Delete,
                                        tooltip: 'Usuń',
                                        onClick: (
                                            event: React.MouseEvent<HTMLElement>,
                                            resourceEntity
                                        ) => {
                                            if (!isArray(resourceEntity)) {
                                                setRemovedResourceID(
                                                    resourceEntity.requirementBaseID.toString()
                                                )
                                                setRemovalDialogOpened(true)
                                            }
                                        },
                                    },
                                ]}
                            />
                        </TabPanel>
                    )
                )}
            </TabContext>
            <Route
                path={[
                    '/admin/requirements/add',
                    '/admin/requirements/edit/:resourceID',
                ]}
            >
                <AdminRequirementCrudDialog />
            </Route>
            <ConfirmationDialog
                submitButtonProps={{
                    children: 'Usuń',
                    isLoading: isResourceRemoving,
                }}
                title="Potwierdzenie usunięcia"
                onClose={onResourceRemove}
                open={isRemovalDialogOpened}
            >
                Czy napewno chcesz usunąć dany wiersz?
            </ConfirmationDialog>
        </>
    )
}
