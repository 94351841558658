import React from 'react'
import { OutlinedTextFieldProps, TextField as MaterialTextField } from '@material-ui/core'

type Props = Omit<OutlinedTextFieldProps, 'variant'>

export function TextField(props: Props) {
  return (
    <MaterialTextField
      fullWidth
      margin='normal'
      InputLabelProps={{
        shrink: true
      }}
      variant='outlined'
      {...props}
    />
  )
}
