import { Status, StatusTypes } from '@shared/types'
import { statusColors } from '@shared/utils/status-colors'

export const getStatusColor = (statuses: Status[], statusID?: number) =>
  !statusID
    ? StatusTypes.NotAssessed
    : statusColors[
        (statuses.find(status => status.statusID === statusID)?.label ?? '').replace(
          StatusTypes.NotAssessed,
          StatusTypes.NotAssessedNegative
        ) as unknown as StatusTypes
      ] ?? statusColors[StatusTypes.NotAssessedNegative]

export const getStatusColorStyles = (color: string) => ({
  backgroundColor: color,
  ...(color === statusColors[StatusTypes.NotAssessedNegative] ? { border: '1px solid black' } : {})
})
