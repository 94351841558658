import React from 'react'

import { Alert, AlertTitle } from '@material-ui/lab'
import { NormalizedErrors } from '@shared/utils/api/api.util'
import { makeStyles } from '@material-ui/core'

type Props = {
  errors: NormalizedErrors | null
  onClose?: () => void
}

export function ErrorsAlert(props: Props) {
  const classes = useStyles()

  return props.errors ? (
    <Alert severity='error' onClose={props.onClose} className={classes.alert}>
      <AlertTitle>Błąd</AlertTitle>
      <ul>
        {props.errors.map(error => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </Alert>
  ) : null
}

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(2)
  }
}))
