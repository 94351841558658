import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { originalIcons } from '@shared/components/icons/icons'
import { apiJSON } from '@shared/utils/api/api.util'
import { User } from '@shared/types'

type Props = {
    email: User['email']
}

export const AdminGenericResetPassword = (props: Props) => {
    const classes = useStyles()
    const { email } = props

    const {
        mutate: resetPassword,
        isLoading: isPasswordResetInProgress,
        isError: resetPasswordFailed,
        isSuccess: resetPasswordSuccess,
        error: resetPasswordError,
    } = useMutation((email: string) =>
        apiJSON('users/resetpassword', { email }, { method: 'PUT' })
    )

    const onResetButtonClick = useCallback(() => {
        resetPassword(email)
    }, [resetPassword, email])

    const tooltipMessage = resetPasswordSuccess
        ? 'Wysłano link do resetowania hasła'
        : (resetPasswordError as { message: string } | null)?.message ??
          'Wyślij link resetowania hasła'

    const buttonClassname = resetPasswordSuccess
        ? classes.resetPasswordSuccess
        : resetPasswordFailed
        ? classes.resetPasswordFailed
        : ''

    return !isPasswordResetInProgress ? (
        <Tooltip title={tooltipMessage}>
            <IconButton
                color="inherit"
                className={buttonClassname}
                disabled={isPasswordResetInProgress}
                onClick={onResetButtonClick}
            >
                <originalIcons.NewReleases />
            </IconButton>
        </Tooltip>
    ) : (
        <CircularProgress size={24} className={classes.progress} />
    )
}

export const useStyles = makeStyles((theme) => ({
    progress: {
        top: 12,
        position: 'relative',
    },
    resetPasswordFailed: {
        color: theme.palette.error.main,
    },
    resetPasswordSuccess: {
        color: theme.palette.primary.main,
    },
}))
