import React from 'react'
import { Link, Route } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import {
    Divider,
    CssBaseline,
    AppBar,
    Toolbar,
    Typography,
    Container,
    Grid,
    Paper,
} from '@material-ui/core'

import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'

import { useRouter } from '@shared/hooks/use-router'
import { ACL } from '@shared/components/acl.component'
import { useACL } from '@shared/hooks/use-acl.hook'
import { UnstyledAnchor } from '@shared/components/unstyled-link.component'
import { Roles } from '@shared/types'

import { useAuth } from '@modules/auth/use-auth.hook'

import { LayoutAdminHeader } from './layout-admin-header.component'

import { version } from '../../../package.json'

type Props = {
    showIndicator: boolean
    children: React.ReactNode
}

export function Layout(props: Props) {
    const { is } = useACL()
    const classes = useStyles()
    const { logout, authenticatedUser } = useAuth()
    const { status, isOffline } = useNavigatorOnline()
    const { location, query } = useRouter<{ companyID?: string }>()

    const isClient = is([Roles.Client, Roles.ClientEditor])

    const topMenuItemsClassNames = [classes.menuLink, classes.topMenuLink].join(
        ' '
    )

    const adminLinkClassName = [
        topMenuItemsClassNames,
        isOffline ? classes.adminLinkDisabled : false,
    ]
        .filter(Boolean)
        .join(' ')

    const logoutClassNames = [
        topMenuItemsClassNames,
        classes.logoutLink,
        isClient ? classes.logoutLinkClient : false,
    ]
        .filter(Boolean)
        .join(' ')

    return (
        <div className={classes.root}>
            <CssBaseline />
            {props.showIndicator && (
                <Typography className={classes.testEnv}>
                    ŚRODOWISKO TESTOWE
                </Typography>
            )}
            <AppBar position="static">
                <Toolbar>
                    <div className={classes.toolbarSectionLeft}>
                        <Link to="/" className={classes.menuLink}>
                            <img
                                src="/logo/logo-banner.png"
                                className={classes.logoImage}
                                alt="logo"
                            />
                            {/* <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                Audyty
              </Typography> */}
                            <Divider
                                className={classes.topMenuDivider}
                                orientation="vertical"
                                flexItem
                            />
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.status}
                            >
                                {status}
                            </Typography>
                        </Link>
                    </div>
                    <div className={classes.toolbarSectionRight}>
                        {/**
                         * @todo Duplication, move to `HeaderLink`
                         */}
                        {query.companyID ? (
                            <ACL allowedRoles={[Roles.Client]}>
                                <Link
                                    to={`/documents/${query.companyID}`}
                                    className={[
                                        classes.menuLink,
                                        classes.topMenuLink,
                                    ].join(' ')}
                                >
                                    <Typography
                                        variant="caption"
                                        className={[
                                            classes.title,
                                            location.pathname.includes(
                                                'documents'
                                            )
                                                ? classes.topMenuLinkActive
                                                : false,
                                        ]
                                            .filter(Boolean)
                                            .join(' ')}
                                    >
                                        Historia
                                    </Typography>
                                </Link>
                            </ACL>
                        ) : null}
                        <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                            <Link to="/admin" className={adminLinkClassName}>
                                <Typography
                                    variant="caption"
                                    className={[
                                        classes.title,
                                        location.pathname.includes('/admin')
                                            ? classes.topMenuLinkActive
                                            : false,
                                    ]
                                        .filter(Boolean)
                                        .join(' ')}
                                >
                                    Administracja
                                </Typography>
                            </Link>
                        </ACL>
                        <Typography
                            className={logoutClassNames}
                            variant="caption"
                            onClick={logout}
                        >
                            Wyloguj {authenticatedUser?.name}{' '}
                            {authenticatedUser?.surname}
                        </Typography>
                    </div>
                </Toolbar>
                <Route path="/admin">
                    <LayoutAdminHeader />
                </Route>
            </AppBar>
            <main className={classes.content}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                {props.children}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
            <footer className={classes.footer}>
                <div className={classes.footerLinks}>
                    <ul>
                        <li>
                            <UnstyledAnchor
                                target="_blank"
                                href="http://www.ecoms.pl/prawa-autorskie/"
                            >
                                Prawa autorskie
                            </UnstyledAnchor>
                        </li>
                        <li>
                            <UnstyledAnchor
                                target="_blank"
                                href="http://www.ecoms.pl/polityka-prywatnosci/"
                            >
                                Polityka prywatności
                            </UnstyledAnchor>
                        </li>
                        <li>
                            <UnstyledAnchor
                                target="_blank"
                                href="http://www.ecoms.pl/kontakt/"
                            >
                                Kontakt
                            </UnstyledAnchor>
                        </li>
                        <li>Wersja: {version}</li>
                    </ul>
                    <div className={classes.footerCopyrights}>
                        EcoMS Consulting Sp. z o.o. Copyright ©. All rights
                        reserved.
                    </div>
                </div>
            </footer>
        </div>
    )
}

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.grey['200'],
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarSectionLeft: {
        flex: 1,
    },
    toolbarSectionRight: {
        justifyContent: 'flex-end',
        flex: 1,
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        marginRight: theme.spacing(1),
        textDecoration: 'none',
        lineHeight: `${theme.spacing(4.5)}px`,
    },
    status: {
        marginLeft: theme.spacing(1),
        lineHeight: `${theme.spacing(4.5)}px`,
    },
    appBarSpacer: theme.mixins.toolbar,
    appDoubleBarSpacer: Object.entries(theme.mixins.toolbar).reduce(
        (memo, [property, value]: [string, unknown]) => {
            const propertyValue = value as { [key: string]: any }

            memo[property] = propertyValue.minHeight
                ? { ...propertyValue, minHeight: propertyValue.minHeight * 2 }
                : propertyValue

            return memo
        },
        {} as CSSProperties
    ),
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
        },
    },
    container: {
        marginTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    logoImage: {
        position: 'relative',
        top: -2,
        height: '40px',
        marginRight: '10px',
    },
    menuLink: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
    },
    topMenuDivider: {
        opacity: 0.7,
        height: 25,
        top: 8,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
    },
    topMenuLink: {
        marginRight: 10,
        marginLeft: 10,
    },
    topMenuLinkActive: {
        fontWeight: theme.typography.fontWeightBold,
    },
    adminLinkDisabled: {
        color: theme.palette.grey[300],
        pointerEvents: 'none',
    },
    logoutLink: {
        paddingLeft: 15,
        alignItems: 'center',
        cursor: 'pointer',
        borderLeft: '1px solid rgba(255,255,255,0.7)',
    },
    logoutLinkClient: {
        border: 'none',
    },
    footer: {
        '& > div': {
            display: 'flex',
            alignContent: 'space-between',
        },
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        backgroundColor: theme.palette.grey['900'],
        color: theme.palette.grey['300'],
    },
    footerLinks: {
        '& ul': {
            display: 'flex',
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
        '& li': {
            marginRight: theme.spacing(1),
        },
    },
    footerCopyrights: {
        flex: 1,
        textAlign: 'right',
    },
    testEnv: {
        padding: 10,
        textAlign: 'center',
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 'bold',
    },
}))
