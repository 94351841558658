import React, { ReactNode } from 'react'
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { StatusDetailsTemplates } from '@modules/requirement/requirement-form/status-details-templates.component'
import { makeStyles } from '@material-ui/core/styles'
import { useFormContext } from 'react-hook-form'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import ListIcon from '@material-ui/icons/List'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import { Template } from '@shared/types'

interface Props {
    showTemplates: boolean
    requirementBaseID: number
    onSetTemplate: (templateText: any) => void
    statusID: number
    onShowTemplatesClick: () => void
    saveDisabled: boolean
    children?: ReactNode
    controlName: string
    selectedTemplate?: Template
    setSelectedTemplate: (value: Template) => void
    isSummary?: boolean
}

export const FormTemplates = ({
    showTemplates,
    requirementBaseID,
    onSetTemplate,
    statusID,
    onShowTemplatesClick,
    saveDisabled,
    children,
    controlName,
    selectedTemplate,
    setSelectedTemplate,
    isSummary = false,
}: Props) => {
    const classes = useStyles()
    const { isOnline } = useNavigatorOnline()
    const { register } = useFormContext()

    return (
        <>
            <ShowConditionally when={isOnline}>
                <>
                    <Grid>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...register(controlName)}
                                    color="primary"
                                />
                            }
                            label="Zapisz jako szablon"
                            disabled={saveDisabled}
                        />
                    </Grid>
                    {showTemplates && (
                        <StatusDetailsTemplates
                            statusID={statusID}
                            requirementBaseID={requirementBaseID}
                            onSetTemplate={onSetTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            isSummary={isSummary}
                            selectedTemplate={selectedTemplate}
                            onSetSummaryTemplate={onSetTemplate}
                        />
                    )}
                </>
            </ShowConditionally>
            <Grid container>
                <Grid item xs={8} className={classes.actionButtonsContainer}>
                    <ShowConditionally when={isOnline}>
                        <>
                            <Button
                                onClick={onShowTemplatesClick}
                                variant="text"
                                color="primary"
                                startIcon={<ListIcon />}
                                disabled={isSummary && !selectedTemplate}
                            >
                                {showTemplates
                                    ? 'Zamknij szablony'
                                    : 'Szablony'}
                            </Button>
                            {children}
                        </>
                    </ShowConditionally>
                </Grid>
            </Grid>
        </>
    )
}

export const useStyles = makeStyles({
    actionButtonsContainer: {
        alignItems: 'center',
        display: 'flex',
    },
})
