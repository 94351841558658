import React from 'react'
import { MTableToolbar } from 'material-table'
import { makeStyles } from '@material-ui/core/styles'

export function TableToolbar(toolbarProps: {
    children: React.ReactChild
    additionalComponent?: React.ReactChild
}) {
    const classes = useStyles()

    return (
        <div className={classes.toolbar}>
            <MTableToolbar {...toolbarProps} />
            {toolbarProps.additionalComponent
                ? toolbarProps.additionalComponent
                : null}
            {toolbarProps.children}
        </div>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        toolbar: {
            marginLeft: -theme.spacing(3),
        },
    }
})
