import React from 'react'
import { SchemaPayload } from '@shared/hooks/use-schemas-query.hook'
import { JSONSchema7 } from 'json-schema'
import { useThemeStyles } from '@shared/theme/use-theme-styles'
import { useRouter } from '@shared/hooks/use-router'
import { useHandleResourceState } from '@shared/hooks/use-handle-resource-state.hook'
import { Redirect } from 'react-router-dom'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import { ErrorsAlert } from '@shared/components/errors-alert.component'
import {
    getFinalResourceConfig,
    getResourceRefSchema,
    getResourceSchemaKeyFromRef,
} from '@modules/admin-generic/admin-generic.utils'
import { isEmpty } from 'lodash'
import { AdminGenericCrudSelect } from '@modules/admin-generic/admin-generic-crud-select.component'
import { ProgressButton } from '@shared/components/progress-button.component'
import { FormProvider } from 'react-hook-form'
import { AdminResourceType } from '@shared/types'
import { LegalBasisNumberField } from '@modules/legal-basis/legal-basis-number.component'

type Props = {
    resourceName: string
    resourceEndpoint: string
    resourceNameSingular: string
    schema: SchemaPayload
    properties: JSONSchema7
    requiredProperties: string[]
}

type RouteQuery = {
    resourceName?: string
    resourceID?: string
}

export const AdminGenericCrudDialog = (props: Props) => {
    const classes = useThemeStyles()
    const { history, query } = useRouter<RouteQuery>()
    const isResourceEdit = Boolean(query?.resourceID)

    const {
        formMethods,
        onAddResource,
        mutation: {
            isResourceCreating,
            isResourceCreationSucceed,
            createResourceErrors,
            resetResourceCreationState,
        },
    } = useHandleResourceState({
        resourceEndpoint: props.resourceEndpoint,
        resourceID: query?.resourceID,
    })

    if (isResourceCreationSucceed) {
        return <Redirect to={`/admin/${props.resourceName}`} />
    }

    return (
        <Dialog fullWidth open={true} maxWidth="md">
            <form noValidate>
                <FormProvider {...formMethods}>
                    <DialogTitle className={classes.dialogTitle}>
                        {isResourceEdit ? 'Edytuj' : 'Utwórz nowy'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ErrorsAlert
                            errors={createResourceErrors}
                            onClose={resetResourceCreationState}
                        />
                        {Object.entries(props.properties ?? {}).map(
                            ([property, propertySchema]: [
                                string,
                                JSONSchema7
                            ]) => {
                                if (
                                    props.resourceName ===
                                    AdminResourceType.LEGAL_BASIS
                                ) {
                                    if (property === 'documentName') {
                                        return (
                                            <LegalBasisNumberField
                                                formMethods={formMethods}
                                                property={property}
                                                propertySchema={propertySchema}
                                            />
                                        )
                                    }
                                }

                                const isArraySchema =
                                    propertySchema.type === 'array'

                                if (
                                    propertySchema.$ref ||
                                    (isArraySchema &&
                                        (propertySchema.items as JSONSchema7)
                                            ?.$ref)
                                ) {
                                    const $ref = isArraySchema
                                        ? (propertySchema.items as JSONSchema7)
                                              ?.$ref
                                        : propertySchema.$ref
                                    const propertyResourceSchemaKey =
                                        getResourceSchemaKeyFromRef($ref)

                                    /**
                                     * @todo sync with BE if we can align all DTOs for CRUD with "Crud" suffix
                                     */
                                    const $refResourceConfig =
                                        getFinalResourceConfig(
                                            propertyResourceSchemaKey
                                        )
                                    const $refSchema = getResourceRefSchema(
                                        props.schema?.components?.schemas,
                                        $refResourceConfig
                                    )

                                    if (!isEmpty($refResourceConfig)) {
                                        return (
                                            <AdminGenericCrudSelect
                                                key={property}
                                                name={property}
                                                multiple={isArraySchema}
                                                label={
                                                    propertySchema?.description ??
                                                    $refSchema?.description ??
                                                    property
                                                }
                                                resourceConfig={
                                                    $refResourceConfig
                                                }
                                            />
                                        )
                                    } else {
                                        if (
                                            process.env.NODE_ENV ===
                                            'development'
                                        ) {
                                            console.warn(
                                                `No resource configuration found for resource "${$ref}"`
                                            )
                                        }

                                        return null
                                    }
                                } else {
                                    return (
                                        <TextField
                                            {...formMethods.register(property, {
                                                required:
                                                    props.requiredProperties.includes(
                                                        property
                                                    ),
                                            })}
                                            required={props.requiredProperties.includes(
                                                property
                                            )}
                                            key={property}
                                            label={
                                                propertySchema?.description ??
                                                property
                                            }
                                            fullWidth
                                            multiline={
                                                (propertySchema?.maxLength ??
                                                    0) > 50
                                            }
                                            rows={10}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    )
                                }
                            }
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={history.goBack}
                            color="primary"
                            variant="outlined"
                        >
                            Anuluj
                        </Button>
                        <ProgressButton
                            onClick={onAddResource}
                            isLoading={isResourceCreating}
                            color="primary"
                            variant="contained"
                        >
                            Zapisz
                        </ProgressButton>
                    </DialogActions>
                </FormProvider>
            </form>
        </Dialog>
    )
}
