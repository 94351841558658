import { makeStyles } from '@material-ui/core/styles'
import { TABLE_PADDING_COMMON } from '@shared/components/table/table.consts'

export const useThemeStyles = makeStyles((theme) => {
    return {
        detailsContainer: {
            backgroundColor: theme.palette.grey[100],
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            marginBottom: theme.spacing(1.5),
            paddingTop: 0,
        },
        detailsHeader: {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: '100%',
        },
        detailsBreadcrumbsContainer: {
            marginBottom: theme.spacing(2),
        },
        detailsBreadcrumbsItem: {
            color: theme.palette.grey['600'],
            textTransform: 'uppercase',
        },
        autocomplete: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
        },
        tableContainer: {
            '& table tr td > button': {
                padding: TABLE_PADDING_COMMON.padding,
            },
        },
        dialogTitle: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    }
})
