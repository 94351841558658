import { JSONSchema7 } from 'json-schema'
import { filter, find, set } from 'lodash'
import { ALLOWED_RESOURCES, RESOURCES } from './admin-generic.consts'
import { Resource } from './admin-generic.types'
import { AdminResourceType, GenericResourceData } from '@shared/types'
import {
    FieldValues,
    Path,
    RegisterOptions,
    UseFormRegister,
} from 'react-hook-form'

export function registerInputRefFactory<T extends FieldValues>(
    register: UseFormRegister<T>
) {
    return (name: Path<T>, options?: RegisterOptions<T, Path<T>>) => {
        const { ref: inputRef, ...inputProps } = register(name, options)
        return { inputRef, ...inputProps }
    }
}

export const getOmittedFields = (
    resourceNameSingular: string,
    resource: Resource,
    properties: JSONSchema7
) => {
    return [
        getResourceIdentityProperty(resourceNameSingular),
        ...Object.keys(properties).filter((property) =>
            property.endsWith('ID')
        ),
    ]
}

export const getResourceIdentityProperty = (resourceNameSingular: string) =>
    `${resourceNameSingular.replace('Crud', '')}ID`

export const getResourceSchemaKeyFromRef = ($ref: string = '') =>
    $ref.match(/([A-Za-z]+)DTO/)![1]

export const getResourceConfig = (
    propertyResourceValue: string,
    field: keyof Resource = 'id'
) => find<Resource>(RESOURCES, { [field]: propertyResourceValue })

export const getFinalResourceConfig = (propertyResourceSchemaKey: string) => {
    return (
        getResourceConfig(propertyResourceSchemaKey, 'dto') ??
        getResourceConfig(`${propertyResourceSchemaKey}Crud`, 'dto') ??
        ({} as Resource)
    )
}

export const getResourceRefSchema = (
    schemas?: Record<string, JSONSchema7>,
    $refResourceConfig?: Resource
) =>
    schemas && $refResourceConfig
        ? schemas?.[`${$refResourceConfig.dto}DTO`]
        : null

/**
 * @todo replace `--` with const and re-use in admin-requirements.page
 */
export const defaultParseResourceData = (
    resourceData: GenericResourceData = [],
    { filterBy, filterValue }: { filterBy?: string; filterValue?: any }
) =>
    filterBy && filterValue
        ? filter(
              resourceData,
              set(
                  {},
                  filterBy?.replace(/--/g, '.') ?? '',
                  !isNaN(filterValue as number) ? +filterValue : filterValue
              )
          )
        : resourceData

export const RESOURCE_NAME_ROUTE_PARAM = `:resourceName(${ALLOWED_RESOURCES.filter(
    (resourceID) => resourceID !== AdminResourceType.REQUIREMENTS
).join('|')})`
