import React, { useState } from 'react'
import {
    Grid,
    Typography,
    Box,
    Tooltip,
    Button,
    Dialog,
} from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Audit, LocationAudits, Location, Roles } from '@shared/types'
import { TooltipText } from '@modules/requirements/audit-tooltip-text.component'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { ACL } from '@shared/components/acl.component'
import { ProgressButton } from '@shared/components/progress-button.component'
import { useAuditMutation } from '@modules/requirements/use-audit-action.hook'
import { ConfirmationDialog } from '@shared/components/confirmation.dialog.component'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import { UnstyledLink } from '@shared/components/unstyled-link.component'
import { useRouter } from '@shared/hooks/use-router'
import { ProgressDownloadButton } from '@shared/components/ProgressDownloadButton'
import { ClientAuditStats } from '@modules/requirements/client-audit-stats.component'

type Props = {
    audit?: Audit
    company: (Location & LocationAudits) | undefined
    isPreviewMode: boolean
    handleSync: () => void
    isSyncLoading: boolean
    draftsExists: boolean
    currentScopeID: number
    currentTabSelected: string
    isLastAudit?: boolean
}

const SEPARATOR = '•'

const LABELS = {
    AUDIT: 'AUDYT',
    START_DATE: 'Rozpoczęcie',
    END_DATE: 'Zakończenie',
    LEGAL_DATE: 'Stan prawny',
    LOCALIZATION: 'Lokalizacja',
    AUDITORS: 'Audytorzy',
    SYNCHRONIZE: 'Sychronizuj',
    CLOSE_AUDIT: 'Zakończ audyt',
    CHANGE_RANGE: 'Zmień zakres',
    GENERATE_EXCEL: 'Generuj excel',
    HISTORY: 'Historia',
    METRIC: 'Metryka',
    CHART: 'Wykres',
}

export const AuditHeader = (props: Props) => {
    const {
        audit,
        company,
        isPreviewMode,
        handleSync,
        isSyncLoading,
        draftsExists,
        currentScopeID,
        currentTabSelected,
        isLastAudit,
    } = props
    const [areStatsOpen, setAreStatsOpen] = useState(false)
    const { query } = useRouter<{
        auditID: string
        companyID: number
    }>()
    const { companyID, auditID } = query
    const { isOffline } = useNavigatorOnline()
    const classes = useStyles()
    const auditInfo = [
        { label: LABELS.START_DATE, value: audit?.preparationDate || '-' },
        { label: LABELS.END_DATE, value: audit?.reportDate || '-' },
        { label: LABELS.LEGAL_DATE, value: audit?.legalStatusVersion || '-' },
    ]
    const {
        isAuditMutationInProgress: isAuditRemoving,
        isConfirmAuditMutationDialogOpen: isConfirmRemoveAuditDialogOpen,
        onAuditMutationStart: onAuditRemove,
        onAuditActionConfirmation: onAuditRemoveConfirmation,
    } = useAuditMutation(auditID, 'DELETE')
    const {
        isAuditMutationInProgress: isAuditClosing,
        isConfirmAuditMutationDialogOpen: isConfirmCloseAuditDialogOpen,
        onAuditMutationStart: onAuditClose,
        onAuditActionConfirmation: onAuditCloseConfirmation,
    } = useAuditMutation(auditID, 'PUT', { ...audit, auditCompleted: true })

    if (!audit) {
        return null
    }

    return (
        <>
            <Box className={classes.container}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                >
                    <Grid
                        container
                        direction="column"
                        className={classes.detailsSection}
                    >
                        <Grid item>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleWrapper}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.auditTitle}
                                >
                                    {LABELS.AUDIT}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    className={classes.title}
                                >
                                    {company?.name}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    className={classes.number}
                                >
                                    ({audit.auditNumber ?? 'BRAK NUMERU'})
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                <originalIcons.Calendar
                                    className={classes.icon}
                                />
                                <Typography
                                    variant="body2"
                                    className={classes.infoText}
                                >
                                    {auditInfo.map((info, index) => (
                                        <React.Fragment key={index}>
                                            {info.label}: {info.value}
                                            {index < auditInfo.length - 1 && (
                                                <span
                                                    className={
                                                        classes.separator
                                                    }
                                                >
                                                    {SEPARATOR}
                                                </span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                                <TooltipText
                                    icon={
                                        <originalIcons.Pin
                                            className={classes.icon}
                                        />
                                    }
                                    text={LABELS.LOCALIZATION}
                                    tooltipText={audit.localization || '-'}
                                />
                                <TooltipText
                                    icon={
                                        <originalIcons.Group
                                            className={classes.icon}
                                        />
                                    }
                                    text={LABELS.AUDITORS}
                                    tooltipText={audit.auditors || '-'}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <ShowConditionally when={!isPreviewMode}>
                        <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                            <Box
                                display="flex"
                                className={classes.mainButtonsWrapper}
                            >
                                <ACL allowedRoles={[Roles.Admin]}>
                                    <ProgressButton
                                        isLoading={isAuditRemoving}
                                        onClick={onAuditRemove}
                                        variant="contained"
                                        color="secondary"
                                        disabled={isOffline}
                                    >
                                        <originalIcons.Delete />
                                    </ProgressButton>
                                </ACL>
                                <ProgressButton
                                    onClick={handleSync}
                                    isLoading={isSyncLoading}
                                    variant="outlined"
                                    disabled={isOffline}
                                >
                                    {LABELS.SYNCHRONIZE}
                                </ProgressButton>
                                <Tooltip title="Zapisz wymagania, aby zakończyć audyt">
                                    <span>
                                        <ProgressButton
                                            isLoading={isAuditClosing}
                                            onClick={onAuditClose}
                                            color="primary"
                                            variant="contained"
                                            disabled={
                                                isOffline ||
                                                Boolean(draftsExists)
                                            }
                                        >
                                            {LABELS.CLOSE_AUDIT}
                                        </ProgressButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        </ACL>
                    </ShowConditionally>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    className={classes.buttonsWrapper}
                >
                    <ShowConditionally when={!isPreviewMode}>
                        <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                            <Box display="flex" className={classes.buttonsGap}>
                                <Button
                                    variant="outlined"
                                    startIcon={<originalIcons.Tune />}
                                >
                                    <UnstyledLink
                                        to={`/client/${companyID}/${auditID}`}
                                    >
                                        {LABELS.CHANGE_RANGE}
                                    </UnstyledLink>
                                </Button>
                                <ProgressDownloadButton
                                    variant="outlined"
                                    downloadUrl={`${process.env.REACT_APP_HOST}/api/audits/excel/${auditID}`}
                                    disabled={isOffline}
                                    startIcon={<originalIcons.Send />}
                                >
                                    {LABELS.GENERATE_EXCEL}
                                </ProgressDownloadButton>
                                <Button
                                    variant="outlined"
                                    startIcon={<originalIcons.Paper />}
                                >
                                    <UnstyledLink
                                        to={`/audit-report/${companyID}/${auditID}`}
                                    >
                                        {LABELS.METRIC}
                                    </UnstyledLink>
                                </Button>
                            </Box>
                        </ACL>
                    </ShowConditionally>
                    <ShowConditionally when={isLastAudit}>
                        <Button
                            variant="outlined"
                            startIcon={<originalIcons.Chart />}
                            onClick={() => setAreStatsOpen(true)}
                        >
                            {LABELS.CHART}
                        </Button>
                    </ShowConditionally>
                    <Box display="flex">
                        <Button
                            variant="outlined"
                            disabled={isOffline}
                            startIcon={<originalIcons.Watch />}
                        >
                            <UnstyledLink to={`/documents/${companyID}`}>
                                {LABELS.HISTORY}
                            </UnstyledLink>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <ConfirmationDialog
                submitButtonProps={{
                    children: 'Usuń',
                    isLoading: isAuditRemoving,
                }}
                title="Czy jesteś pewien, że chcesz usunąć audyt?"
                open={isConfirmRemoveAuditDialogOpen}
                onClose={onAuditRemoveConfirmation}
            >
                Po usunięciu audyt wraz z informacjami o statusie wymagań
                zostanie permanentnie usunięte.
            </ConfirmationDialog>
            <ConfirmationDialog
                submitButtonProps={{ children: 'Zakończ' }}
                title="Czy jesteś pewien, że chcesz zakończyć audyt?"
                open={isConfirmCloseAuditDialogOpen}
                onClose={onAuditCloseConfirmation}
            >
                Po zakończeniu, Raport z Audytu wraz z informacjami o statusie
                wymagań zostaną automatycznie udostępnione Klientowi. Upewnij
                się, że wsystkie wymagania zostały sprawdzone oraz, że
                podsumowanie raportu zostało uzupełnione.
            </ConfirmationDialog>
            <ACL
                allowedRoles={[
                    Roles.Client,
                    Roles.ClientEditor,
                    ...(isPreviewMode ? [Roles.Admin, Roles.Auditor] : []),
                ]}
            >
                <Dialog fullWidth open={areStatsOpen} maxWidth="sm">
                    <ClientAuditStats
                        handleClose={() => setAreStatsOpen(false)}
                        isPreviewMode={isPreviewMode}
                        companyID={companyID}
                        scopeID={currentScopeID}
                        currentTabSelected={currentTabSelected}
                    />
                </Dialog>
            </ACL>
        </>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            marginBottom: theme.spacing(2),
        },
        detailsSection: {
            marginBottom: theme.spacing(2),
        },
        titleWrapper: {
            gap: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        auditTitle: {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.grey['100'],
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            borderRadius: theme.spacing(1),
        },
        title: {
            textTransform: 'uppercase',
            fontWeight: 500,
        },
        number: {
            color: theme.palette.text.secondary,
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(0.25),
            fontSize: theme.spacing(2),
        },
        infoText: {
            fontSize: theme.spacing(1.5),
        },
        separator: {
            color: theme.palette.text.secondary,
            fontWeight: 'bold',
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        mainButtonsWrapper: {
            gap: theme.spacing(1),
            alignItems: 'start',
            justifyContent: 'flex-end',
            width: '100%',
        },
        buttonsWrapper: {
            backgroundColor: theme.palette.grey['100'],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
        },
        buttonsGap: {
            gap: theme.spacing(1),
        },
    }
})
