import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'

import {
    Container,
    CssBaseline,
    TextField,
    Typography,
} from '@material-ui/core'

import { ProgressButton } from '@shared/components/progress-button.component'
import { apiJSON } from '@shared/utils/api/api.util'
import { useRouter } from '@shared/hooks/use-router'
import { ErrorsAlert } from '@shared/components/errors-alert.component'
import { registerInputRefFactory } from '@modules/admin-generic/admin-generic.utils'

type ResetPasswordForm = {
    email: string
}

export function ForgotPasswordPage() {
    const classes = useStyles()
    const { replace } = useRouter<{ token: string; email: string }>()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordForm>({
        mode: 'onChange',
        defaultValues: { email: '' },
    })

    const {
        mutate: resetPassword,
        isLoading: isPasswordResetInProgress,
        isError: resetPasswordFailed,
        error: resetPasswordError,
    } = useMutation(
        (email: string) =>
            apiJSON('users/resetpassword', { email }, { method: 'PUT' }),
        {
            onSuccess: () => {
                replace('/login')
            },
        }
    )

    const onSubmit = useCallback(
        (data: ResetPasswordForm) => {
            resetPassword(data.email)
        },
        [resetPassword]
    )

    const registerInputRef = registerInputRefFactory(register)

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logo}>
                    <img
                        src="/logo/logo-e.jpg"
                        className={classes.logoImage}
                        alt="logo"
                    />
                </div>
                <Typography component="h1" variant="h5">
                    Zapomniałeś hasło?
                </Typography>
                <form
                    className={classes.form}
                    onSubmit={(event) => event.preventDefault()}
                >
                    <ErrorsAlert errors={resetPasswordError} />
                    <TextField
                        {...registerInputRef('email', {
                            required: 'Musisz podać email',
                        })}
                        helperText={errors?.email && errors.email.message}
                        error={Boolean(errors?.email)}
                        inputProps={{
                            autoCapitalize: 'none',
                        }}
                        disabled={isPasswordResetInProgress}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        autoComplete="email"
                        autoFocus
                    />
                    <ProgressButton
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isPasswordResetInProgress}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Wyślij mail resetujący hasło
                    </ProgressButton>
                </form>
            </div>
        </Container>
    )
}

export const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFF',
        padding: '20px',
    },
    logo: {
        margin: theme.spacing(1),
        backgroundColor: '#FFF',
    },
    logoImage: {
        width: '100px',
        height: '100px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))
