import React from 'react'

const MaintenanceScreen: React.FC = () => {
    return (
        <div style={styles.container}>
            <h1>Przerwa techniczna</h1>
            <p>
                Nasza aplikacja jest obecnie w trakcie przerwy technicznej.
                Spróbuj ponownie później.
            </p>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        justifyContent: 'center' as const,
        height: '100vh',
        backgroundColor: '#f0f0f0',
        textAlign: 'center' as const,
    },
}

export default MaintenanceScreen
