/**
 * @todo investigate getting types from swagger:
 * - https://dev.to/pavelkeyzik/generate-typescript-types-from-swagger-openapi-3-amh
 * - https://github.com/mtennoe/swagger-typescript-codegen
 */
export type Audit = {
    auditID: number
    auditNumber: string
    preparationDate: string
    reportDate: string
    legalStatusVersion: string
    auditors: string
    companyID: number
    company: {
        companyID: number
        name: string
        companyHeadquaters: string
    }
    auditParticipants: string
    introductionLeft: string
    introductionRight: string
    summary: string
    localization: string
    activities: string
    assessmentScope: string
    excelURL: string
    auditCompleted: boolean
    additionalInformation: string
    auditRequirements: AuditRequirement[]
}

export type Location = {
    companyID: number
    name: string
    companyHeadquaters: string
}

export type LocationAudits = {
    lastActiveAuditID: number
    lastClosedAuditID: number

    lastClosedAuditLocalization: string
    lastActiveAuditLocalization: string
}

export type LocationDecorated = Location &
    LocationAudits & {
        action: string
    }

export type LegalBasisType = {
    legalBasisTypeID: string
    name: string
}

export type LegalBasis = {
    details: string
    paragraph: string
    documentName: string
    legalBasisID: number
    legalBasisType: LegalBasisType
    lexApiNRO: string
    lexApiURL: string
}

export type AuditRequirementImage = {
    imageID: number | string
    imageURL: string
    fileName: string
    auditID: number
    auditRequirementID: number
}

export type BaseRequirementLegalBasis = {
    legalBasis: LegalBasis
    paragraph: string
}

export type BaseRequirement = {
    requirementBaseID: number
    group: RequirementDefaultGroup
    name: string
    description: string
    tooltipData: string
    fixDate: string
    order: number
    isPublished: boolean
    deprecated: boolean
    default: false
    legalBasis: BaseRequirementLegalBasis[]
}

interface IColorsPropertiesObject {
    auditor: string
    client: string
}

interface IColorsPropertiesObject {
    auditor: string
    client: string
}

export type AuditRequirement = {
    summary: string
    auditRequirementID: number
    auditID: number
    groupID: number
    scopeID: number
    name: string
    description: string
    fixDate: string
    evaluationDate: string
    legalBasisParagraph: string
    auditorAssessment: string
    requirementBaseID: number
    isOutdated: boolean
    images: AuditRequirementImage[]
    auditorStatus: RequirementStatus
    clientStatus: RequirementStatus
    legalBasis: LegalBasis[]
    tooltipData: string
    group: RequirementsGroup<any>
    connectedAuditRequirements: number[]
    colors: IColorsPropertiesObject
    isDraft: boolean
}

export type AuditRequirementEnhanced = AuditRequirement & {
    group: RequirementDefaultGroup
    scope: Scope
}

export type AuditRequirements = AuditRequirement[]

export type TableData = {
    tableData?: { id: number }
}

export type RequirementType = AuditRequirement | BaseRequirement

export type RequirementsGroup<Requirement> = {
    groupID: number
    name: string
    scope: Scope
    requirements: Requirement[]
    no?: number
}

export type RequirementStatus = {
    statusID: number
    statusBaseID: number
    comment?: string
}

export type RequirementDefaultGroup = {
    groupID: number
    scope: Scope
    name: string
    order: number
}

export type RequirementDefaultGroups = RequirementDefaultGroup[]

export enum StatusTypes {
    Accepted = 'Accepted',
    Warnings = 'Warnings',
    Infringement = 'Infringement',
    Rejected = 'Rejected',
    NotAssessed = 'NotAssessed',
    NotApplicable = 'NotApplicable',
    NoShow = 'NoShow',
    New = 'New',
    /**
     * This is special type only for FE purposes, used to show negative color of parent status
     */
    NotAssessedNegative = 'NotAssessedNegative',
}

export type Status = {
    statusID: number
    name: string
    order: number
    label: StatusTypes
}

export type SummaryTemplate = {
    descriptionTemplateID: number
    description: string
    summaryTemplateID: number
}

export type Template = {
    descriptionTemplateID: number
    description: string
    status: Status
    requirementBase: BaseRequirement
    summaryTemplates: SummaryTemplate[]
}

export enum Roles {
    Admin = 'Administrator',
    Auditor = 'Audytor',
    Client = 'Klient',
    ClientEditor = 'Klient - edytor',
}

export type UserPassword = {
    password: string
}

export type Role = {
    roleID: string
    roleName: Roles
}

export type User = {
    email: string
    userID: string
    userRoles: Role[]
    companies: Location[]
    name?: string
    surname?: string
}

export type Scope = {
    name: string
    scopeID: number
    imageURL: string
    groups: RequirementsGroup<AuditRequirement>[]
}

export type Document = {
    name: string
    creationDate: string
}

export type AutocompleteValue = Record<string, string>

export enum AdminResourceType {
    USERS = 'users',
    COMPANIES = 'companies',
    ROLES = 'roles',
    SCOPES = 'scopes',
    GROUPS = 'groups',
    STATUSES = 'statuses',
    TEMPLATES = 'templates',
    REQUIREMENTS = 'requirements',
    LEGAL_BASIS = 'legalbasis',
    LEGAL_BASIS_TYPE = 'legalbasistype',
    SUMMARY_TEMPLATES = 'summaryTemplates',
}

export enum StatusKeyTypes {
    CLIENT_STATUS = 'clientStatus',
    AUDITOR_STATUS = 'auditorStatus',
}

export type GenericResourceData = Record<string, unknown>[]

export type LexSearchResultType = {
    documentMainType: string
    documentType: string
    nro: number
    signature: string
    title: string
    version: number
}

export type LexSearchType = {
    allDocumentCount: number
    deprecated: boolean
    results: LexSearchResultType[]
    size: number
}
