import { AuditRequirement } from '@shared/types'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

export const useButtonsRestrictions = (selectedStatus: number) => {
    const [selectedStatusID, setSelectedStatusID] =
        useState<number>(selectedStatus)
    const { watch } = useFormContext<AuditRequirement>()

    const auditorSummary = watch('summary')
    const auditorAssessment = watch('auditorAssessment')

    const hideTooltip = selectedStatusID === 7
    const isStatusNew = selectedStatusID === 8
    const isAuditorAssessmentEmpty =
        !auditorAssessment || auditorAssessment?.length === 0
    const isAuditorAssessmentEmptyWithStatus =
        selectedStatusID !== 7 && isAuditorAssessmentEmpty
    const isSummaryEmpty = !auditorSummary || auditorSummary?.length === 0

    const isSaveDisabled = useMemo(
        () => isStatusNew || isAuditorAssessmentEmptyWithStatus,
        [isAuditorAssessmentEmptyWithStatus, isStatusNew]
    )

    return {
        hideTooltip,
        isSaveDisabled,
        isStatusNew,
        isAuditorAssessmentEmpty,
        isSummaryEmpty,
        selectedStatusID,
        setSelectedStatusID,
    }
}
