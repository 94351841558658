import { Audit, AuditRequirementEnhanced } from '@shared/types'

export const getConnectedAuditRequirements = (
    audit: Audit,
    requirement: AuditRequirementEnhanced
) => {
    return audit.auditRequirements.filter((auditRequirement) =>
        requirement.connectedAuditRequirements.includes(
            auditRequirement.auditRequirementID
        )
    )
}
