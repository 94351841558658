import { colors } from '@material-ui/core'
import { StatusTypes } from '@shared/types'

export const statusColors = {
  [StatusTypes.NotAssessed]: colors.common.black,
  [StatusTypes.NotAssessedNegative]: colors.common.white,
  [StatusTypes.NotApplicable]: colors.grey['500'],
  [StatusTypes.Warnings]: colors.blue['500'],
  [StatusTypes.Accepted]: colors.green['500'],
  [StatusTypes.Rejected]: colors.red['500'],
  [StatusTypes.Infringement]: colors.orange['500'],
  [StatusTypes.New]: colors.pink['100'],

  /**
   * @todo ask Michał about proper one
   */
  [StatusTypes.NoShow]: colors.brown['500']
}
