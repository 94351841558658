import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { useStyles } from './progress-button.component';
import React, { useState, useRef, useEffect } from 'react';

export type ProgressDownloadButtonProps = Omit<
  ButtonProps & { downloadUrl?: string; },
  'onClick'>

export const DOWNLAOD_STARTED_COOKIE_NAME = 'downloadStarted'

function getCookie(name: string) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookieName = cookies[i].split('=')[0]
    if (cookieName === name) {
      return true
    }
  }
};

export function ProgressDownloadButton(props: ProgressDownloadButtonProps) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    timerRef.current = window.setInterval(() => {
      if (getCookie(DOWNLAOD_STARTED_COOKIE_NAME)) {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [isLoading]);

  return (
    <>
      <Button {...props} onClick={() => {
        anchorRef.current?.click();
      }}>
        {props.children}
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
      <a
        ref={anchorRef}
        href={props.downloadUrl}
        target='_self'
        rel='noreferrer'
        style={{ display: 'none' }}
        onClick={() => setIsLoading(true)}>
      </a>
    </>
  );
}
