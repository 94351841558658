import { UseQueryOptions, useQuery } from 'react-query'

import { apiJSON } from '@shared/utils/api/api.util'
import { Audit } from '@shared/types'
import { OfflineEntityType, useOfflineContent } from './use-offline-content'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'

export function useAuditQuery(auditID: number | string, options?: UseQueryOptions<Audit>) {
  const { isOffline } = useNavigatorOnline()
  const offlineContent = useOfflineContent()

  const {
    data: audit,
    isLoading: isAuditLoading,
    refetch: refetchAudit
  } = useQuery<Audit>(`audits/${auditID}`, () => apiJSON(`audits/${auditID}`), options)

  if (audit && !isOffline) {
    offlineContent.set(OfflineEntityType.Audit, audit)
  }

  return { audit, isAuditLoading, refetchAudit }
}
