import React from 'react'
import {
    ViewRowTableFieldLabelEnum,
    ViewRowTableFieldPropertyEnum,
} from '@shared/lib/reducer'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'

type Variants =
    | ViewRowTableFieldPropertyEnum.CONFORMITY_ASSESSMENT
    | ViewRowTableFieldPropertyEnum.ACTION_TAKEN

interface Props {
    content: string
    mainColor?: string
    variant: Variants
}

const getTitle = (variant: Variants) => {
    switch (variant) {
        case ViewRowTableFieldPropertyEnum.ACTION_TAKEN:
            return ViewRowTableFieldLabelEnum.ACTION_TAKEN

        case ViewRowTableFieldPropertyEnum.CONFORMITY_ASSESSMENT:
            return ViewRowTableFieldLabelEnum.CONFORMITY_ASSESSMENT

        default:
            return ''
    }
}

const getIcon = (variant: Variants) => {
    switch (variant) {
        case ViewRowTableFieldPropertyEnum.ACTION_TAKEN:
            return <originalIcons.Description />

        case ViewRowTableFieldPropertyEnum.CONFORMITY_ASSESSMENT:
            return <originalIcons.Comment />

        default:
            return <></>
    }
}

function lightenColor(hex: string, percent: number): string {
    hex = hex.replace(/^#/, '')

    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)

    r = Math.min(255, Math.floor(r + (255 - r) * percent))
    g = Math.min(255, Math.floor(g + (255 - g) * percent))
    b = Math.min(255, Math.floor(b + (255 - b) * percent))

    return (
        '#' +
        r.toString(16).padStart(2, '0') +
        g.toString(16).padStart(2, '0') +
        b.toString(16).padStart(2, '0')
    )
}

export const TableRequirementCommentComponent = ({
    content,
    mainColor,
    variant,
}: Props) => {
    const classes = useStyles()

    return (
        <div
            className={classes.wrapper}
            style={
                mainColor
                    ? { backgroundColor: lightenColor(mainColor, 0.92) }
                    : {}
            }
        >
            <div
                className={classes.header}
                style={mainColor ? { color: mainColor } : {}}
            >
                {getIcon(variant)}
                <Typography variant="subtitle2" className={classes.headerTitle}>
                    {getTitle(variant)}:
                </Typography>
            </div>
            <Typography variant="body2">{content}</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        border: '1px solid',
        padding: theme.spacing(1.5),
        borderRadius: 4,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderColor: '#E8E8E8',
        backgroundColor: '#F4F4F4',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(0.8),
    },
    headerTitle: {
        marginLeft: theme.spacing(1),
        textTransform: 'uppercase',
    },
}))
