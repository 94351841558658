import { useCallback, useEffect, useState } from 'react'

export function usePersistedTabChange(persistedTabKey: string) {
  const [currentTab, setCurrentTab] = useState<string>('0')

  useEffect(() => {
    const persistedCurrentTab = localStorage.getItem(persistedTabKey)

    if (persistedCurrentTab) {
      setCurrentTab(persistedCurrentTab)
    }
  }, [persistedTabKey])

  const onTabChange = useCallback(
    (event: unknown, newValue: string) => {
      setCurrentTab(newValue)
      localStorage.setItem(persistedTabKey, newValue)
    },
    [setCurrentTab, persistedTabKey]
  )

  return {
    currentTab,
    onTabChange
  }
}
