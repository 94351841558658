import React, { useRef } from 'react'
import { camelCase, get, isArray, omit } from 'lodash'
import { Route } from 'react-router-dom'
import { useQuery } from 'react-query'
import { JSONSchema7 } from 'json-schema'

import { apiJSON } from '@shared/utils/api/api.util'
import { Table } from '@shared/components/table/table.component'
import { useRouter } from '@shared/hooks/use-router'
import { ConfirmationDialog } from '@shared/components/confirmation.dialog.component'
import { useSchemasQuery } from '@shared/hooks/use-schemas-query.hook'
import { Empty } from '@shared/components/empty.component'
import { useResourceRemove } from '@shared/hooks/use-resource-remove.hook'
import { originalIcons } from '@shared/components/icons/icons'

import { Resource } from './admin-generic.types'
import { AdminGenericCrudDialog } from './admin-generic-crud.dialog.component'
import { useThemeStyles } from '@shared/theme/use-theme-styles'
import {
    defaultParseResourceData,
    getFinalResourceConfig,
    getOmittedFields,
    getResourceConfig,
    getResourceIdentityProperty,
    getResourceRefSchema,
    getResourceSchemaKeyFromRef,
    RESOURCE_NAME_ROUTE_PARAM,
} from '@modules/admin-generic/admin-generic.utils'
import { AdminResourceType, GenericResourceData } from '@shared/types'

type RouteQuery = {
    resourceName: string
    filterBy?: string
    filterValue?: string
}

export const AdminGenericCrudPage = () => {
    const {
        query: { resourceName, filterBy, filterValue },
        push,
    } = useRouter<RouteQuery>()

    const serachText = useRef<string | undefined>(undefined)
    const themeClasses = useThemeStyles()
    const resourceConfig = getResourceConfig(resourceName) ?? ({} as Resource)

    const {
        dto: resourceSchemaKey,
        singular: resourceNameSingular,
        endpoint: resourceEndpoint,
        table: resourceTableConfig,
        parseData: parseResourceData,
        components: resourceTableComponents,
    } = resourceConfig

    const resourceIdentityProperty = getResourceIdentityProperty(
        `${camelCase(resourceSchemaKey)}`
    )

    const { schema } = useSchemasQuery()

    const {
        isRemovalDialogOpened,
        isResourceRemoving,
        setRemovalDialogOpened,
        setRemovedResourceID,
        onResourceRemove,
        isInvalidatingAfterRemove,
    } = useResourceRemove(resourceEndpoint)

    const { data: resourceData, isLoading: isResourceLoading } =
        useQuery<GenericResourceData>(resourceEndpoint, () =>
            apiJSON(resourceEndpoint)
        )

    const resourceSchema =
        schema?.components?.schemas?.[`${resourceSchemaKey}DTO`]

    const resourceSchemaProperties = resourceSchema?.properties ?? {}

    const resourceSchemaRequiredProperties = resourceSchema?.required ?? []

    const resourceFinalSchema = omit(
        resourceSchemaProperties,
        getOmittedFields(
            resourceNameSingular,
            resourceConfig,
            resourceSchemaProperties
        )
    ) as JSONSchema7

    const resourceTitle = resourceSchema?.description ?? resourceNameSingular

    const columns = (
        Object.entries(resourceFinalSchema)
            .map(([property, propertySchema]: [string, JSONSchema7]) => {
                if (resourceTableConfig?.[property]) {
                    return resourceTableConfig[property]
                }

                /**
                 * @todo implement custom array fields rendering
                 */
                if (
                    propertySchema?.type === 'array' ||
                    (resourceName === AdminResourceType.LEGAL_BASIS &&
                        ['lexApiNRO', 'lexApiURL'].includes(property))
                ) {
                    return false
                }

                if (propertySchema?.$ref) {
                    const propertyResourceSchemaKey =
                        getResourceSchemaKeyFromRef(propertySchema?.$ref)

                    const $refResourceConfig = getFinalResourceConfig(
                        propertyResourceSchemaKey
                    )
                    const $refSchema = getResourceRefSchema(
                        schema?.components?.schemas,
                        $refResourceConfig
                    )

                    if (!$refSchema?.properties?.name) {
                        return false
                    }

                    return {
                        field: `${property}.name`,
                        title: $refSchema?.description ?? property,
                        ...($refResourceConfig.lookup
                            ? {
                                  lookup: (resourceData ?? []).reduce(
                                      (memo, item) => {
                                          memo[
                                              get(
                                                  item,
                                                  `${property}.name`
                                              ) as string
                                          ] = get(item, `${property}.name`)

                                          return memo
                                      },
                                      {}
                                  ),
                              }
                            : {}),
                    }
                }

                return {
                    field: property,
                    title: propertySchema?.description ?? property,
                    ...(propertySchema?.maxLength ?? 0 >= 10000
                        ? { width: '60%' }
                        : {}),
                }
            })
            .filter(Boolean) as { field: string; title: string }[]
    ).map((column, index) => {
        const commonStyles = {
            padding: 5,
            ...(index === 0 ? { paddingLeft: 20 } : {}),
        }
        let newColumn
        if (column.field === 'companyHeadquaters') {
            newColumn = {
                ...column,
                customSort: (a: any, b: any) =>
                    a['companyHeadquaters'].localeCompare(
                        b['companyHeadquaters'],
                        'pl'
                    ),
            }
        } else {
            newColumn = column
        }

        return {
            ...newColumn,
            cellStyle: commonStyles,
            headerStyle: commonStyles,
        }
    })

    return (
        <span className={themeClasses.tableContainer}>
            <Table<{ [key: string]: string | number }>
                isLoading={isResourceLoading || isInvalidatingAfterRemove}
                data={
                    parseResourceData
                        ? parseResourceData(resourceData ?? [])
                        : defaultParseResourceData(resourceData, {
                              filterBy,
                              filterValue,
                          })
                }
                title={resourceTitle}
                columns={columns}
                options={{
                    grouping: true,
                    filtering: true,
                    searchText: serachText.current,
                }}
                onSearchChange={(value) => (serachText.current = value)}
                components={{
                    Groupbar: Empty,
                    ...(resourceTableComponents ?? {}),
                }}
                actions={[
                    {
                        icon: originalIcons.Create,
                        tooltip: 'Edytuj',
                        onClick: (
                            event: React.MouseEvent<HTMLElement>,
                            resourceEntity
                        ) => {
                            if (!isArray(resourceEntity)) {
                                push(
                                    `/admin/${resourceName}/edit/${
                                        resourceEntity[
                                            resourceIdentityProperty
                                        ] as unknown as string
                                    }`,
                                    {
                                        resourceEntity,
                                    }
                                )
                            }
                        },
                    },
                    {
                        icon: originalIcons.Delete,
                        tooltip: 'Usuń',
                        onClick: (
                            event: React.MouseEvent<HTMLElement>,
                            resourceEntity
                        ) => {
                            if (!isArray(resourceEntity)) {
                                setRemovedResourceID(
                                    resourceEntity[
                                        resourceIdentityProperty
                                    ] as unknown as string
                                )
                                setRemovalDialogOpened(true)
                            }
                        },
                    },
                    {
                        icon: originalIcons.Add,
                        isFreeAction: true,
                        onClick: () => {
                            push(`/admin/${resourceName}/add`)
                        },
                    },
                ]}
            />
            <Route
                path={[
                    `/admin/${RESOURCE_NAME_ROUTE_PARAM}/add`,
                    `/admin/${RESOURCE_NAME_ROUTE_PARAM}/edit/:resourceID`,
                ]}
            >
                <AdminGenericCrudDialog
                    schema={schema ?? {}}
                    resourceName={resourceName}
                    resourceNameSingular={resourceNameSingular}
                    resourceEndpoint={resourceEndpoint}
                    properties={resourceFinalSchema}
                    requiredProperties={resourceSchemaRequiredProperties}
                />
            </Route>
            <ConfirmationDialog
                submitButtonProps={{
                    children: 'Usuń',
                    isLoading: isResourceRemoving,
                }}
                title="Potwierdzenie usunięcia"
                onClose={onResourceRemove}
                open={isRemovalDialogOpened}
            >
                Czy napewno chcesz usunąć dany wiersz?
            </ConfirmationDialog>
        </span>
    )
}
