import React, { useCallback, useState } from 'react'
import { Controller, Control, useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Checkbox, Grid, TextField } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { ACL } from '@shared/components/acl.component'
import {
    Audit,
    AuditRequirement,
    AuditRequirementEnhanced,
    Roles,
    Template,
} from '@shared/types'
import { useAuditQuery } from '@shared/hooks/use-audit-query.hook'
import { Autocomplete } from '@material-ui/lab'
import { orderBy } from 'lodash'
import { FormTemplates } from '@modules/requirement/requirement-form/form-templates.component'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { useRouter } from '@shared/hooks/use-router'
import { registerInputRefFactory } from '@modules/admin-generic/admin-generic.utils'

type RouteQuery = {
    companyID: string
    auditID: string
    auditRequirementID: string
}
type RouteLocation = {
    requirement: AuditRequirementEnhanced
    audit: Audit
    isPreviewMode: boolean
}

export type RequirementDetailsFormData = {
    saveAsTemplate: boolean
    saveAsTemplateEnhanced: boolean
    fixDate: AuditRequirement['fixDate']
    summary: AuditRequirement['summary']
    auditorAssessment: AuditRequirement['auditorAssessment']
    clientComment: AuditRequirement['clientStatus']['comment']
    connectedAuditRequirements: AuditRequirement['connectedAuditRequirements']
}

type Props = {
    auditID: number
    statusID: number
    requirementBaseID: number
    formControl: Control<RequirementDetailsFormData>
    isAuditorAssessmentEmpty: boolean
    onTakePhoto: () => void
    onUploadPhoto: () => void
    isClient: boolean
    isClientOrClientEditor: boolean
    setSelectedTemplate: (value: Template) => void
}

export function FormStatusDetails({
    auditID,
    statusID,
    requirementBaseID,
    formControl,
    isAuditorAssessmentEmpty,
    onTakePhoto,
    onUploadPhoto,
    isClient,
    isClientOrClientEditor,
    setSelectedTemplate,
}: Props) {
    const classes = useStyles()
    const { audit, isAuditLoading } = useAuditQuery(auditID)
    const { location } = useRouter<RouteQuery, RouteLocation>()

    const [showTemplates, setShowTemplates] = useState(false)

    const { register, setValue } = useFormContext()
    const registerInputRef = registerInputRefFactory(register)

    const onShowTemplatesClick = useCallback(() => {
        setShowTemplates(!showTemplates)
    }, [showTemplates])

    const onSetTemplate = useCallback(
        (templateText) => {
            setValue('auditorAssessment', templateText)
            setShowTemplates(false)
        },
        [setShowTemplates, setValue]
    )

    const filterOptions = useCallback((options, { inputValue }) => {
        const searchValue = inputValue.toLowerCase()
        return options.filter((option: AuditRequirement) =>
            option.name.toLowerCase().includes(searchValue)
        )
    }, [])

    return (
        <ShowConditionally when={!location.state.isPreviewMode}>
            <>
                <ACL allowedRoles={[Roles.ClientEditor]}>
                    <TextField
                        {...registerInputRef('clientComment')}
                        disabled={isClient}
                        label={
                            isClientOrClientEditor
                                ? 'Mój komentarz (klient)'
                                : 'Komentarz klienta'
                        }
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </ACL>
                {!isAuditLoading ? (
                    <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                        <>
                            <TextField
                                {...registerInputRef('auditorAssessment')}
                                label="Realizacja wymagania"
                                fullWidth
                                multiline
                                rowsMax={20}
                                rows={6}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Grid container>
                                <FormTemplates
                                    showTemplates={showTemplates}
                                    requirementBaseID={requirementBaseID}
                                    onSetTemplate={onSetTemplate}
                                    statusID={statusID}
                                    onShowTemplatesClick={onShowTemplatesClick}
                                    saveDisabled={isAuditorAssessmentEmpty}
                                    controlName="saveAsTemplate"
                                    setSelectedTemplate={setSelectedTemplate}
                                >
                                    <>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<PhotoCameraIcon />}
                                            onClick={onTakePhoto}
                                        >
                                            Zrób zdjęcie
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<PhotoCameraIcon />}
                                            onClick={onUploadPhoto}
                                        >
                                            Dodaj zdjęcie z dysku
                                        </Button>
                                    </>
                                </FormTemplates>
                            </Grid>
                            <Controller
                                defaultValue={[]}
                                control={formControl}
                                name="connectedAuditRequirements"
                                render={(formControllerProps) => (
                                    <>
                                        <Autocomplete
                                            value={
                                                formControllerProps.field.value
                                            }
                                            {...formControllerProps}
                                            filterOptions={filterOptions}
                                            fullWidth
                                            multiple
                                            disableCloseOnSelect
                                            className={
                                                classes.connectedRequirementsAutocomplete
                                            }
                                            options={orderBy(
                                                audit?.auditRequirements,
                                                'name'
                                            )}
                                            onChange={(event, values) => {
                                                const requirementIds =
                                                    values.map(
                                                        (i) =>
                                                            i.auditRequirementID ??
                                                            i
                                                    )
                                                const dedupedValues =
                                                    requirementIds.filter(
                                                        (value, index) =>
                                                            requirementIds.indexOf(
                                                                value
                                                            ) === index
                                                    )
                                                formControllerProps.field.onChange(
                                                    dedupedValues
                                                )
                                            }}
                                            getOptionLabel={(option: any) =>
                                                audit?.auditRequirements.find(
                                                    (i) =>
                                                        i.auditRequirementID ===
                                                        option
                                                )?.name ?? '-'
                                            }
                                            renderOption={(option: any) => {
                                                const selected =
                                                    !!formControllerProps.field.value.find(
                                                        (i: any) =>
                                                            i ===
                                                            option.auditRequirementID
                                                    )
                                                return (
                                                    <>
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                        />
                                                        {
                                                            (
                                                                option as AuditRequirement
                                                            ).name
                                                        }
                                                    </>
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Powiązane wymagania"
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            />
                        </>
                    </ACL>
                ) : null}
            </>
        </ShowConditionally>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        actionButtonsContainer: {
            alignItems: 'center',
            display: 'flex',
        },
        connectedRequirementsAutocomplete: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
    }
})
