import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const ENABLED_VALUE = 'enabled'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_USE_SERVICE_WORKER === ENABLED_VALUE) {
  serviceWorkerRegistration.register({
    onSuccess: () => {
      alert('Aplikacja będzie starała się zachować dane w przypadku wykrycia braku połączenia z internetem')
    },
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event: any) => {
          /* tslint:disable no-console */
          console.log('statechange', event)
          if (event?.target?.state === 'activated') {
            const result = window.confirm('Pojawiła się nowa wersja aplikacji, kliknij OK żeby wczytać')

            if (result) {
              window.location.reload()
            }
          }
        })

        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
      }
    }
  })
}
