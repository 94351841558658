import { useState } from 'react'

import { AuthService } from './auth.types'

import { api, apiJSON, CRITICAL_ERROR_MESSAGE, ErrorResponse, normalizeErrors } from '@shared/utils/api/api.util'
import { User } from '@shared/types'

export type CreateSessionResponse = {
  type: string
  title: string
  status: number
  detail: string
  instance: string
}

export type ReadSessionResponse = User

export function useAuthProvider(): AuthService {
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [authenticatedUser, setAuthenticatedUser] = useState<ReadSessionResponse | null>(null)

  const login = async (email: string, password: string) => {
    const response = await api('sessions', { email, password, remmeberMe: false }, { method: 'POST' })

    if (response.status === 200) {
      await checkIsLogged()
    }

    if (response.status === 400) {
      throw normalizeErrors(((await response.json()) as ErrorResponse)?.errors ?? CRITICAL_ERROR_MESSAGE)
    }

    if (response.status === 401) {
      throw normalizeErrors(await response.text())
    }
  }

  const logout = async () => {
    const response = await api('sessions', {}, { method: 'DELETE' })

    if (response.status === 204) {
      setIsLogged(false)
      setAuthenticatedUser(null)
    }
  }

  function isSessionResponse(data: any): data is ReadSessionResponse {
    return data?.userID && data?.userRoles
  }

  const checkIsLogged = async () => {
    const data: ReadSessionResponse = await apiJSON('sessions')

    if (isSessionResponse(data)) {
      setIsLogged(true)
      setAuthenticatedUser(data)
    }
  }

  return {
    isLogged,

    login,
    logout,

    checkIsLogged,
    authenticatedUser
  }
}
