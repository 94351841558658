import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Redirect } from 'react-router-dom'
import { Controller, FormProvider, useFieldArray } from 'react-hook-form'
import { filter, find, isEqual } from 'lodash'

import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    makeStyles,
    Switch,
    Typography,
    TextField as MaterialTextField,
    Tooltip,
    InputAdornment,
    Link,
} from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'

import { usePrevious } from '@shared/hooks/use-previous.hook'
import { useRouter } from '@shared/hooks/use-router'
import { useScopesQuery } from '@shared/hooks/use-scopes-query.hook'
import { ProgressButton } from '@shared/components/progress-button.component'
import { TextField } from '@shared/components/text-field.component'
import { originalIcons } from '@shared/components/icons/icons'
import { apiJSON } from '@shared/utils/api/api.util'
import { useHandleResourceState } from '@shared/hooks/use-handle-resource-state.hook'
import { ACL } from '@shared/components/acl.component'
import { Roles } from '@shared/types'

import { useThemeStyles } from '@shared/theme/use-theme-styles'

import {
    AuditRequirement,
    BaseRequirement,
    BaseRequirementLegalBasis,
    LegalBasis,
    RequirementsGroup,
    Scope,
} from '@shared/types'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { ErrorsAlert } from '@shared/components/errors-alert.component'
import { BackdropProgress } from '@shared/components/backdrop-progress.component'
import { LegalActStrings } from '@shared/consts'
import { adminRequirementTooltips } from './consts'
import { registerInputRefFactory } from '@modules/admin-generic/admin-generic.utils'
import { useGenerateParagraphLinks } from '@modules/admin-requirements/use-generate-paragraph-links'
import { InfoTooltip } from '@shared/components/info-tooltip.component'
import LinkStatus from '@modules/admin-requirements/link-status'

type Groups = RequirementsGroup<AuditRequirement>[]

enum SAVE_TYPES {
    DEPRECATE = 'Deprecate',
    SILENT = 'Silent',
    NORMAL = 'Normal',
}

const PARAGRAPH_INPUT_INFO_DESCRIPTION = (
    <div>
        <p>Tutaj należy wpisać odpowiednie artykuły lub paragrafy dokumentu:</p>
        <ul>
            <li>
                Aby dodać pojedynczy artykuł, wpisz: <code>art. 1</code>
            </li>
            <li>
                Aby dodać przypis górny dodaj znak <code>^</code> np.:{' '}
                <code>art. 9^1, Art. 11^3</code>
            </li>
            <li>
                Aby dodać wiele artykułów, oddziel je przecinkami, np.:{' '}
                <code>art. 1, art. 2, art. 3</code>
            </li>
            <li>
                Aby dodać paragraf, użyj znaku §, np.: <code>§ 1</code>
            </li>
            <li>
                Aby dodać wiele paragrafów, oddziel je przecinkami, np.:{' '}
                <code>§ 1, § 2, § 3</code>
            </li>
            <li>
                Aby dodać zakres artykułów/paragrafów, wpisz numer pierwszego i
                ostatniego w zakresie, oddzielając je myślnikiem:{' '}
                <code>Art. 1-20, § 1-20</code>
            </li>
        </ul>
    </div>
)

export const AdminRequirementCrudDialog = () => {
    const classes = useStyles()
    const sharedClasses = useThemeStyles()

    const { history, query } = useRouter<{ resourceID?: string }>()

    const { areScopesLoading, scopes } = useScopesQuery()
    const [currentScope, setCurrentScope] = useState<Scope | null>(null)
    const [scopeGroups, setScopeGroups] = useState<Groups>(
        [] as unknown as Groups
    )
    const { legalBasisLinks, handleGenerateLinks } = useGenerateParagraphLinks()
    const { data: groups, isLoading: isLoadingGroups } = useQuery<Groups>(
        'groups',
        () => apiJSON('groups')
    )

    /**
     * @todo retrieve from admin-generic.consts
     */
    const { data: legalBasisList, isLoading: isLoadingLegalBasis } = useQuery<
        LegalBasis[]
    >('legalbasis/crud', () => apiJSON('legalbasis/crud'))

    const onScopeChange = useCallback(
        (e: any, value: Scope | null) => {
            if (value) {
                setCurrentScope(value)
            }
        },
        [setCurrentScope]
    )

    const {
        formMethods,
        onAddResource,
        mutation: {
            isResourceEdit,
            isResourceCreating,
            isResourceCreationSucceed,
            createResourceErrors,
            resetResourceCreationState,
        },
    } = useHandleResourceState<BaseRequirement & { saveMethod: SAVE_TYPES }>({
        /**
         * @todo should be taken from config
         */
        resourceEndpoint: 'requirementbase/crud',
        resourceID: query?.resourceID,
    })

    const {
        fields: legalBasisFields,
        append: addLegalBasis,
        remove: removeLegalBasis,
    } = useFieldArray<BaseRequirementLegalBasis>({
        control: formMethods.control,
        name: 'legalBasis',
    })

    const groupFormValue = formMethods.watch('group')
    const legalBasisValue = formMethods.watch('legalBasis')
    const isPublishedFormValue = formMethods.watch('isPublished')

    const previousScope = usePrevious(currentScope)

    const { setValue: setFormValue } = formMethods

    /* eslint react-hooks/exhaustive-deps: 0 */
    useEffect(() => {
        if (previousScope) {
            setFormValue('group', null)
        }

        setScopeGroups(
            filter(groups ?? [], {
                scope: { scopeID: currentScope?.scopeID },
            }) ?? []
        )
    }, [currentScope, setFormValue])

    useEffect(() => {
        if (scopes?.length && !currentScope && groupFormValue) {
            const group = find(groups ?? [], {
                groupID: groupFormValue?.groupID,
            })
            const scope = find(scopes ?? [], {
                scopeID: group?.scope?.scopeID,
            })

            if (scope) {
                setCurrentScope(scope)
            }
        }
    }, [scopes, currentScope, groupFormValue])

    const onLegalBasisRemoveFactory = useCallback(
        (removedLegalBasisIndex: number) => () => {
            removeLegalBasis(removedLegalBasisIndex)
        },
        [removeLegalBasis]
    )

    const onLegalBasisAdd = useCallback(() => {
        if (legalBasisList) {
            addLegalBasis({ paragraph: '', legalBasis: legalBasisList[0] })
        }
    }, [legalBasisList])

    const registerInputRef = registerInputRefFactory(formMethods.register)

    useEffect(() => {
        if (!legalBasisLinks.length) {
            handleGenerateLinks(legalBasisValue)
        }
    }, [legalBasisValue])

    if (isResourceCreationSucceed) {
        return <Redirect to="/admin/requirements" />
    }

    const setSaveMethodAndAddResource = (saveMethod: SAVE_TYPES) => {
        setFormValue('saveMethod', saveMethod)
        onAddResource()
    }

    return !areScopesLoading && !isLoadingLegalBasis && !isLoadingGroups ? (
        <Dialog open fullWidth maxWidth="md">
            <form noValidate>
                <FormProvider {...formMethods}>
                    <DialogTitle className={sharedClasses.dialogTitle}>
                        {isResourceEdit ? 'Edytuj' : 'Utwórz nowy'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ErrorsAlert
                            errors={createResourceErrors}
                            onClose={resetResourceCreationState}
                        />
                        <Autocomplete
                            value={currentScope as Scope}
                            className={sharedClasses.autocomplete}
                            autoHighlight
                            options={scopes!}
                            getOptionLabel={(option) => option?.name ?? ''}
                            getOptionSelected={isEqual}
                            renderInput={(params) => (
                                <MaterialTextField
                                    {...params}
                                    label="Zakres"
                                    variant="outlined"
                                />
                            )}
                            onChange={onScopeChange}
                            filterSelectedOptions
                            disableClearable
                            defaultValue={null as unknown as Scope}
                        />
                        <Controller
                            name="group"
                            control={formMethods.control}
                            render={(formControllerProps) => (
                                <Autocomplete
                                    value={formControllerProps.field.value}
                                    className={sharedClasses.autocomplete}
                                    autoHighlight
                                    options={
                                        scopeGroups as unknown as {
                                            [key: string]: string
                                        }[]
                                    }
                                    getOptionLabel={(option: {
                                        [key: string]: string
                                    }) => option?.name}
                                    getOptionSelected={isEqual}
                                    renderInput={(params) => (
                                        <MaterialTextField
                                            {...params}
                                            label="Grupa"
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(e, data) =>
                                        formControllerProps.field.onChange(data)
                                    }
                                    filterSelectedOptions
                                    disableClearable
                                    {...formControllerProps}
                                />
                            )}
                            defaultValue={null}
                        />
                        <TextField
                            {...registerInputRef('name', { required: true })}
                            required
                            label="Nazwa wymagania"
                        />
                        <TextField
                            {...registerInputRef('description', {
                                required: true,
                            })}
                            required
                            multiline
                            label="Wymaganie prawne"
                        />
                        <TextField
                            {...registerInputRef('tooltipData')}
                            multiline
                            label="Objaśnienia"
                        />
                        <TextField
                            {...registerInputRef('fixDate')}
                            multiline
                            label="Termin realizacji"
                        />
                        <TextField
                            {...registerInputRef('order', { required: true })}
                            required
                            label="Pozycja"
                        />
                        <Card
                            variant="outlined"
                            className={classes.legalBasisList}
                        >
                            <Typography color="textSecondary">
                                Podstawy prawne
                            </Typography>
                            <IconButton
                                color="primary"
                                aria-label="add legal basis"
                                component="span"
                                className={classes.addLegalBasis}
                                onClick={onLegalBasisAdd}
                            >
                                <originalIcons.Add />
                            </IconButton>
                            <CardContent>
                                {legalBasisFields.map(
                                    ({ legalBasis, paragraph }, index) => {
                                        if (legalBasis) {
                                            return (
                                                <Card
                                                    variant="outlined"
                                                    className={
                                                        classes.legalBasisItem
                                                    }
                                                    key={`${legalBasis.legalBasisID}.${index}`}
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="remove legal basis"
                                                        component="span"
                                                        className={
                                                            classes.removeLegalBasis
                                                        }
                                                        onClick={onLegalBasisRemoveFactory(
                                                            index
                                                        )}
                                                    >
                                                        <originalIcons.Clear />
                                                    </IconButton>
                                                    <CardContent>
                                                        <Controller
                                                            name={`legalBasis[${index}].legalBasis`}
                                                            control={
                                                                formMethods.control
                                                            }
                                                            defaultValue={
                                                                legalBasis
                                                            }
                                                            render={(
                                                                formControllerProps
                                                            ) => {
                                                                const filteredLegalBasisList: LegalBasis[] =
                                                                    legalBasisList?.filter(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            Boolean(
                                                                                item.documentName
                                                                            )
                                                                    ) as LegalBasis[]
                                                                return (
                                                                    <Autocomplete
                                                                        value={
                                                                            formControllerProps
                                                                                .field
                                                                                .value
                                                                        }
                                                                        forcePopupIcon={
                                                                            false
                                                                        }
                                                                        className={
                                                                            sharedClasses.autocomplete
                                                                        }
                                                                        autoHighlight
                                                                        options={
                                                                            filteredLegalBasisList as LegalBasis[]
                                                                        }
                                                                        getOptionLabel={(
                                                                            option: LegalBasis
                                                                        ) =>
                                                                            option?.documentName ??
                                                                            '[USUNIĘTE]'
                                                                        }
                                                                        getOptionSelected={
                                                                            isEqual
                                                                        }
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <MaterialTextField
                                                                                {...params}
                                                                                InputProps={{
                                                                                    ...(params.InputProps ??
                                                                                        {}),
                                                                                    endAdornment:
                                                                                        (
                                                                                            <InputAdornment position="end">
                                                                                                {formControllerProps
                                                                                                    .field
                                                                                                    .value
                                                                                                    ?.legalBasisType
                                                                                                    ?.name ??
                                                                                                    'brak typu'}
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                }}
                                                                                label={
                                                                                    LegalActStrings.publisherNumber
                                                                                }
                                                                                variant="outlined"
                                                                            />
                                                                        )}
                                                                        onChange={(
                                                                            e,
                                                                            data
                                                                        ) => {
                                                                            formControllerProps.field.onChange(
                                                                                data
                                                                            )
                                                                        }}
                                                                        filterSelectedOptions
                                                                        {...formControllerProps}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        <TextField
                                                            {...registerInputRef(
                                                                `legalBasis[${index}].paragraph`
                                                            )}
                                                            multiline
                                                            label={
                                                                LegalActStrings.legalArticle
                                                            }
                                                            defaultValue={
                                                                paragraph
                                                            }
                                                            onBlur={(e) => {
                                                                registerInputRef(
                                                                    `legalBasis[${index}].paragraph`
                                                                )?.onBlur(e)
                                                                handleGenerateLinks(
                                                                    legalBasisValue
                                                                )
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <>
                                                                        <InfoTooltip
                                                                            interactive
                                                                            description={
                                                                                PARAGRAPH_INPUT_INFO_DESCRIPTION
                                                                            }
                                                                        />
                                                                    </>
                                                                ),
                                                                style: {
                                                                    padding:
                                                                        '13px 14px',
                                                                },
                                                            }}
                                                        />
                                                        <Card variant="outlined">
                                                            <CardContent>
                                                                {legalBasisLinks
                                                                    .find(
                                                                        ({
                                                                            index: legalBasisIndex,
                                                                        }) =>
                                                                            index ===
                                                                            legalBasisIndex
                                                                    )
                                                                    ?.links?.map(
                                                                        (
                                                                            link
                                                                        ) => (
                                                                            <LinkStatus
                                                                                key={
                                                                                    link
                                                                                }
                                                                                link={
                                                                                    link
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                            </CardContent>
                                                        </Card>
                                                    </CardContent>
                                                </Card>
                                            )
                                        }
                                        return null
                                    }
                                )}
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Controller
                            control={formMethods.control}
                            name="isPublished"
                            defaultValue={false}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    className={classes.isPublishedLabel}
                                    color="primary"
                                    control={
                                        <Tooltip
                                            title={
                                                adminRequirementTooltips.active
                                            }
                                        >
                                            <div>
                                                <Switch
                                                    onChange={(e) =>
                                                        onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                    checked={value}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                    label={
                                        <Tooltip
                                            title={
                                                adminRequirementTooltips.active
                                            }
                                        >
                                            <div>Aktywne</div>
                                        </Tooltip>
                                    }
                                />
                            )}
                        />
                        <Button
                            autoFocus
                            onClick={history.goBack}
                            color="primary"
                            variant="outlined"
                        >
                            Anuluj
                        </Button>
                        <ACL
                            allowedRoles={
                                [
                                    Roles.Admin,
                                    isResourceEdit ? Roles.Auditor : false,
                                ].filter(Boolean) as Roles[]
                            }
                        >
                            <Tooltip title={adminRequirementTooltips.publish}>
                                <div>
                                    <ProgressButton
                                        isLoading={isResourceCreating}
                                        onClick={() =>
                                            setSaveMethodAndAddResource(
                                                SAVE_TYPES.DEPRECATE
                                            )
                                        }
                                        disabled={
                                            !isResourceEdit &&
                                            !isPublishedFormValue
                                        }
                                        color="primary"
                                        variant="contained"
                                    >
                                        Zapisz i opublikuj
                                    </ProgressButton>
                                </div>
                            </Tooltip>
                        </ACL>
                        <ShowConditionally when={isResourceEdit}>
                            <Tooltip title={adminRequirementTooltips.update}>
                                <div>
                                    <ProgressButton
                                        isLoading={isResourceCreating}
                                        onClick={() =>
                                            setSaveMethodAndAddResource(
                                                SAVE_TYPES.SILENT
                                            )
                                        }
                                        color="primary"
                                        variant="contained"
                                    >
                                        Uaktualnij
                                    </ProgressButton>
                                </div>
                            </Tooltip>
                        </ShowConditionally>
                        <Tooltip title={adminRequirementTooltips.save}>
                            <div>
                                <ProgressButton
                                    isLoading={isResourceCreating}
                                    onClick={() =>
                                        setSaveMethodAndAddResource(
                                            SAVE_TYPES.NORMAL
                                        )
                                    }
                                    color="primary"
                                    variant="contained"
                                >
                                    Zapisz
                                </ProgressButton>
                            </div>
                        </Tooltip>
                    </DialogActions>
                </FormProvider>
            </form>
        </Dialog>
    ) : (
        <BackdropProgress />
    )
}

const useStyles = makeStyles(() => {
    return {
        saveMethodRadio: {
            display: 'none',
        },
        addLegalBasis: {
            position: 'absolute',
            top: 0,
            right: 10,
        },
        removeLegalBasis: {
            position: 'absolute',
            right: -10,
            top: -10,
        },
        legalBasisList: {
            marginTop: 15,
            padding: '10px 10px 0 10px',
            position: 'relative',
        },
        legalBasisItem: {
            position: 'relative',
            marginBottom: 15,
        },
        isPublishedLabel: {
            flex: 1,
            marginLeft: 5,
        },
    }
})
