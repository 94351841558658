import React from 'react'
import { useACL } from '@shared/hooks/use-acl.hook'

import { Roles } from '@shared/types'

type Props = {
  children: React.ReactElement
  allowedRoles: Roles[]
}

export function ACL(props: Props) {
  const { is: isAllowed } = useACL()

  return isAllowed(props.allowedRoles) ? props.children : null
}
