import React from 'react'
import { Chip, Grid, Typography } from '@material-ui/core'
import {
    getStatusColor,
    getStatusColorStyles,
} from '@modules/requirements/requirements.utils'
import { getCorrectFontColor } from '@shared/utils/get-correct-font-color'
import { makeStyles } from '@material-ui/core/styles'
import { AuditRequirementEnhanced, Status } from '@shared/types'
import { getStatusText, StatusText } from '@shared/utils/get-status-text'

interface Props {
    requirement: AuditRequirementEnhanced
    role: keyof StatusText
    title: string
    statuses: Status[]
}

export const StatusContainer = ({
    requirement,
    role,
    statuses,
    title,
}: Props) => {
    const classes = useStyles()

    const statusText = getStatusText(statuses || [], requirement)
    const statusColor = getStatusColor(
        statuses,
        role === 'auditor'
            ? requirement.auditorStatus.statusBaseID
            : requirement.clientStatus.statusBaseID
    )

    return (
        <Grid className={classes.statusContainer}>
            <Typography className={classes.status}>{title}</Typography>
            <Chip
                label={statusText[role]}
                style={{
                    ...getStatusColorStyles(statusColor),
                    ...getCorrectFontColor(statusText[role]),
                }}
            />
        </Grid>
    )
}

export const useStyles = makeStyles((theme) => ({
    statusContainer: {
        display: 'flex',
        paddingTop: 10,
    },
    status: {
        color: theme.palette.grey['700'],
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
        lineHeight: theme.spacing(0.25),
        marginRight: theme.spacing(1),
    },
}))
