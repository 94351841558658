import { flatten, isEmpty } from 'lodash'

export const CRITICAL_ERROR_MESSAGE =
    'Wystąpił krytyczny błąd, skontaktuj się z dostawcą aplikacji'

export function api(
    url: RequestInfo,
    body: object | string = {},
    options: RequestInit = {}
) {
    const isGetRequest = !options.method || options.method === 'GET'

    const hostname = process.env.REACT_APP_HOST || ''

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Access-Control-Allow-Credentials', 'true')

    return fetch(`${hostname}/api/${url}`, {
        headers,
        body: !isGetRequest
            ? body instanceof FormData
                ? body
                : JSON.stringify(body)
            : null,
        redirect: 'follow',
        credentials: 'include',
        ...options,
    })
}

export type NormalizedErrors = string[]

export type ErrorResponse = Record<string, any> & {
    errors?: Record<string, string | string[]>
}

export function normalizeErrors(errors: ErrorResponse['errors'] | string) {
    return typeof errors === 'string'
        ? [errors]
        : flatten(Object.values(errors!))
}

export async function apiJSON(...args: Parameters<typeof api>) {
    const response = await api(...args)

    if (response.status === 401) {
        throw normalizeErrors('Błąd autoryzacji')
    }

    if (response.status === 404) {
        throw normalizeErrors(CRITICAL_ERROR_MESSAGE)
    }

    if (response.status === 204) {
        return null
    }

    if (!response.ok) {
        console.log(response)
        const data: ErrorResponse = await response.json()
        const errors = data?.errors

        if (!isEmpty(errors)) {
            throw normalizeErrors(errors)
        } else {
            throw normalizeErrors(CRITICAL_ERROR_MESSAGE)
        }
    }

    return response.json()
}
