import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { UseFormReturn } from 'react-hook-form'
import { JSONSchema7 } from 'json-schema'
import { ProgressButton } from '@shared/components/progress-button.component'
import { apiJSON } from '@shared/utils/api/api.util'
import { LexSearchResultType, LexSearchType } from '@shared/types'
import slugify from 'slugify'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import { InfoTooltip } from '@shared/components/info-tooltip.component'

type Props = {
    formMethods: UseFormReturn<any, any>
    property: string
    propertySchema: JSONSchema7
}

function getLinkType(signature: string): string {
    if (signature.includes('dz.u.ue.')) {
        return 'dzienniki-UE'
    } else if (signature.includes('dz.u.')) {
        return 'dzu-dziennik-ustaw'
    } else if (signature.includes('m.p.')) {
        return 'mp-monitor-polski'
    }
    return ''
}

const buildLexUrl = (lexResult: LexSearchResultType) => {
    const signature = getLinkType(lexResult.signature.toLowerCase())
    const baseUrl = 'https://sip.lex.pl/akty-prawne/'
    const options = {
        lower: true,
        locale: 'pl',
        trim: true,
        remove: /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
    }

    return `${baseUrl}${signature}/${slugify(lexResult.title, options)}-${
        lexResult.nro
    }`
}

const formatNumberInput = (value: string) => {
    const polishToLatinMap: { [key: string]: string } = {
        ą: 'a',
        ć: 'c',
        ę: 'e',
        ł: 'l',
        ń: 'n',
        ó: 'o',
        ś: 's',
        ź: 'z',
        ż: 'z',
        Ą: 'A',
        Ć: 'C',
        Ę: 'E',
        Ł: 'L',
        Ń: 'N',
        Ó: 'O',
        Ś: 'S',
        Ź: 'Z',
        Ż: 'Z',
    }

    const normalizedInput = value.replace(
        /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g,
        (match) => polishToLatinMap[match]
    )
    const cleanedInput = normalizedInput.replace(/[^a-zA-Z0-9.]/g, '')

    return cleanedInput.toLowerCase().replace('t.j.', '')
}

const NUMBER_INPUT_DESCRIPTION_INFO = (
    <div>
        <p>
            Tutaj należy wpisać numer dokumentu według następujących formatów:
        </p>
        <ul>
            <li>Dz.U.xxxx.xxxx – dla Dziennika Ustaw.</li>
            <li>M.P.xxxx.xxx – dla Monitora Polskiego.</li>
            <li>
                Dz.U.UE.L.xxxx.xxxx – dla Dziennika Urzędowego Unii Europejskiej
                serii L.
            </li>
            <li>
                Dz.U.UE.C.xxxx.xxxx – dla Dziennika Urzędowego Unii Europejskiej
                serii C.
            </li>
        </ul>
        <p>Przykłady prawidłowych wpisów:</p>
        <ul>
            <li>Dz.U.2023.1465 t.j.</li>
            <li>M.P.2022.123</li>
            <li>Dz.U.UE.L.2021.4567</li>
            <li>Dz.U.UE.C.2021.1234</li>
        </ul>
        <p>
            Po wprowadzeniu numeru, kliknij przycisk <strong>“Szukaj”</strong>{' '}
            aby wyszukać odpowiedni dokument.
        </p>
    </div>
)

export function LegalBasisNumberField({
    formMethods,
    property,
    propertySchema,
}: Props) {
    const [openAlert, setOpenAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { getValues, setValue } = formMethods

    const handleSearch = async () => {
        setIsLoading(true)
        try {
            const formattedNumber = formatNumberInput(getValues(property))
            if (formattedNumber) {
                const data: LexSearchType = await apiJSON(
                    `legalbasis/search-in-lex?signature=${formattedNumber}`
                )
                if (data.results?.length) {
                    const result = data.results[0]
                    const { signature, nro, title } = result
                    setValue('documentName', signature)
                    setValue('lexApiNRO', nro)
                    setValue('details', title)
                    setValue('lexApiURL', buildLexUrl(result))
                    return
                }
            }
            setOpenAlert(true)
            setIsLoading(false)
        } catch (e) {
            setOpenAlert(true)
            console.log('Error in fetch search in lex: ', e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <TextField
                {...formMethods.register(property)}
                label={propertySchema?.description ?? property}
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <>
                            <ProgressButton
                                onClick={handleSearch}
                                isLoading={isLoading}
                                color="primary"
                                variant="contained"
                            >
                                Szukaj
                            </ProgressButton>
                            <InfoTooltip
                                interactive
                                description={NUMBER_INPUT_DESCRIPTION_INFO}
                            />
                        </>
                    ),
                }}
            />
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={() => setOpenAlert(false)}
            >
                <Alert
                    onClose={() => setOpenAlert(false)}
                    severity="error"
                    variant="filled"
                >
                    Nie znaleziono dokumentu o numerze: {getValues(property)}
                </Alert>
            </Snackbar>
        </>
    )
}
