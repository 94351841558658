import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, colors, Grid, Typography } from '@material-ui/core'
import { orderBy } from 'lodash'
import { statusColors } from '@shared/utils/status-colors'
import { useStatusesQuery } from '@shared/hooks/use-statuses-query.hook'
import { StatusTypes } from '@shared/types'

type Props = {
    isPreviewMode: boolean
    selectedStatusID: number
    setSelectedStatus: (statusID: number) => void
    isClient: boolean
    isClientEditor: boolean
    isClientEditorOrEditor: boolean
}

export const StatusSelection = ({
    isPreviewMode,
    selectedStatusID,
    setSelectedStatus,
    isClientEditorOrEditor,
    isClient,
    isClientEditor,
}: Props) => {
    const classes = useStyles()
    const { areStatusesLoading, statuses } = useStatusesQuery()

    const statusesSorted = orderBy(statuses ?? [], 'order', 'asc').filter(
        ({ label }) => !isClientEditorOrEditor || label !== StatusTypes.NoShow
    )

    const onStatusSelectionFactory = (statusID: number) => () => {
        setSelectedStatus(statusID)
    }

    if (areStatusesLoading) return null

    return (
        <>
            <Grid container className={classes.textContainer}>
                <Typography variant="h6">
                    Wskaż status {isClientEditorOrEditor ? '(Klienta)' : ''}
                </Typography>
            </Grid>
            <Grid
                container
                justify="space-between"
                className={classes.statusButtonsContainer}
            >
                {statusesSorted?.map((status) => {
                    const isSelected = selectedStatusID === status.statusID

                    const statusStyle = {
                        backgroundColor: isSelected
                            ? statusColors?.[status.label]
                            : '',
                        color: isSelected
                            ? colors.common.white
                            : statusColors?.[status.label],
                        borderColor: isSelected
                            ? ''
                            : statusColors?.[status.label],
                    }

                    const isButtonDisabled =
                        isPreviewMode ||
                        isClient ||
                        (isClientEditor &&
                            ![
                                StatusTypes.Accepted,
                                StatusTypes.NotAssessed,
                            ].includes(status.label))

                    return (
                        <Button
                            disabled={isButtonDisabled}
                            key={status.statusID}
                            style={statusStyle}
                            className={classes.statusButton}
                            onClick={onStatusSelectionFactory(status.statusID)}
                            data-status-id={status.statusID}
                        >
                            {status.name}
                        </Button>
                    )
                })}
            </Grid>
        </>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: theme.spacing(1),
        },
        title: {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(1),
        },
        breadcrumbs: {
            color: theme.palette.grey['600'],
            textTransform: 'uppercase',
            marginBottom: theme.spacing(2),
        },
        status: {
            color: theme.palette.grey['700'],
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'uppercase',
            lineHeight: theme.spacing(0.25),
            marginRight: theme.spacing(1),
        },
        statusContainer: {
            display: 'flex',
        },
        legalBasis: {
            marginLeft: theme.spacing(5),
            marginTop: theme.spacing(1),
        },
        statusButton: {
            '&.Mui-disabled': {
                opacity: 0.5,
            },
            textTransform: 'uppercase',
            border: '1px solid',
            width: 140,
            marginBottom: theme.spacing(2),
        },
        textContainer: {
            paddingTop: theme.spacing(1),
        },
        statusButtonsContainer: {
            marginTop: theme.spacing(1),
            height: 'flex',
        },
    }
})
