import {
    AuditRequirement,
    BaseRequirement,
    RequirementType,
} from '@shared/types'

export const isAuditRequirement = (
    requirement: RequirementType
): requirement is AuditRequirement & { colors: Record<string, string> } =>
    (requirement as AuditRequirement).clientStatus !== undefined &&
    (requirement as AuditRequirement).auditorStatus !== undefined

export const isBaseRequirement = (
    requirement: RequirementType
): requirement is BaseRequirement & { colors: Record<string, string> } =>
    (requirement as BaseRequirement).isPublished !== undefined
