import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useRouter } from '@shared/hooks/use-router'
import { useCompaniesAuditsQueryEndpoint } from '@shared/hooks/use-companies-audits-query.hook'
import { apiJSON } from '@shared/utils/api/api.util'

export function useAuditMutation(
    auditID: string,
    method: 'PUT' | 'DELETE',
    payload: object = {}
) {
    const { history } = useRouter()
    const [
        isConfirmAuditMutationDialogOpen,
        setConfirmAuditMutationDialogOpen,
    ] = useState(false)

    const queryClient = useQueryClient()
    const locationsEndpoint = useCompaniesAuditsQueryEndpoint()

    const { mutateAsync: mutateAudit, isLoading: isAuditMutationInProgress } =
        useMutation(() => apiJSON(`audits/${auditID}`, payload, { method }), {
            onSuccess: () => {
                queryClient.invalidateQueries(locationsEndpoint)
            },
        })

    const onAuditActionConfirmation = useCallback(
        async (confirm: boolean) => {
            if (confirm) {
                await mutateAudit()

                history.push('/')
            }

            setConfirmAuditMutationDialogOpen(false)
        },
        [setConfirmAuditMutationDialogOpen, mutateAudit, history]
    )

    const onAuditMutationStart = useCallback(async () => {
        setConfirmAuditMutationDialogOpen(true)
    }, [setConfirmAuditMutationDialogOpen])

    return {
        isConfirmAuditMutationDialogOpen,
        isAuditMutationInProgress,
        onAuditMutationStart,
        onAuditActionConfirmation,
    }
}
