import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'

import { DropzoneAreaBase, DropzoneAreaBaseProps, FileObject } from 'material-ui-dropzone'

import { apiJSON, NormalizedErrors } from '@shared/utils/api/api.util'

import { ConfirmationDialog } from './confirmation.dialog.component'
import { ErrorsAlert } from './errors-alert.component'
import { Alert } from '@material-ui/lab'

export type Props<Response> = {
  open: boolean
  acceptedFiles: string[]
  getPreviewIcon: DropzoneAreaBaseProps['getPreviewIcon']
  getEndpoint: (file: FileObject) => string
  onCleanup?: () => void
  onDialogClose?: () => void
  onAddFiles?: (data: Response[]) => void
}

export function AddFileDialog<Response = object>(props: Props<Response>) {
  const classes = useStyles()
  const [fileObjects, setFileObjects] = useState<FileObject[]>([])

  const {
    mutateAsync: addFile,
    isLoading: isAddFileLoading,
    error: addFileError,
    isSuccess: isAddFileSuccess,
    reset: resetAddFileState
  } = useMutation<Response, NormalizedErrors, FileObject, unknown>((fileObject: FileObject) => {
    const headers = new Headers()
    const formData = new FormData()

    formData.append('file', fileObject.file)

    return apiJSON(props.getEndpoint(fileObject), formData, { method: 'POST', headers })
  })

  const { onCleanup, onAddFiles, onDialogClose } = props

  const cleanup = useCallback(() => {
    resetAddFileState()
    onCleanup && onCleanup()
    setFileObjects([])
  }, [resetAddFileState, onCleanup])

  const onAddFileClose = useCallback(
    async (result: boolean) => {
      onDialogClose && onDialogClose()

      if (result && fileObjects.length > 0) {
        const addFilesResults = await Promise.all(fileObjects.map(fileObject => addFile(fileObject)))

        if (onAddFiles) {
          onAddFiles(addFilesResults)
        }
      } else {
        cleanup()
      }
    },
    [fileObjects, addFile, onAddFiles, onDialogClose, cleanup]
  )

  if (isAddFileLoading) {
    return (
      <Backdrop open>
        <CircularProgress color='inherit' />
      </Backdrop>
    )
  }

  if (isAddFileSuccess && !isAddFileLoading) {
    return (
      <Snackbar open onClose={cleanup} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity='success'>
          <p>Poprawnie dodano:</p>
          {fileObjects.map(fileObjects => fileObjects?.file.name).join(',')}
        </Alert>
      </Snackbar>
    )
  }

  if (addFileError) {
    return <ErrorsAlert errors={addFileError} onClose={resetAddFileState} />
  }

  return (
    <ConfirmationDialog
      submitButtonProps={{ children: 'Dodaj', isLoading: isAddFileLoading }}
      title='Dodaj plik'
      open={props.open}
      onClose={onAddFileClose}
      fullWidth>
      <DropzoneAreaBase
        acceptedFiles={props.acceptedFiles}
        maxFileSize={123123123} //15MB~
        dropzoneText={'Przeciągnij i upuść dokument tutaj lub kliknij w ten obszar'}
        dropzoneClass={classes.dropzoneClass}
        dropzoneParagraphClass={classes.dropzoneParagraphClass}
        fileObjects={fileObjects}
        filesLimit={10}
        onAdd={newFiles => {
          setFileObjects(prevFiles => [...prevFiles, ...newFiles])
        }}
        onDelete={deletedFile => {
          setFileObjects(prevFiles => prevFiles.filter(file => file !== deletedFile))
        }}
        getPreviewIcon={props.getPreviewIcon}
      />
    </ConfirmationDialog>
  )
}

export const useStyles = makeStyles(theme => {
  return {
    dropzoneClass: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    dropzoneParagraphClass: {
      ...theme.typography.caption
    }
  }
})
