import { Audit, AuditRequirement } from '@shared/types'
import { apiJSON } from '@shared/utils/api/api.util'
import { useMutation, useQueryClient } from 'react-query'

export function useRequirementMutation(auditID?: number) {
  const queryClient = useQueryClient()

  const { mutateAsync: updateAuditRequirement, isLoading: areRequirementsSubmitting } = useMutation(
    async (requirement: AuditRequirement) => {
      await queryClient.cancelQueries(`audits/${auditID}`)

      const previousAudit = queryClient.getQueryData<Audit>(`audits/${auditID}`)
      if (previousAudit) {
        previousAudit.auditRequirements = previousAudit.auditRequirements.map(req => req.auditRequirementID === requirement.auditRequirementID ? requirement : req)
        await queryClient.setQueryData(`audits/${auditID}`, previousAudit)
      }

      return await apiJSON(`auditrequirements/${requirement.auditRequirementID}`, requirement, { method: 'PUT' })
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(`audits/${auditID}`)
      }
    }
  )

  return { updateAuditRequirement, areRequirementsSubmitting }
}
