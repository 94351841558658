export const statusContainerContent = [
    {
        title: 'Status audytora',
        role: 'auditor',
    },
    {
        title: 'Status klienta',
        role: 'client',
    },
]
