import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, Typography } from '@material-ui/core'

import { useRouter } from '@shared/hooks/use-router'

import { AdminResourceType, Roles } from '@shared/types'
import { ACL } from '@shared/components/acl.component'

const ADMIN_MENU_LINKS = [
    { path: AdminResourceType.REQUIREMENTS, title: 'Wymagania' },
    { path: AdminResourceType.SCOPES, title: 'Zakresy' },
    { path: AdminResourceType.GROUPS, title: 'Grupy' },
    { path: AdminResourceType.COMPANIES, title: 'Lokalizacje' },
    {
        path: AdminResourceType.USERS,
        title: 'Użytkownicy',
        allowedRoles: [Roles.Admin],
    },
    { path: AdminResourceType.TEMPLATES, title: 'Szablony' },
    { path: AdminResourceType.LEGAL_BASIS, title: 'Dokumenty' },
]

export function LayoutAdminHeader() {
    const classes = useStyles()
    const { location } = useRouter()

    return (
        <Toolbar className={classes.subToolbar} variant="dense">
            {ADMIN_MENU_LINKS.map(({ path, title, allowedRoles }) => {
                const fullPath = `/admin/${path}`
                const isActive = location.pathname.includes(fullPath)
                const allowedRole = allowedRoles ?? [Roles.Admin, Roles.Auditor]

                return (
                    <ACL key={fullPath} allowedRoles={allowedRole}>
                        <Link
                            to={fullPath}
                            className={`${classes.subToolbarLink} ${
                                isActive ? classes.subToolbarLinkActive : ''
                            }`.trim()}
                        >
                            <Typography
                                className={classes.subToolbarLinkContent}
                                variant="caption"
                                color="primary"
                            >
                                {title}
                            </Typography>
                        </Link>
                    </ACL>
                )
            })}
        </Toolbar>
    )
}

export const useStyles = makeStyles((theme) => ({
    subToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.default,
    },
    subToolbarLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    subToolbarLinkActive: {
        borderBottom: `2px solid ${theme.palette.primary.light}`,
    },
    subToolbarLinkContent: {
        textDecoration: 'none',
        textTransform: 'uppercase',
    },
}))
