import React from 'react'

import { LocationDecorated } from '@shared/types'
import { useCompaniesAuditsQuery } from '@shared/hooks/use-companies-audits-query.hook'

import { SelectLocationTable } from './select-location-table.component'
import { AuditModes } from './select-location.types'

export function SelectLocationClientPage() {
  const { companiesWithAudits, areCompaniesLoading } = useCompaniesAuditsQuery()

  const userLocations: LocationDecorated[] = (companiesWithAudits ?? []).map(company => ({
    ...company,
    action: 'Podgląd'
  }))

  return <SelectLocationTable mode={AuditModes.Other} isLoading={areCompaniesLoading} companies={userLocations} />
}
