import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@material-ui/core'
import { useThemeStyles } from '@shared/theme/use-theme-styles'

type Props = DialogProps & {
    isSubmitDisabled: boolean
    onClose: (result: boolean) => void
    onReset: () => void
}

export function RequirementAddPhotoDialog({
    isSubmitDisabled,
    onClose,
    onReset,
    open,
    children,
    ...dialogProps
}: Props) {
    const classes = useThemeStyles()

    return (
        <Dialog open={open} {...dialogProps}>
            <DialogTitle
                className={classes.dialogTitle}
                id="confirmation-dialog-title"
            >
                Dodaj zdjęcie
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        onClose(false)
                    }}
                    color="primary"
                    variant="outlined"
                >
                    Anuluj
                </Button>
                <Button onClick={onReset} color="primary" variant="contained">
                    Wyczyść
                </Button>
                <Button
                    disabled={isSubmitDisabled}
                    onClick={() => {
                        onClose(true)
                    }}
                    color="primary"
                    variant="contained"
                >
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    )
}
