import React, { useCallback, useEffect, useState } from 'react'
import {
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'
import { SummaryTemplate, Template } from '@shared/types'
import { useRouter } from '@shared/hooks/use-router'
import { makeStyles } from '@material-ui/core/styles'
import { originalIcons } from '@shared/components/icons/icons'
import { useMutation, useQueryClient } from 'react-query'
import { apiJSON } from '@shared/utils/api/api.util'
import { SummaryTemplateSelect } from '@modules/admin-generic/summary-template-select.component'

type TemplateSummaryFormData = {
    descriptionTemplateID?: number
    description: string
}

type RouteQuery = {
    resourceID: string
}

interface Props {
    templateData: Template
    endpoint: string
}

export const AdminGenericCrudSummaryTemplateComponent = ({
    templateData,
    endpoint,
}: Props) => {
    const queryClient = useQueryClient()
    const classes = useStyles()
    const { query } = useRouter<RouteQuery>()

    const isEditing = Boolean(query.resourceID)

    const [creationSectionVisible, setCreationSectionVisible] = useState(false)
    const [isCreateButtonDisabled, setIsCreationButtonDisabled] = useState(true)

    const [newTemplate, setNewTemplate] =
        useState<TemplateSummaryFormData | null>(null)
    const [selectedTemplate, setSelectedTemplate] =
        useState<SummaryTemplate | null>(null)
    const [templates, setTemplates] = useState<SummaryTemplate[]>([])

    useEffect(() => {
        if (!newTemplate?.description) setIsCreationButtonDisabled(true)
        if (newTemplate)
            setIsCreationButtonDisabled(newTemplate?.description.length === 0)
    }, [newTemplate, newTemplate?.description.length])

    useEffect(() => {
        if (templateData && templateData.summaryTemplates)
            setTemplates(templateData.summaryTemplates)
    }, [templateData])

    const {
        mutateAsync: createSummaryTemplate,
        isLoading: isTemplateCreating,
    } = useMutation(
        (template: TemplateSummaryFormData) =>
            apiJSON(`templates/crudSummary`, template, { method: 'POST' }),
        {
            onSuccess: () => queryClient.invalidateQueries(endpoint),
        }
    )

    const {
        mutateAsync: deleteSummaryTemplate,
        isLoading: isTemplateDeleting,
    } = useMutation(
        (id: number) =>
            apiJSON(`templates/${id}/crudSummary`, {}, { method: 'DELETE' }),
        {
            onSuccess: () => queryClient.invalidateQueries(endpoint),
        }
    )

    const { mutateAsync: editSummaryTemplate, isLoading: isTemplateEditing } =
        useMutation(
            (template: SummaryTemplate) =>
                apiJSON(
                    `templates/${selectedTemplate?.summaryTemplateID}/crudSummary`,
                    template,
                    {
                        method: 'PUT',
                    }
                ),
            {
                onSuccess: () => queryClient.invalidateQueries(endpoint),
            }
        )

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const properTemplate = templates.find(
            (template) =>
                template.summaryTemplateID === +(event.target.value as string)
        )
        setSelectedTemplate(properTemplate || null)
    }

    const handleChangeSelectedTemplate = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (selectedTemplate) {
            const editedTemplate = {
                ...selectedTemplate,
                description: event.target.value,
            }
            setSelectedTemplate(editedTemplate)
        }
    }

    const handleChangeCreatedTemplate = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newTemplate = {
            description: event.target.value,
            descriptionTemplateID: +query.resourceID || 0,
        }
        setNewTemplate(newTemplate)
    }

    const onCreateNewTemplate = async () => {
        if (newTemplate && newTemplate.description.length > 0) {
            await createSummaryTemplate(newTemplate)
            setCreationSectionVisible(false)
            setNewTemplate(null)
        }
    }

    const onEditTemplate = async () => {
        if (selectedTemplate) {
            await editSummaryTemplate(selectedTemplate)
            setSelectedTemplate(null)
        }
    }

    const onDeleteTemplate = async () => {
        if (selectedTemplate) {
            const filteredTemplates = templates.filter(
                (template) =>
                    template.summaryTemplateID !==
                    selectedTemplate.summaryTemplateID
            )
            setTemplates(filteredTemplates)
            await deleteSummaryTemplate(selectedTemplate.summaryTemplateID)
            setSelectedTemplate(null)
        }
    }

    const onToggleCreatingSection = () => {
        setCreationSectionVisible(!creationSectionVisible)
        setSelectedTemplate(null)
    }

    const displayedName = useCallback(() => {
        const indexOfSelected = templates.findIndex(
            (element) =>
                element.summaryTemplateID ===
                selectedTemplate?.summaryTemplateID
        )

        if (indexOfSelected === -1) return 'Wybierz szablon podsumowania'
        if (templates[indexOfSelected].description.length > 50) {
            return `${templates[indexOfSelected].description.slice(0, 50)}...`
        }
        return templates[indexOfSelected].description
    }, [selectedTemplate?.summaryTemplateID, templates])

    if (!isEditing) return null

    return (
        <Grid container>
            <Grid container className={classes.title} justify="space-between">
                <Typography>Szablony podsumowania</Typography>
                <IconButton
                    className={classes.icon}
                    onClick={onToggleCreatingSection}
                >
                    {creationSectionVisible ? (
                        <originalIcons.Close />
                    ) : (
                        <originalIcons.Add />
                    )}
                </IconButton>
            </Grid>
            {creationSectionVisible && (
                <Grid container className={classes.input}>
                    <Grid item xs={11}>
                        <TextField
                            onChange={handleChangeCreatedTemplate}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            rowsMax="Infinity"
                            value={newTemplate?.description}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} container justify="flex-end">
                        <IconButton
                            onClick={onCreateNewTemplate}
                            className={classes.icon}
                            disabled={isCreateButtonDisabled}
                        >
                            {isTemplateCreating ? (
                                <CircularProgress />
                            ) : (
                                <originalIcons.Check />
                            )}
                        </IconButton>
                    </Grid>
                </Grid>
            )}
            {templates.length > 0 && !creationSectionVisible && (
                <SummaryTemplateSelect
                    selectedTemplate={selectedTemplate}
                    handleChange={handleChange}
                    displayedName={displayedName}
                    templates={templates}
                    onEditTemplate={onEditTemplate}
                    isTemplateEditing={isTemplateEditing}
                    isTemplateDeleting={isTemplateDeleting}
                    onDeleteTemplate={onDeleteTemplate}
                    handleChangeSelectedTemplate={handleChangeSelectedTemplate}
                />
            )}
        </Grid>
    )
}

export const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(1),
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    icon: {
        padding: 0,
        marginLeft: theme.spacing(2),
    },
}))
