import React, { createContext, useContext, useReducer } from 'react'
import { State, Action } from '../lib/reducer'

type StoreProviderProps = {
  children: React.ReactNode
  initialState: State
  reducer: React.Reducer<State, Action>
}

const Store = createContext({} as any)

export const useStore = () => useContext(Store)

export const StoreProvider = ({ children, initialState, reducer }: StoreProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
}
