import { useAuth } from '@modules/auth/use-auth.hook'
import { Roles } from '@shared/types'

export function useACL() {
  const { authenticatedUser } = useAuth()

  return {
    is(roles: Roles[]) {
      return Boolean(authenticatedUser?.userRoles.map(({ roleName }) => roleName).some(userRoleName => roles.includes(userRoleName)))
    }
  }
}
