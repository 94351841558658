import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { debounce, throttle } from 'lodash'
import { getPersistKey } from '../utils/get-persist-key'
import { useStore } from '@shared/hooks/use-store.hook'

export const SCROLL_KEY_PREFIX = 'scroll'

export function usePersistedScroll({ key, enabled }: { key?: string; enabled?: boolean }) {
  const mainRef = useRef<HTMLElement>()
  const isDOMReady = Boolean(mainRef.current)
  const shouldResetScroll = useRef<boolean>(false)
  const {
    state: { currentPathBase, prevPathBase }
  } = useStore()

  useLayoutEffect(() => {
    mainRef.current = document.querySelector('main') as HTMLElement

    const observer = new MutationObserver(
      throttle(() => {
        observer.disconnect()

        if (enabled) {
          mainRef.current?.scrollTo({
            left: 0,
            top:
              currentPathBase !== prevPathBase ? 0 : JSON.parse((key && localStorage.getItem(getPersistKey(key, SCROLL_KEY_PREFIX))) ?? '0')
          })
        }
      }, 500)
    )

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  }, [enabled, key, currentPathBase, prevPathBase])

  const handleScroll = useMemo(
    () =>
      debounce(e => {
        if (enabled && key && e.target.scrollTop > 0) {
          localStorage.setItem(getPersistKey(key, SCROLL_KEY_PREFIX), JSON.stringify(e.target.scrollTop))
        }
      }, 200),
    [enabled, key]
  )

  useLayoutEffect(() => {
    mainRef.current?.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      mainRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [enabled, key, handleScroll, mainRef, isDOMReady])

  const resetScroll = useCallback(() => {
    mainRef.current?.scrollTo({
      left: 0,
      top: 0
    })

    if (enabled && key) {
      localStorage.setItem(getPersistKey(key, SCROLL_KEY_PREFIX), '0')
    }

    shouldResetScroll.current = false
  }, [shouldResetScroll, mainRef, enabled, key])

  return resetScroll
}
