import { JSONSchema7 } from 'json-schema'
import { useQuery } from 'react-query'
import { apiJSON } from '@shared/utils/api/api.util'

export type SchemaPayload = {
  components?: {
    schemas?: { [key: string]: JSONSchema7 }
  }
}

export function useSchemasQuery() {
  const { data: schema } = useQuery<SchemaPayload>('schema', () => apiJSON('schema'))

  return { schema }
}
