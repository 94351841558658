import React, { FC, useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Container,
    IconButton,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'
import {
    RegisteredStatus,
    RequirementData,
} from '@modules/requirement/requirement-table/requirements-table.component'
import { AuditRequirement, Roles } from '@shared/types'
import { useStore } from '@shared/hooks/use-store.hook'
import { Actions, ViewRowTableField } from '@shared/lib/reducer'
import { ACL } from '@shared/components/acl.component'
import { ShowConditionally } from '@shared/components/show-conditionally.component'

interface Props {
    registeredStatuses: RegisteredStatus[]
    activeFilters: number[]
    setActiveFilters: (value: number[]) => void
    setFilteredData: (value: RequirementData[] | null) => void
    data: RequirementData[]
    initiallyCollapsedWithButtons?: boolean
    expandAll: () => void
    collapseAll: () => void
    isExpanded: boolean
    isPreviewMode?: boolean
}

const isDev =
    window.location.origin.includes(
        'https://dev-ecoms-audyty.azurewebsites.net'
    ) || process.env.NODE_ENV === 'development'

const INDICATOR_HEIGHT = 44
const HEADER_HEIGHT = 64
const TOP_EDGE = isDev ? INDICATOR_HEIGHT + HEADER_HEIGHT : HEADER_HEIGHT

export const FilterStatusesComponent: FC<Props> = ({
    registeredStatuses,
    setFilteredData,
    data,
    activeFilters,
    setActiveFilters,
    isExpanded,
    initiallyCollapsedWithButtons,
    expandAll,
    collapseAll,
    isPreviewMode,
}) => {
    const { state, dispatch } = useStore()
    const viewRowTableFields = state.viewRowTableFields as ViewRowTableField[]
    const classes = useStyles()
    const triggerRef = useRef<HTMLDivElement>(null)
    const [isHideFloating, setIsHideFloating] = useState(true)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClickOpenViewOptions = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseViewOptions = () => {
        setAnchorEl(null)
    }

    const switchFilter = (filterId: number | null) => {
        if (filterId) {
            const newFilters = activeFilters.includes(filterId)
                ? [...activeFilters.filter((id) => id !== filterId)]
                : [...activeFilters, filterId]
            setActiveFilters(newFilters)

            if (newFilters.length < 1) return setFilteredData(null)

            const updatedData = data.filter(
                (requirement) =>
                    newFilters.includes(
                        (requirement as AuditRequirement).auditorStatus
                            .statusBaseID
                    ) &&
                    (state.isDraftFilterActive
                        ? (requirement as AuditRequirement).isDraft
                        : true)
            )

            return setFilteredData(updatedData)
        }
        setActiveFilters([])
        setFilteredData(null)
    }

    const toggleViewRowTableField = (property: string) => {
        dispatch({
            type: Actions.TOGGLE_VIEW_ROW_TABLE_FIELD,
            payload: { property },
        })
    }

    const toggleFilterDrafts = () => {
        dispatch({ type: Actions.TOGGLE_FILTER_DRAFTS })
        if (!state.isDraftFilterActive) {
            expandAll()
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries
                setIsHideFloating(entry.isIntersecting)
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        )

        const currentTrigger = triggerRef.current
        if (currentTrigger) {
            observer.observe(currentTrigger)
        }

        return () => {
            if (currentTrigger) {
                observer.unobserve(currentTrigger)
            }
        }
    }, [])

    if (registeredStatuses.length === 0) return null

    const filterBar = (
        <div className={classes.barWrapper}>
            <div className={classes.filtersButtonsWrapper}>
                <span className={classes.filtersTitle}>
                    Filtruj wg statusu audytora:
                </span>
                {registeredStatuses
                    ?.sort((a, b) => {
                        if (a.statusBaseID < b.statusBaseID) return -1
                        if (a.statusBaseID > b.statusBaseID) return 1
                        return 0
                    })
                    .map(({ statusBaseID, statusColor, name }) => (
                        <Tooltip title={name.toUpperCase()} key={statusBaseID}>
                            <div
                                onClick={() => switchFilter(statusBaseID)}
                                className={classes.colorCircleBtn}
                                style={{
                                    backgroundColor: statusColor,
                                    border: '1px solid #000',
                                    boxShadow: activeFilters.includes(
                                        statusBaseID
                                    )
                                        ? '0px 0px 0px 2px #000'
                                        : 'none',
                                }}
                            />
                        </Tooltip>
                    ))}
                <Tooltip title="Usuń filtry">
                    <IconButton
                        className={classes.refreshButton}
                        onClick={() => switchFilter(null)}
                    >
                        <originalIcons.Close />
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.buttonsWrapper}>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    onClick={handleClickOpenViewOptions}
                >
                    Widok
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseViewOptions}
                    style={{ transform: 'translateY(60px)' }}
                >
                    {viewRowTableFields.map(({ property, label, selected }) => (
                        <MenuItem
                            key={property}
                            value={property}
                            onClick={() => toggleViewRowTableField(property)}
                        >
                            <Checkbox checked={selected} color="primary" />
                            <ListItemText
                                primary={label}
                                classes={{ primary: classes.listItemText }}
                            />
                        </MenuItem>
                    ))}
                </Menu>
                <ShowConditionally when={!isPreviewMode}>
                    <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                        <div className={classes.collapseButtons}>
                            <Button
                                variant="outlined"
                                onClick={toggleFilterDrafts}
                            >
                                {state.isDraftFilterActive
                                    ? 'Pokaż wszystko'
                                    : 'Pokaż drafty'}
                            </Button>
                        </div>
                    </ACL>
                </ShowConditionally>
                {initiallyCollapsedWithButtons ? (
                    <div className={classes.collapseButtons}>
                        <Button
                            variant="outlined"
                            onClick={isExpanded ? collapseAll : expandAll}
                        >
                            {isExpanded ? 'Zwiń wszystkie' : 'Rozwiń wszystkie'}
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
    )

    return (
        <>
            <div className={classes.filtersWrapper} ref={triggerRef}>
                {filterBar}
            </div>
            {!isHideFloating && (
                <Box className={classes.floatingFiltersContainer} boxShadow={1}>
                    <Container
                        maxWidth="xl"
                        className={classes.floatingFilters}
                    >
                        {filterBar}
                    </Container>
                </Box>
            )}
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    barWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    listItemText: {
        fontSize: '0.8rem',
    },
    filtersWrapper: {
        display: 'flex',
        padding: '10px',
        paddingLeft: '24px',
        paddingTop: 0,
        marginTop: '-10px',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersTitle: {
        color: theme.palette.text.disabled,
    },
    filtersButtonsWrapper: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
    },
    refreshButton: {
        marginLeft: -10,
        alignItems: 'center',
        cursor: 'pointer',
    },
    colorCircleBtn: {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        border: '1px solid #fff',
        cursor: 'pointer',
    },
    collapseButtons: {},
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
    },
    floatingFiltersContainer: {
        position: 'fixed',
        top: TOP_EDGE,
        backgroundColor: theme.palette.background.paper,
        zIndex: 9999,
        left: 0,
        right: 0,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
        },
    },
    floatingFilters: {
        display: 'flex',
        paddingLeft: 40,
        paddingRight: 50,
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))
