import React from 'react'

import { PieSvgProps, ResponsivePie } from '@nivo/pie'

type Props = {
  data: PieSvgProps<unknown>['data']
}

export function ClientAuditStatsChart(props: Props) {
  return (
    <div style={{ height: 220, width: 220 }}>
      <ResponsivePie
        data={props.data}
        margin={{ bottom: 20 }}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor='#333333'
        radialLabelsLinkColor={{ from: 'color' }}
        enableSliceLabels={false}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor='#333333'
        isInteractive={false}
      />
    </div>
  )
}
