import { useCompanyQuery } from '@shared/hooks/use-company-query.hook'
import { useScopesQuery } from '@shared/hooks/use-scopes-query.hook'
import { useStatusesQuery } from '@shared/hooks/use-statuses-query.hook'

type Props = {
  companyID: number
}

export function AuditCacheWarmup(props: Props) {
  useScopesQuery()
  useStatusesQuery()
  useCompanyQuery(props.companyID)

  return null
}
