import { AuditRequirements } from '@shared/types'
import { NO_REFETCH_OPTIONS } from '@shared/utils/api/api.consts'
import { apiJSON } from '@shared/utils/api/api.util'
import { useQuery } from 'react-query'

export function useSynchronizedAuditRequirements(auditID: string) {
  const { data, refetch, isLoading } = useQuery<AuditRequirements>(
    `audits/${auditID}/auditrequirements?synchronize=true`,
    () => apiJSON(`audits/${auditID}/auditrequirements?synchronize=true`),
    {
      enabled: false,
      cacheTime: 0,
      ...NO_REFETCH_OPTIONS
    }
  )

  return {
    synchronizedAuditRequirements: data,
    refetchRequirements: refetch,
    areSynchronizedRequirementsRefetching: isLoading
  }
}
