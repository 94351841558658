import { useState } from 'react'
import { BaseRequirementLegalBasis } from '@shared/types'
import { flatten, groupBy, map, uniq } from 'lodash'

export interface LegalReference {
    type: 'art' | '§'
    numbers: string[]
}

const MAX_VALUE_IN_RANGE = 100

function parseRange(range: string): string[] {
    const [start, end] = range.split('-')

    if (end !== undefined) {
        const startNum = parseInt(start, 10)
        const endNum = Math.min(parseInt(end, 10), MAX_VALUE_IN_RANGE)

        return Array.from({ length: endNum - startNum + 1 }, (_, i) =>
            (startNum + i).toString()
        ).filter((num) => parseInt(num, 10) <= MAX_VALUE_IN_RANGE)
    } else {
        return [start]
    }
}

export function parseLegalInput(input: string): LegalReference[] {
    const result: LegalReference[] = []
    input = input.replace(/\s+/g, '').toLowerCase()

    const articleRegex =
        /art\.(\d+[a-z]?(\^\d+[a-z]?)?(-\d+[a-z]?(\^\d+[a-z]?)?)?)(,art\.(\d+[a-z]?(\^\d+[a-z]?)?(-\d+[a-z]?(\^\d+[a-z]?)?)?))*/g
    const paragraphRegex =
        /§(\d+[a-z]?(\^\d+[a-z]?)?(-\d+[a-z]?(\^\d+[a-z]?)?)?)(,§(\d+[a-z]?(\^\d+[a-z]?)?(-\d+[a-z]?(\^\d+[a-z]?)?)?))*/g

    function processMatches(
        matches: RegExpMatchArray | null,
        type: 'art' | '§'
    ) {
        if (matches) {
            for (const match of matches) {
                const ranges = match.match(/(\d+[a-z]?(\^\d+[a-z]?)?(-\d+)?)/g);
                if (ranges) {
                    const numbers = ranges.flatMap(parseRange)
                    result.push({ type, numbers })
                }
            }
        }
    }

    const articleMatches = input.match(articleRegex)
    processMatches(articleMatches, 'art')

    const paragraphMatches = input.match(paragraphRegex)
    processMatches(paragraphMatches, '§')

    return map(groupBy(result, 'type'), (items, type) => ({
        type,
        numbers: uniq(flatten(items.map((item) => item.numbers))).sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true })
        ),
    })) as LegalReference[]
}

export default function generateParagraphLinks(
    input: string,
    mainUrl: string
): string[] {
    const result: string[] = []
    const legalReferences = parseLegalInput(input)

    if (!mainUrl) {
        return []
    }

    legalReferences.forEach(({ type, numbers }) => {
        const suffix = type === 'art' ? 'art' : 'par'
        numbers.forEach((number) => {
            // Dodanie myślników między cyframi a literkami oraz obsługa przypisów górnych
            const formattedNumber = number
                .replace(/(\d+)\^(\d+)([a-z])?/gi, (_, p1, p2, p3) =>
                    p3 ? `${p1}-${p2}-${p3}` : `${p1}-${p2}`
                )
                .replace(/(\d+)([a-z])/gi, '$1-$2')
            result.push(`${mainUrl}/${suffix}-${formattedNumber}`)
        })
    })

    return result
}

interface LegalBasisLinks {
    index: number
    links: string[]
}

interface useGenerateParagraphLinksReturn {
    legalBasisLinks: LegalBasisLinks[]
    handleGenerateLinks: (legalBasisValues: BaseRequirementLegalBasis[]) => void
}

export function useGenerateParagraphLinks(): useGenerateParagraphLinksReturn {
    const [links, setLinks] = useState<LegalBasisLinks[]>([])

    const handleGenerateLinks = (
        legalBasisValues: BaseRequirementLegalBasis[]
    ) => {
        setLinks(
            legalBasisValues.map(
                (value, index): LegalBasisLinks => ({
                    index,
                    links: generateParagraphLinks(
                        value.paragraph || '',
                        value.legalBasis?.lexApiURL || ''
                    ),
                })
            )
        )
    }

    return {
        legalBasisLinks: links,
        handleGenerateLinks,
    }
}
