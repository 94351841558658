import React, { useEffect } from 'react'
import {
    Box,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { LegalActStrings } from '@shared/consts'
import { makeStyles } from '@material-ui/core/styles'
import {
    AuditRequirementEnhanced,
    BaseRequirementLegalBasis,
} from '@shared/types'
import { useGenerateParagraphLinks } from '@modules/admin-requirements/use-generate-paragraph-links'
import LinkStatus from '@modules/admin-requirements/link-status'

interface Props {
    requirement: AuditRequirementEnhanced
}

export const FormLegalBasis = ({ requirement }: Props) => {
    const classes = useStyles()
    const { legalBasisLinks, handleGenerateLinks } = useGenerateParagraphLinks()

    useEffect(() => {
        handleGenerateLinks(
            requirement.legalBasis.map(
                (value) =>
                    ({
                        legalBasis: value,
                        paragraph: value.paragraph,
                    } as BaseRequirementLegalBasis)
            )
        )
    }, [requirement.legalBasis])

    return (
        <TableContainer className={classes.legalBasisTable}>
            <Table>
                <TableHead>
                    <TableRow>
                        {Object.keys(LegalActStrings).map((string) => {
                            const content =
                                LegalActStrings[
                                    string as keyof typeof LegalActStrings
                                ]

                            return (
                                <TableCell key={string} size="small">
                                    {content}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requirement.legalBasis.map((legalBasis, index) => {
                        const { documentName, paragraph, details, lexApiURL } =
                            legalBasis

                        return (
                            <TableRow key={legalBasis.documentName}>
                                {[documentName, paragraph, details].map(
                                    (basis, i, array) => {
                                        const isParagraphColumn = i === 1
                                        const isDetails = i === 2
                                        const cellClasses =
                                            i !== array.length - 1
                                                ? classes.legalBassisNoWrapCell
                                                : ''
                                        const links = isParagraphColumn
                                            ? legalBasisLinks.find(
                                                  (legalBasisLink) =>
                                                      legalBasisLink.index ===
                                                      index
                                              )
                                            : undefined

                                        return (
                                            <TableCell
                                                size="medium"
                                                className={cellClasses}
                                            >
                                                {isParagraphColumn ? (
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography>
                                                            {lexApiURL
                                                                ? links?.links?.map(
                                                                      (
                                                                          link
                                                                      ) => (
                                                                          <React.Fragment
                                                                              key={
                                                                                  link
                                                                              }
                                                                          >
                                                                              <LinkStatus
                                                                                  link={
                                                                                      link
                                                                                  }
                                                                                  isColumnView
                                                                              />{' '}
                                                                          </React.Fragment>
                                                                      )
                                                                  )
                                                                : basis}
                                                        </Typography>
                                                    </Box>
                                                ) : isDetails && lexApiURL ? (
                                                    <Link
                                                        href={lexApiURL}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        {basis}
                                                    </Link>
                                                ) : (
                                                    basis
                                                )}
                                            </TableCell>
                                        )
                                    }
                                )}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        legalBasisTable: {
            marginBottom: theme.spacing(2),
            '& td, th': {
                paddingLeft: '0',
                fontSize: '1rem',
            },
        },
        legalBassisNoWrapCell: {
            width: 140,
        },
    }
})
