import React from 'react'

import { Table } from '@shared/components/table/table.component'
import { TableActionLink } from '@shared/components/table/table-action-link.component'
import { LocationDecorated, Roles } from '@shared/types'

import { AuditModes } from './select-location.types'
import { ACL } from '@shared/components/acl.component'
import { ShowConditionally } from '../../shared/components/show-conditionally.component'

type Props = {
  isLoading: boolean
  companies: LocationDecorated[]
  mode: AuditModes
}

export function SelectLocationTable(props: Props) {
  const isInProgressMode = props.mode === AuditModes.InProgress

  return (
    <Table<LocationDecorated>
      isLoading={props.isLoading}
      title='Wybierz lokalizację'
      columns={[
        {
          title: 'Lokalizacja',
          field: 'name'
        },
        {
          title: 'Adres',
          field: isInProgressMode ? 'lastActiveAuditLocalization' : 'lastClosedAuditLocalization'
        },
        {
          title: 'Akcje',
          render: (company: LocationDecorated) => {
            return (
              <>
                <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                  <TableActionLink
                    to={
                      company.lastActiveAuditID
                        ? `/audit/${company.companyID}/${company.lastActiveAuditID}`
                        : `/client/${company.companyID}`
                    }>
                    {company.action}
                  </TableActionLink>
                </ACL>
                <ShowConditionally when={Boolean(company?.lastClosedAuditID)}>
                  <TableActionLink to={`/audit/${company.companyID}/last`}>Podgląd</TableActionLink>
                </ShowConditionally>
              </>
            )
          }
        }
      ].filter(Boolean)}
      data={props.companies}
    />
  )
}
