import React, { useCallback, useEffect, useState } from 'react'
import {
    Avatar,
    Badge,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { PREVIEW_AUDIT_ID } from '@shared/consts'
import { format } from 'date-fns'
import { ACL } from '@shared/components/acl.component'
import { ShowConditionally } from '@shared/components/show-conditionally.component'
import { Link } from 'react-router-dom'
import {
    Audit,
    AuditRequirement,
    AuditRequirementEnhanced,
    AuditRequirementImage,
    Roles,
    StatusKeyTypes,
    Template,
} from '@shared/types'
import { ProgressButton } from '@shared/components/progress-button.component'
import { makeStyles } from '@material-ui/core/styles'
import { useStatusesQuery } from '@shared/hooks/use-statuses-query.hook'
import { useRouter } from '@shared/hooks/use-router'
import { useFormContext } from 'react-hook-form'
import {
    OfflineEntityType,
    useOfflineContent,
} from '@shared/hooks/use-offline-content'
import { omit } from 'lodash'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { apiJSON } from '@shared/utils/api/api.util'
import { useRequirementMutation } from '@shared/hooks/use-requirement-mutation.hook'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import { getConnectedAuditRequirements } from '@shared/utils/get-connected-audit-requirements'
import { useButtonsRestrictions } from '@shared/hooks/use-buttons-restrictions'
import { FormSection } from '@modules/requirement/requirement-form/form-section.component'
import { FormDetails } from '@modules/requirement/requirement-form/form-details.component'
import { FormLegalBasis } from '@modules/requirement/requirement-form/form-legal-basis.component'
import {
    FormStatusDetails,
    RequirementDetailsFormData,
} from '@modules/requirement/requirement-form/form-status-details.component'
import { StatusSelection } from '@modules/requirement/requirement-form/status-selection.component'
import { FormStatusSummary } from '@modules/requirement/requirement-form/form-status-summary.component'
import { FormSectionContent } from '@modules/requirement/requirement-form/form-section-content.component'
import { registerInputRefFactory } from '@modules/admin-generic/admin-generic.utils'

type TemplateFormData = {
    description: Template['description']
    requirementBaseID: Template['requirementBase']['requirementBaseID']
    statusID: Template['status']['statusID']
    descriptionTemplateID?: number
    summaryTemplates?: TemplateSummaryFormData[]
}

type TemplateSummaryFormData = {
    descriptionTemplateID?: number
    description: string
}

type RouteQuery = {
    companyID: string
    auditID: string
    auditRequirementID: string
}
type RouteLocation = {
    requirement: AuditRequirementEnhanced
    audit: Audit
    isPreviewMode: boolean
}

interface Props {
    onImagePreviewOpenFactory: (
        image: AuditRequirementImage
    ) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
    isClient: boolean
    isClientEditor: boolean
    isClientOrClientEditor: boolean
    onTakePhotoDialogOpen: () => void
    onUploadPhotoDialogOpen: () => void
    photos: AuditRequirementImage[]
    removeImageMutation: UseMutationResult<any, unknown, React.ReactText>
    onPhotoRemoveFactory: (
        photoToRemove: AuditRequirementImage
    ) => () => Promise<void>
}

export const RequirementForm = ({
    onImagePreviewOpenFactory,
    isClient,
    isClientEditor,
    isClientOrClientEditor,
    onTakePhotoDialogOpen,
    onPhotoRemoveFactory,
    onUploadPhotoDialogOpen,
    photos,
    removeImageMutation,
}: Props) => {
    const queryClient = useQueryClient()
    const { isOnline, isOffline } = useNavigatorOnline()

    const classes = useStyles()

    const { statuses } = useStatusesQuery()
    const offlineContent = useOfflineContent()
    const [isSavingDraft, setIsSavingDraft] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState<Template>()

    const { query, history, location } = useRouter<RouteQuery, RouteLocation>()

    const { companyID, auditID } = query
    const parentAuditURL = `/audit/${companyID}/${auditID}`
    const { requirement, audit } = location.state ?? {}

    const isPreviewModeWithoutRoles = auditID === PREVIEW_AUDIT_ID

    useEffect(() => {
        if (!location.state) history.replace(parentAuditURL)
    }, [location.state, history, parentAuditURL])

    const { register, handleSubmit, control } =
        useFormContext<RequirementDetailsFormData>()

    const registerInputRef = registerInputRefFactory(register)

    const connectedAuditRequirements = getConnectedAuditRequirements(
        audit,
        requirement
    )

    const allowedRequirementPreviewRoles = [
        Roles.Client,
        Roles.ClientEditor,
        ...(location.state.isPreviewMode ? [Roles.Admin, Roles.Auditor] : []),
    ]

    const statusKey =
        location.state.isPreviewMode || isClientOrClientEditor
            ? StatusKeyTypes.CLIENT_STATUS
            : StatusKeyTypes.AUDITOR_STATUS

    const {
        hideTooltip,
        isSaveDisabled,
        isStatusNew,
        isAuditorAssessmentEmpty,
        isSummaryEmpty,
        selectedStatusID,
        setSelectedStatusID,
    } = useButtonsRestrictions(
        requirement?.[statusKey]?.statusBaseID || 0,
        control
    )

    const [tooltipMessage, setTooltipMessage] = useState('')

    useEffect(() => {
        if (hideTooltip || !isAuditorAssessmentEmpty) setTooltipMessage('')
        else if (isAuditorAssessmentEmpty)
            setTooltipMessage(`Pole "Realizacja wymagania" nie może być puste.`)
        if (isStatusNew) setTooltipMessage('Należy ustawić odpowiedni status')
    }, [isAuditorAssessmentEmpty, isStatusNew, hideTooltip, selectedStatusID])

    const { updateAuditRequirement, areRequirementsSubmitting } =
        useRequirementMutation(+auditID)

    const { mutateAsync: createTemplate, isLoading: isTemplateCreating } =
        useMutation(
            (template: TemplateFormData) =>
                apiJSON(`templates`, template, { method: 'POST' }),
            {
                onSuccess: () => queryClient.invalidateQueries('templates'),
            }
        )

    const { mutateAsync: createSummaryTemplate } = useMutation(
        (template: TemplateSummaryFormData) =>
            apiJSON(`templates/crudSummary`, template, { method: 'POST' }),
        {
            onSuccess: () => queryClient.invalidateQueries('templates'),
        }
    )

    const createNewTemplate = useCallback(
        async (auditorAssessment: string) => {
            const newTemplate: TemplateFormData = {
                description: auditorAssessment,
                statusID: selectedStatusID,
                requirementBaseID: requirement.requirementBaseID,
                descriptionTemplateID:
                    selectedTemplate && selectedTemplate.descriptionTemplateID,
            }

            offlineContent.set(OfflineEntityType.Template, newTemplate)

            await createTemplate(newTemplate)
        },
        [
            createTemplate,
            offlineContent,
            requirement.requirementBaseID,
            selectedStatusID,
            selectedTemplate,
        ]
    )

    const addSummaryToExistingTemplate = useCallback(
        async (summary: string) => {
            const newTemplate = {
                description: summary,
                descriptionTemplateID: selectedTemplate?.descriptionTemplateID,
            }

            offlineContent.set(OfflineEntityType.Template, newTemplate)

            await createSummaryTemplate(newTemplate)
        },
        [
            createSummaryTemplate,
            offlineContent,
            selectedTemplate?.descriptionTemplateID,
        ]
    )

    const createNewTemplateWithSummary = useCallback(
        async (auditorAssessment: string, summary: string) => {
            const newTemplate = {
                description: auditorAssessment,
                statusID: selectedStatusID,
                requirementBaseID: requirement.requirementBaseID,
                summaryTemplates: [
                    {
                        description: summary,
                    },
                ],
            }

            offlineContent.set(OfflineEntityType.Template, newTemplate)

            await createTemplate(newTemplate)
        },
        [
            createTemplate,
            offlineContent,
            requirement.requirementBaseID,
            selectedStatusID,
        ]
    )

    const onRequirementSubmit = useCallback(
        async (formData: RequirementDetailsFormData, isDraft: boolean) => {
            const {
                summary,
                fixDate,
                auditorAssessment,
                clientComment,
                saveAsTemplate,
                saveAsTemplateEnhanced,
                connectedAuditRequirements,
            } = formData

            setIsSavingDraft(isDraft)

            const isEnhancedTemplate =
                saveAsTemplateEnhanced ||
                (saveAsTemplate && saveAsTemplateEnhanced)

            const isAssessmentChanged =
                selectedTemplate?.description === auditorAssessment

            try {
                if (!isDraft) {
                    if (isEnhancedTemplate) {
                        if (selectedTemplate) {
                            if (isAssessmentChanged) {
                                await addSummaryToExistingTemplate(summary)
                            } else {
                                await createNewTemplateWithSummary(
                                    auditorAssessment,
                                    summary
                                )
                            }
                        } else {
                            await createNewTemplateWithSummary(
                                auditorAssessment,
                                summary
                            )
                        }
                    } else if (saveAsTemplate) {
                        await createNewTemplate(auditorAssessment)
                    }
                }

                const limitedAuditRequirement = {
                    ...(omit(requirement, [
                        'no',
                        'tableData',
                        'color',
                        'group',
                        'scope',
                    ]) as AuditRequirement),
                }

                const updatedRequirement = {
                    ...limitedAuditRequirement,
                    summary,
                    fixDate,
                    auditorAssessment,
                    connectedAuditRequirements,
                    isDraft,
                    [statusKey]: {
                        ...requirement[statusKey],
                        statusBaseID: selectedStatusID,
                        ...(isClientOrClientEditor
                            ? { comment: clientComment }
                            : {}),
                    },
                }

                offlineContent.set(
                    OfflineEntityType.Requirement,
                    updatedRequirement
                )

                await updateAuditRequirement(updatedRequirement)
            } catch (error) {
                /**
                 * @todo if not offline error, we should handle it properly
                 */
            } finally {
                history.push(parentAuditURL)
            }
        },
        [
            selectedTemplate,
            createNewTemplate,
            requirement,
            statusKey,
            selectedStatusID,
            isClientOrClientEditor,
            offlineContent,
            updateAuditRequirement,
            addSummaryToExistingTemplate,
            createNewTemplateWithSummary,
            history,
            parentAuditURL,
        ]
    )

    const completedDateFormatted =
        requirement.isDraft && audit.auditCompleted
            ? 'Nie oceniono'
            : format(new Date(requirement.evaluationDate), 'dd/MM/yyyy')

    return (
        <form noValidate>
            <Grid container className={classes.container}>
                <FormDetails
                    requirement={requirement}
                    statuses={statuses || []}
                />
                <FormSection title="Podstawy prawne">
                    <FormLegalBasis requirement={requirement} />
                </FormSection>
                <FormSection title="Data oceny">
                    <FormSectionContent content={completedDateFormatted} />
                </FormSection>
                <FormSection title="Wymagania prawne">
                    <FormSectionContent content={requirement.description} />
                </FormSection>
                <FormSection title="Objaśnienia">
                    <FormSectionContent content={requirement.tooltipData} />
                </FormSection>
                <ACL allowedRoles={allowedRequirementPreviewRoles}>
                    <FormSection title="Realizacja wymagania">
                        <FormSectionContent
                            content={requirement.auditorAssessment}
                        />
                    </FormSection>
                </ACL>
                <ACL allowedRoles={allowedRequirementPreviewRoles}>
                    <ShowConditionally when={connectedAuditRequirements.length}>
                        <FormSection title="Powiązane wymagania">
                            <Box
                                component="span"
                                paddingTop={0.75}
                                marginTop={0.75}
                            >
                                <List
                                    dense
                                    className={
                                        classes.connectedRequirementsList
                                    }
                                >
                                    {connectedAuditRequirements.map(
                                        (connectedAuditRequirement) => {
                                            const {
                                                auditRequirementID,
                                                colors,
                                                name,
                                            } = connectedAuditRequirement

                                            return (
                                                <ListItem
                                                    key={auditRequirementID}
                                                    button
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: `/audit/${companyID}/${auditID}/${auditRequirementID}`,
                                                            state: {
                                                                requirement: {
                                                                    ...connectedAuditRequirement,
                                                                    colors:
                                                                        colors ??
                                                                        colors,
                                                                },
                                                                audit,
                                                                isPreviewMode:
                                                                    location
                                                                        .state
                                                                        .isPreviewMode,
                                                            },
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={name}
                                                        />
                                                    </Link>
                                                </ListItem>
                                            )
                                        }
                                    )}
                                </List>
                            </Box>
                        </FormSection>
                    </ShowConditionally>
                </ACL>
                <ACL allowedRoles={allowedRequirementPreviewRoles}>
                    <ShowConditionally when={requirement.fixDate}>
                        <FormSection title="Termin realizacji">
                            <FormSectionContent content={requirement.fixDate} />
                        </FormSection>
                    </ShowConditionally>
                </ACL>
                <ACL allowedRoles={allowedRequirementPreviewRoles}>
                    <ShowConditionally when={requirement.summary}>
                        <FormSection title="Podsumowanie">
                            <FormSectionContent content={requirement.summary} />
                        </FormSection>
                    </ShowConditionally>
                </ACL>
                <ShowConditionally
                    when={requirement.clientStatus.comment && !isClientEditor}
                >
                    <FormSection
                        title={
                            isClient
                                ? 'Mój komentarz (klient)'
                                : 'Komentarz klienta'
                        }
                    >
                        <FormSectionContent
                            content={requirement.clientStatus.comment || ''}
                        />
                    </FormSection>
                </ShowConditionally>
                <ShowConditionally
                    when={!isPreviewModeWithoutRoles || isClientEditor}
                >
                    <>
                        <Divider variant="middle" className={classes.divider} />
                        <StatusSelection
                            isPreviewMode={location.state.isPreviewMode}
                            selectedStatusID={selectedStatusID}
                            setSelectedStatus={setSelectedStatusID}
                            isClient={isClient}
                            isClientEditor={isClientEditor}
                            isClientEditorOrEditor={isClientOrClientEditor}
                        />
                    </>
                </ShowConditionally>
                <FormStatusDetails
                    auditID={requirement.auditID}
                    statusID={selectedStatusID}
                    requirementBaseID={requirement.requirementBaseID}
                    onTakePhoto={onTakePhotoDialogOpen}
                    onUploadPhoto={onUploadPhotoDialogOpen}
                    formControl={control}
                    isAuditorAssessmentEmpty={isAuditorAssessmentEmpty}
                    isClient={isClient}
                    isClientOrClientEditor={isClientOrClientEditor}
                    setSelectedTemplate={setSelectedTemplate}
                />
                <Divider
                    variant="middle"
                    className={`${classes.divider} ${
                        photos.length > 0 ? classes.dividerImage : ''
                    }`}
                />
                <ShowConditionally when={photos.length > 0}>
                    <>
                        <ShowConditionally
                            when={isOnline && !removeImageMutation.isLoading}
                        >
                            <>
                                {photos.map((photo) => {
                                    return location.state.isPreviewMode ||
                                        isClientOrClientEditor ? (
                                        <Avatar
                                            key={photo.imageID}
                                            variant="square"
                                            src={photo.imageURL}
                                            onClick={onImagePreviewOpenFactory(
                                                photo
                                            )}
                                            className={classes.imageContainer}
                                        />
                                    ) : (
                                        <Badge
                                            key={photo.imageID}
                                            color="secondary"
                                            onClick={onPhotoRemoveFactory(
                                                photo
                                            )}
                                            className={classes.imageContainer}
                                            badgeContent="x"
                                        >
                                            <Avatar
                                                variant="square"
                                                src={photo.imageURL}
                                                onClick={onImagePreviewOpenFactory(
                                                    photo
                                                )}
                                            />
                                        </Badge>
                                    )
                                })}
                            </>
                        </ShowConditionally>
                        <ShowConditionally when={isOffline}>
                            <Typography>
                                Podgląd zdjęć nie jest dostępny w trybie offline
                            </Typography>
                        </ShowConditionally>
                        <ShowConditionally when={removeImageMutation.isLoading}>
                            <CircularProgress
                                className={classes.imagesProgress}
                            />
                        </ShowConditionally>
                        <Divider variant="middle" className={classes.divider} />
                    </>
                </ShowConditionally>
                <ShowConditionally when={!location.state.isPreviewMode}>
                    <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                        <TextField
                            {...registerInputRef('fixDate')}
                            label="Termin realizacji"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline
                            variant="outlined"
                            fullWidth
                        />
                    </ACL>
                </ShowConditionally>
                <FormStatusSummary
                    isAuditorAssessmentEmpty={isAuditorAssessmentEmpty}
                    statusID={selectedStatusID}
                    requirementBaseID={requirement.requirementBaseID}
                    isSummaryEmpty={isSummaryEmpty}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                />
                <div className={classes.actionButtonsContainer}>
                    <Link
                        to={parentAuditURL}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button variant="outlined" color="primary">
                            Powrót
                        </Button>
                    </Link>
                    {!location.state.isPreviewMode && !isClient ? (
                        <Tooltip title={tooltipMessage}>
                            <div>
                                <ProgressButton
                                    className={classes.saveButton}
                                    onClick={handleSubmit((data) =>
                                        onRequirementSubmit(data, false)
                                    )}
                                    variant="contained"
                                    color="primary"
                                    isLoading={
                                        (isTemplateCreating ||
                                            areRequirementsSubmitting) &&
                                        !isSavingDraft
                                    }
                                    disabled={isSaveDisabled}
                                >
                                    Zapisz
                                </ProgressButton>
                            </div>
                        </Tooltip>
                    ) : null}
                    {!location.state.isPreviewMode &&
                        !isClientOrClientEditor && (
                            <ProgressButton
                                onClick={handleSubmit((data) =>
                                    onRequirementSubmit(data, true)
                                )}
                                variant="contained"
                                color="primary"
                                isLoading={
                                    (isTemplateCreating ||
                                        areRequirementsSubmitting) &&
                                    isSavingDraft
                                }
                            >
                                Draft
                            </ProgressButton>
                        )}
                </div>
            </Grid>
        </form>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: theme.spacing(1),
        },
        subContainer: {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        actionButtonsContainer: {
            display: 'flex',
            marginTop: '30px',
            marginLeft: 'auto',
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            width: '100%',
            marginLeft: 0,
        },
        dividerImage: {
            marginBottom: theme.spacing(2),
        },
        imageContainer: {
            cursor: 'pointer',
            marginRight: '20px',
        },
        imagePreviewTitle: {
            padding: 0,
            display: 'flex',
            flexFlow: 'row-reverse',
        },
        imagesProgress: {
            width: 40,
            height: 40,
            position: 'relative',
            top: 5,
            margin: '0 auto',
        },
        connectedRequirementsList: {
            paddingTop: 0,
        },
        saveButton: {
            marginLeft: 20,
            marginRight: 20,
        },
    }
})
