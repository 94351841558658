import { statusColors } from '@shared/utils/status-colors'
import { StatusTypes } from '@shared/types'
import { Theme } from '@material-ui/core'
import { Stats } from '@modules/requirements/client-audit-stats.component'

export const getChartData = (data: Stats | undefined, theme: Theme) => ({
    overall: [
        {
            id: 'rejected',
            label: 'Niezgodne',
            value: data?.rejected,
            color: statusColors[StatusTypes.Rejected],
        },
        {
            id: 'infringement',
            label: 'Naruszenie',
            value: data?.infringement,
            color: statusColors[StatusTypes.Infringement],
        },
        {
            id: 'warnings',
            label: 'Uwagi',
            value: data?.warnings,
            color: statusColors[StatusTypes.Warnings],
        },
        {
            id: 'accepted',
            label: 'Zgodne',
            value: data?.accepted,
            color: statusColors[StatusTypes.Accepted],
        },
        {
            id: 'notAssessed',
            label: 'Nie oceniono',
            value: data?.notAssessed,
            color: statusColors[StatusTypes.NotApplicable],
        },
    ],
    outdated: [
        {
            id: 'outdated',
            label: 'Przestarzałe',
            value: data?.outdated,
            color: theme.palette.grey[300],
        },
        {
            id: 'sumWithoutOutdated',
            label: 'Ocenione bez przestarzałych',
            value: data?.sumWithoutOutdated,
            color: statusColors[StatusTypes.Accepted],
        },
    ],
    incorrect: [
        {
            id: 'outdated',
            label: 'Przestarzałe',
            value: data?.outdated,
            color: theme.palette.grey[300],
        },
        {
            id: 'accepted',
            label: 'Zgodne',
            value: data?.accepted,
            color: statusColors[StatusTypes.Accepted],
        },
        {
            id: 'incorrectSumWithoutOutdated',
            label: 'Zgodne',
            value: data?.incorrectSumWithoutOutdated,
            color: statusColors[StatusTypes.Rejected],
        },
    ],
})
