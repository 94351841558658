import React, { useCallback } from 'react'
import { useQuery } from 'react-query'
import {
    Button,
    Divider,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { apiJSON } from '@shared/utils/api/api.util'
import { SummaryTemplate, Template } from '@shared/types'

type Props = {
    statusID: number
    requirementBaseID: number
    onSetTemplate: (template: string) => void
    onSetSummaryTemplate: (summaryTemplate: string) => void
    selectedTemplate?: Template
    setSelectedTemplate: (value: Template) => void
    isSummary: boolean
}

export const StatusDetailsTemplates = ({
    statusID,
    requirementBaseID,
    onSetTemplate,
    onSetSummaryTemplate,
    selectedTemplate,
    setSelectedTemplate,
    isSummary,
}: Props) => {
    const classes = useStyles()
    const { data: templates } = useQuery<Template[]>('templates', () =>
        apiJSON('templates')
    )

    const requirementTemplates = templates?.filter(
        ({
            requirementBase: { requirementBaseID: templateRequirementBaseID },
            status: { statusID: templateStatusID },
        }) =>
            templateRequirementBaseID === requirementBaseID &&
            templateStatusID === statusID
    )

    const onTemplateClickFactory = useCallback(
        (template: Template) => () => {
            onSetTemplate(template.description)
            setSelectedTemplate(template)
        },
        [onSetTemplate, setSelectedTemplate]
    )

    const onSummaryTemplateClickFactory = useCallback(
        (summaryTemplate: SummaryTemplate) => () => {
            onSetSummaryTemplate(summaryTemplate.description)
        },
        [onSetSummaryTemplate]
    )

    if (!requirementTemplates) return null

    return (
        <>
            <Divider className={classes.divider} />
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="h6">Szablony</Typography>
                </Grid>
                <Grid item xs={8}>
                    {!isSummary &&
                        requirementTemplates.length > 0 &&
                        requirementTemplates.map((template) => (
                            <Button
                                key={template.descriptionTemplateID}
                                onClick={onTemplateClickFactory(template)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                className={classes.templateButton}
                            >
                                {template.description}
                            </Button>
                        ))}
                    {!isSummary && requirementTemplates.length === 0 && (
                        <Typography variant="body1">
                            Brak szablonów dla wybranego wymagania
                        </Typography>
                    )}
                    {isSummary &&
                        selectedTemplate &&
                        selectedTemplate?.summaryTemplates.length > 0 &&
                        selectedTemplate?.summaryTemplates.map(
                            (summaryTemplate) => (
                                <Button
                                    onClick={onSummaryTemplateClickFactory(
                                        summaryTemplate
                                    )}
                                    key={summaryTemplate.summaryTemplateID}
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    className={classes.templateButton}
                                >
                                    {summaryTemplate.description}
                                </Button>
                            )
                        )}
                    {isSummary &&
                        selectedTemplate &&
                        selectedTemplate?.summaryTemplates.length === 0 && (
                            <Typography variant="body1">
                                Brak szablonów
                            </Typography>
                        )}
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    divider: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    templateButton: {
        marginBottom: '20px',
        textAlign: 'left',
        textTransform: 'none',
    },
}))
