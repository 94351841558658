import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'

import { ProgressButton, Props as ProgressButtonProps } from '@shared/components/progress-button.component'

export type Props = DialogProps & {
  title: string
  cancelButtonProps?: Partial<ProgressButtonProps>
  submitButtonProps?: Partial<ProgressButtonProps>
  onClose: (result: boolean) => void
}

export function ConfirmationDialog(props: Props) {
  const {
    onClose,
    open,
    title,
    cancelButtonProps = { children: 'Anuluj' },
    submitButtonProps = { children: 'Zamknij' },
    ...dialogProps
  } = props
  const classes = useStyles()

  const handleCancel = () => {
    onClose(false)
  }

  const handleOk = () => {
    onClose(true)
  }

  return (
    <Dialog open={open} {...dialogProps}>
      <DialogTitle className={classes.dialogTitle} id='confirmation-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <ProgressButton autoFocus onClick={handleCancel} color='primary' variant='outlined' {...cancelButtonProps} />
        <ProgressButton onClick={handleOk} color='primary' variant='contained' {...submitButtonProps} />
      </DialogActions>
    </Dialog>
  )
}

export const useStyles = makeStyles(theme => {
  /**
   * @todo possible styles duplication
   */
  return {
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  }
})
