import { useEffect, useState } from 'react'

interface UseMaintenanceMode {
    isMaintenanceMode: boolean
    isTester: boolean
}

const useMaintenanceMode = (): UseMaintenanceMode => {
    const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false)
    const [isTester, setIsTester] = useState<boolean>(false)

    useEffect(() => {
        const testerMode = localStorage.getItem('testerMode')
        if (testerMode === 'enabled') {
            setIsTester(true)
        }
    }, [])

    return {
        isMaintenanceMode,
        isTester,
    }
}

export default useMaintenanceMode
