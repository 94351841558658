import React from 'react'

import { IconButton, Tooltip } from '@material-ui/core'
import { originalIcons } from '@shared/components/icons/icons'

type Props = {
    description: NonNullable<React.ReactNode>
    interactive?: boolean
}

export function InfoTooltip(props: Props) {
    return (
        <Tooltip title={props.description} interactive={props.interactive}>
            <IconButton edge="end" size="small">
                <originalIcons.InfoIcon />
            </IconButton>
        </Tooltip>
    )
}
