import React, { ReactNode } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
    title: string
    children: ReactNode
}

export const FormSection = ({ title, children }: Props) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.subContainer}>
            <Grid item xs={4} md={3} lg={2}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
                {children}
            </Grid>
        </Grid>
    )
}

export const useStyles = makeStyles((theme) => {
    return {
        subContainer: {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        legalBasisTable: {
            marginBottom: theme.spacing(2),
            '& td, th': {
                paddingLeft: '0',
                fontSize: '1rem',
            },
        },
        legalBassisNoWrapCell: {
            width: 140,
        },
    }
})
