import React, { useCallback, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { format } from 'date-fns'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import { useRouter } from '@shared/hooks/use-router'
import { apiJSON } from '@shared/utils/api/api.util'
import { DownloadAnchor } from '@shared/components/download-anchor.component'

import { Table } from '@shared/components/table/table.component'
import { Document, Roles } from '@shared/types'
import { ConfirmationDialog } from '@shared/components/confirmation.dialog.component'
import { ACL } from '@shared/components/acl.component'
import { useResourceRemove } from '@shared/hooks/use-resource-remove.hook'
import { originalIcons } from '@shared/components/icons/icons'
import { AddFileDialog } from '@shared/components/add-file.dialog.component'
import { useACL } from '@shared/hooks/use-acl.hook'
import { Action } from 'material-table'

const sortByDate = (dateA: string, dateB: string) => +new Date(dateB) - +new Date(dateA)

export function DocumentsPage() {
  const { is } = useACL()
  const classes = useStyles()
  const queryClient = useQueryClient()
  const isClient = is([Roles.Client, Roles.ClientEditor])

  const [isUploadDocumentDialogOpen, setUploadDialogOpen] = useState(false)

  const onUploadDocumentDialogClose = useCallback(() => {
    setUploadDialogOpen(false)
  }, [setUploadDialogOpen])

  const {
    query: { companyID },
    history
  } = useRouter<{ companyID: string }>()

  const endpoint = ['companies', companyID, isClient ? 'own' : false, 'history'].filter(Boolean).join('/')

  const onAddFiles = useCallback(async () => {
    await queryClient.invalidateQueries(endpoint)
  }, [endpoint, queryClient])

  const { isRemovalDialogOpened, isResourceRemoving, setRemovalDialogOpened, setRemovedResourceID, onResourceRemove } = useResourceRemove(
    `companies/${companyID}`,
    {
      isFinalEndpoint: true,
      invalidateEndpoint: endpoint
    }
  )

  const onFileDeleteFactory = useCallback(
    (fileURL: string) => () => {
      setRemovedResourceID(`${fileURL}/file`)
      setRemovalDialogOpened(true)
    },
    [setRemovedResourceID, setRemovalDialogOpened]
  )

  const { data: documents, isLoading: areDocumentsLoading } = useQuery<Document[]>(endpoint, () => apiJSON(endpoint))

  const documentsList = documents?.sort(({ creationDate: dateA }, { creationDate: dateB }) => sortByDate(dateA, dateB)) ?? []

  return (
    <>
      <ConfirmationDialog
        submitButtonProps={{ children: 'Usuń', isLoading: isResourceRemoving }}
        title='Potwierdzenie usunięcia'
        onClose={onResourceRemove}
        open={isRemovalDialogOpened}>
        Czy napewno chcesz usunąć dany wiersz?
      </ConfirmationDialog>
      <AddFileDialog
        open={isUploadDocumentDialogOpen}
        acceptedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        getPreviewIcon={() => <originalIcons.Description />}
        getEndpoint={fileObject => `companies/${companyID}/${fileObject.file.name}/file`}
        onDialogClose={onUploadDocumentDialogClose}
        onAddFiles={onAddFiles}
      />
      <Table<Document>
        isLoading={areDocumentsLoading || isResourceRemoving}
        title='Dokumenty'
        columns={[
          { title: 'Plik', field: 'name' },
          {
            title: 'Data',
            field: 'creationDate',
            render: ({ creationDate }) => <Typography variant='body2'>{format(new Date(creationDate), 'dd-MM-RRRR')}</Typography>
          },
          {
            title: 'Akcje',
            width: '25%',
            render: document => (
              <>
                <Typography variant='body2' className={classes.actionText}>
                  <DownloadAnchor href={`${process.env.REACT_APP_HOST}/api/companies/${companyID}/${document.name}/file`}>
                    Pobierz
                  </DownloadAnchor>
                </Typography>
                <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                  <Typography variant='body2' className={classes.actionText} onClick={onFileDeleteFactory(document.name)}>
                    Usuń
                  </Typography>
                </ACL>
              </>
            )
          }
        ]}
        data={documentsList ?? []}
        options={{
          pageSize: 20
        }}
        actions={
          [
            is([Roles.Admin, Roles.Auditor])
              ? {
                  icon: originalIcons.Add,
                  isFreeAction: true,
                  onClick: () => {
                    setUploadDialogOpen(true)
                  }
                }
              : false
          ].filter(Boolean) as Action<Document>[]
        }
      />
      <Button variant='contained' onClick={history.goBack}>
        Powrót
      </Button>
    </>
  )
}

export const useStyles = makeStyles(theme => {
  return {
    actionText: {
      textDecoration: 'underline',
      textAlign: 'right',
      cursor: 'pointer',
      display: 'inline-block',
      marginRight: theme.spacing(2)
    }
  }
})
