import { useMutation, useQueryClient } from 'react-query'

import { getResourceParametrizedEndpoint } from '@shared/utils/get-resource-parametrized-endpoint'

import { apiJSON, NormalizedErrors } from '@shared/utils/api/api.util'

export function useResourceMutation({ resourceEndpoint, resourceID }: { resourceEndpoint: string; resourceID?: string }) {
  const queryClient = useQueryClient()

  const {
    mutate: createResource,
    isLoading: isResourceCreating,
    isError: isResourceCreationFailed,
    isSuccess: isResourceCreationSucceed,
    error: createResourceErrors,
    reset: resetResourceCreationState
  } = useMutation<unknown, NormalizedErrors, object>(
    (resourceData: object) =>
      apiJSON(resourceID ? getResourceParametrizedEndpoint(resourceEndpoint, resourceID) : resourceEndpoint, resourceData, {
        method: resourceID ? 'PUT' : 'POST'
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceEndpoint)
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes('crud')
        })
      }
    }
  )

  return {
    createResource,
    isResourceCreating,
    isResourceCreationFailed,
    isResourceCreationSucceed,
    createResourceErrors,
    resetResourceCreationState
  }
}
