import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useResourceMutation } from './use-resource-mutation.hook'
import { useResourceQuery } from './use-resource-query.hook'

export function useHandleResourceState<ResourceType extends object = any>({
    resourceEndpoint,
    resourceID,
}: {
    resourceEndpoint: string
    resourceID?: string
}) {
    const isResourceEdit = Boolean(resourceID)
    const formMethods = useForm<ResourceType | any>({
        defaultValues: { legalBasis: [] },
    })

    const { resourceData, readResourceData, isResourceDataFetching } =
        useResourceQuery(resourceEndpoint, resourceID)

    const {
        createResource,
        isResourceCreating,
        isResourceCreationFailed,
        isResourceCreationSucceed,
        createResourceErrors,
        resetResourceCreationState,
    } = useResourceMutation({
        resourceEndpoint: resourceEndpoint,
        resourceID: resourceID,
    })

    useEffect(() => {
        if (isResourceEdit) {
            readResourceData()
        }
    }, [isResourceEdit, readResourceData])

    const { reset: formMethodsReset, setValue: formMethodsSetValue } =
        formMethods

    useEffect(() => {
        if (isResourceEdit && resourceData) {
            setTimeout(() => {
                formMethodsReset({ ...resourceData })

                formMethodsSetValue(
                    'requirementBase',
                    resourceData['requirementBase']
                )
            }, 10)
        }
    }, [isResourceEdit, resourceData, formMethodsReset, formMethodsSetValue])

    const onAddResource = formMethods.handleSubmit((resourceFormData) => {
        resetResourceCreationState()
        createResource({
            ...(isResourceEdit ? resourceData : {}),
            ...resourceFormData,
        })
    })

    return {
        formMethods,
        onAddResource,
        mutation: {
            isResourceEdit,
            isResourceDataFetching,
            isResourceCreating,
            isResourceCreationFailed,
            isResourceCreationSucceed,
            createResourceErrors,
            resetResourceCreationState,
        },
    }
}
