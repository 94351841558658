import React, { useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'

import {
    Container,
    CssBaseline,
    TextField,
    Typography,
} from '@material-ui/core'

import { ProgressButton } from '@shared/components/progress-button.component'
import { apiJSON } from '@shared/utils/api/api.util'
import { useRouter } from '@shared/hooks/use-router'
import { ErrorsAlert } from '@shared/components/errors-alert.component'

type ConfirmPasswordForm = {
    password: string
    passwordConfirmation: string
}

export function ResetPasswordPage() {
    const classes = useStyles()
    const { query, replace } = useRouter<{ token: string; email: string }>()

    const {
        register,
        formState: { errors },
        handleSubmit,
        clearErrors: clearFormErrors,
        watch,
    } = useForm<ConfirmPasswordForm>({
        mode: 'onChange',
        defaultValues: { passwordConfirmation: '', password: '' },
    })

    const {
        mutate: confirmPassword,
        isLoading: isConfirmPasswordLoading,
        error: confirmPasswordError,
        reset: confirmPasswordMutationReset,
    } = useMutation<
        void,
        any,
        { email: string; token: string; password: string }
    >(
        ({ email, password, token }) =>
            apiJSON(
                'users/confirmresetpassword',
                { email, token, password },
                { method: 'POST' }
            ),
        {
            onSuccess: () => {
                replace('/login')
            },
        }
    )

    const onSubmit = useCallback(
        (data: ConfirmPasswordForm) => {
            confirmPassword({
                password: data.password,
                email: query.email,
                token: query.token,
            })
        },
        [confirmPassword, query]
    )

    const passwordFormValue = watch('password')
    const passwordConfirmationFormValue = watch('passwordConfirmation')

    useEffect(() => {
        clearFormErrors()
        confirmPasswordMutationReset()
    }, [
        confirmPasswordMutationReset,
        clearFormErrors,
        passwordFormValue,
        passwordConfirmationFormValue,
    ])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logo}>
                    <img
                        src="/logo/logo-e.jpg"
                        className={classes.logoImage}
                        alt="logo"
                    />
                </div>
                <Typography component="h1" variant="h5">
                    Ustaw hasło
                </Typography>
                <form className={classes.form}>
                    <ErrorsAlert errors={confirmPasswordError} />
                    <TextField
                        {...register('password', {
                            required: 'Musisz podać hasło',
                        })}
                        error={Boolean(errors?.password)}
                        disabled={isConfirmPasswordLoading}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Hasło"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        helperText={errors?.password && errors.password.message}
                    />
                    <TextField
                        {...register('passwordConfirmation', {
                            validate: (value: any) =>
                                value === passwordFormValue ||
                                'Hasła nie są takie same',
                        })}
                        error={Boolean(errors?.passwordConfirmation)}
                        disabled={isConfirmPasswordLoading}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Potwierdź hasło"
                        type="password"
                        id="passwordConfirmation"
                        autoComplete="confirm-password"
                        helperText={
                            errors?.passwordConfirmation &&
                            errors.passwordConfirmation.message
                        }
                    />
                    <ProgressButton
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isConfirmPasswordLoading}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={Boolean(
                            errors?.password || errors?.passwordConfirmation
                        )}
                    >
                        Potwierdź hasło
                    </ProgressButton>
                </form>
            </div>
        </Container>
    )
}

export const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFF',
        padding: '20px',
    },
    logo: {
        margin: theme.spacing(1),
        backgroundColor: '#FFF',
    },
    logoImage: {
        width: '100px',
        height: '100px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))
