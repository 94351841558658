import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'

import { useAuth } from '@modules/auth/use-auth.hook'
import { Layout } from '@modules/layout/layout.component'

import { SelectLocationPage } from '@modules/select-location/select-location.page.component'
import { RequirementsPage } from '@modules/requirements/requirements.page.component'
import { RequirementPage } from '@modules/requirement/requirement.page.component'
import { SelectScopesPage } from '@modules/select-scopes/select-scopes.page.component'
import { MetricsPage } from '@modules/metrics/metrics.page.component'
import { DocumentsPage } from '@modules/documents/documents.page.component'
import { AdminGenericCrudPage } from '@modules/admin-generic/admin-generic-crud.page.component'
import { AdminRequirementsCrudPage } from '@modules/admin-requirements/admin-requirements-crud.page.component'

import { ACL } from '@shared/components/acl.component'
import { Roles } from '@shared/types'
import { SelectLocationClientPage } from '@modules/select-location/select-location-client.page.component'
import { RESOURCE_NAME_ROUTE_PARAM } from '@modules/admin-generic/admin-generic.utils'

export function ApplicationRoutes() {
    const { isLogged } = useAuth()
    const { isOffline } = useNavigatorOnline()
    const lastActiveAudit: { companyID: number; auditID: number } = JSON.parse(
        localStorage.getItem('last-active-audit') ?? 'null'
    )
    const isIOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    const hostname = process.env.REACT_APP_HOST || ''
    const showIndicator =
        (hostname.length > 0 &&
            window.location.origin.includes(
                'https://dev-ecoms-audyty.azurewebsites.net'
            )) ||
        process.env.NODE_ENV === 'development'

    return (
        <Layout showIndicator={showIndicator}>
            {!(isOffline && isIOS) ? (
                <>
                    <ACL
                        allowedRoles={[
                            Roles.Admin,
                            Roles.Auditor,
                            Roles.Client,
                            Roles.ClientEditor,
                        ]}
                    >
                        <Switch>
                            <Route path="/audit/:companyID/:auditID" exact>
                                <RequirementsPage />
                            </Route>
                            <Route path="/documents/:companyID">
                                <DocumentsPage />
                            </Route>
                            <Route
                                path="/audit/:companyID/:auditID/:requirementID"
                                exact
                            >
                                <RequirementPage />
                            </Route>
                        </Switch>
                    </ACL>
                    <ACL allowedRoles={[Roles.Client, Roles.ClientEditor]}>
                        <Switch>
                            <Route path="/" exact>
                                <Redirect to="/select-location/client" exact />
                            </Route>
                            <Route path="/select-location/client" exact>
                                <SelectLocationClientPage />
                            </Route>
                        </Switch>
                    </ACL>
                    <ACL allowedRoles={[Roles.Admin, Roles.Auditor]}>
                        <Switch>
                            <Route path="/" exact>
                                <Redirect to="/client" exact />
                            </Route>
                            <Route path="/client" exact>
                                <SelectLocationPage />
                            </Route>
                            <Route path="/client/:companyID/:auditID?" exact>
                                <SelectScopesPage />
                            </Route>
                            <Route
                                path="/audit-report/:companyID/:auditID"
                                exact
                            >
                                <MetricsPage />
                            </Route>
                            <Route path="/admin" exact>
                                <ACL
                                    allowedRoles={[Roles.Admin, Roles.Auditor]}
                                >
                                    <Redirect exact to="/admin/requirements" />
                                </ACL>
                            </Route>
                            <Switch>
                                <Route path="/admin/requirements">
                                    <AdminRequirementsCrudPage />
                                </Route>
                                <Route
                                    path={`/admin/${RESOURCE_NAME_ROUTE_PARAM}`}
                                >
                                    <AdminGenericCrudPage />
                                </Route>
                            </Switch>
                            <Redirect exact to="/client" />
                        </Switch>
                    </ACL>
                </>
            ) : null}

            {isOffline && lastActiveAudit ? (
                <Route
                    render={({ location }) => (
                        <Redirect
                            to={`/audit/${lastActiveAudit.companyID}/${lastActiveAudit.auditID}`}
                        />
                    )}
                />
            ) : null}

            {!isLogged ? (
                <Route
                    render={({ location }) => (
                        <Redirect to={{ pathname: '/login' }} />
                    )}
                />
            ) : null}
        </Layout>
    )
}
