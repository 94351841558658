import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
}))

function useCommonStyles(className?: string) {
  const classes = useStyles()

  return [className, classes.link].filter(Boolean).join(' ')
}

export function UnstyledLink({ children, ...props }: LinkProps) {
  const classNames = useCommonStyles(props.className)

  return (
    <Link {...props} className={classNames}>
      {children}
    </Link>
  )
}

export function UnstyledAnchor({ children, ...props }: React.HTMLProps<HTMLAnchorElement>) {
  const classNames = useCommonStyles(props.className)

  return (
    <a {...props} className={classNames}>
      {children}
    </a>
  )
}
